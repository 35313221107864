import UIController from '../../../../../../managers/UIController.js';
import Button from '../../../../../main/buttons/common/Button.js';
import SingleInfoTable from '../../common/SingleInfoTable.js';
import { TEXT_STYLE_BLUE, TEXT_STYLE_ORANGE } from '../../../../../../utils/textStyles.js';
import { UnitHPBar, UnitParametersText, UnitXPText } from './unitStatsModule.js';
import ActionButton from '../../../../../main/buttons/common/ActionButton.js';
import apiClient from '../../../../../../services/apiClient.js';
import InfoTables from '../../../../../../scenes/ui/InfoTables.js';


export default class UnitTable extends SingleInfoTable {
    constructor(scene, x, y, children, width, height, texture) {
        super(scene, x, y, children, width, height, texture);
    }

    create() {
        this.healItem = 'medkit';
        this.healItemIncrease = 100;
        this.addContent(this.width, this.height);
        super.create();
    }

    async update(category) {
        await super.update(category);
        this.updateHealingItemInfo();
    }

    addContent(w, h) {
        this.add(this.XP = new UnitXPText(this.scene, w * -0.215, h * -0.357, [], w, h));
        this.add(this.name = this.scene.add.text(w * -0.22, h * -0.315, '')
            .setOrigin(0.5, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.02));

        this.add(this.params = new UnitParametersText(this.scene, w * -0.267, h * -0.227, [], w, h));
        this.add(this.HPBar = new UnitHPBar(this.scene, w * -0.294, h * -0.215, [], w, h));

        this.addHealingItemInfo(w, h);
    }

    addHealingItemInfo(w, h) {
        this.add(this.buttonHeal = new ActionButton(this.scene, w * -0.17, h * 0.264, [], 'button_up', h * 0.038, h * 0.045, 'green', this.useMedkit.bind(this)));
        this.buttonHeal.setRotation(Math.PI / 6);

        this.add(this.healItemAmount = this.scene.add.text(w * -0.245, h * 0.26, '-')
            .setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.021));

        this.add(this.healItemIncreaseText = this.scene.add.text(w * -0.223, h * 0.28, `+${this.healItemIncrease}`)
            .setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.021));

        // Health icon
        this.add(this.healItemIcon = this.scene.add.sprite(w * -0.237, h * 0.28, 'parameters_icons', 'health.png').setDisplaySize(h * 0.03, h * 0.03));
    }

    updateHealingItemInfo() {
        const items = this.scene.game.registry.get('items');
        this.healItemAmount.setText(items[this.healItem]);
    }

    updateInfo(data) {
        this.setData('unit', data['unit']);
        this.HPBar.show();
        this.HPBar.update(data['unit']['health'], data['unit']['max_health']);
        this.updateHealingItemInfo();

        if (data) {
            this.XP.update(data['unit']['xp'], data['unit']['max_xp']);
            this.params.update(data['unit']);
        }
    }

    async useMedkit() {
        const data = await apiClient.units.useMedkit({ id: this.getData('unit')['id'], morion: false });
        InfoTables.showWarningText(data['message']);
        this.update();
    }
}


