import BattleUI from "../../../../scenes/ui/BattleUI";
import { TILE_HEIGHT, TILE_WIDTH } from "../../../../utils/const";
import { TEXT_STYLE_ORANGE } from "../../../../utils/textStyles";
import { isPointInMatrix, getDistance, getRandomArrayElement, callWithProbability, getItemTexture } from "../../../../utils/utils";
import BattleUnitHPBar from "./components/BattleUnitHPBar";
import MoveTo from 'phaser3-rex-plugins/plugins/moveto.js';

const UNIT_STATES = {
    IDLE: 'idle',
    DEAD: 'dead',
    DESTINATION: 'destination',
    ATTACK: 'attack',
    WALK: 'walk',
    PARALYSED: 'paralysed',
};

export default class BattleUnit extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, type, size) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.type = type; // player, enemy
        this.size = size;
    }

    /**
     * Creates the unit and adds it to the game map.
     * @param {object} unitData Unit data that includes his parameters and features.
     * @param {object} linkedIcon The icon in battle UI that will be linked to the unit.
     */
    create(unitData, linkedIcon) {
        this.init(unitData, linkedIcon);
        this.addContent();
        this.rest();
        this.monitorTargets();
    }

    /**
     * Defines the main config and parameters of the unit.
     * @param {object} unitData Unit data that includes his parameters and features.
     * @param {object} linkedIcon The icon in battle UI that will be linked to the unit.
     */
    init(unitData, linkedIcon) {
        this.setData('unit', unitData);
        this.linkedIcon = linkedIcon || null;

        // Will be used to go back to the normal unit range in case his range was changed
        this.initialRange = unitData['range'];

        // By default, unit attacks all targets without priority
        this.setData('config', {
            "target": 'unit', // unit, crystall, extra
            "priority": null, // Target category
            "close_fight": false,
            "close_fight_anims": ['attack'],
            "attack_frames": [5],
            "ignore_armor": false,
            "skills": [],
            "weakness": ['fear'],
            "XP_cost": 1,
        });
    }

    /**
     * Adds all the needed objects to the unit that will be displayed on game map. 
     */
    addContent() {
        const unitData = this.getData('unit');
        const size = Math.floor(window.screen.height * (unitData['size'] || this.size));
        const category = unitData['category'];
        const texture = this.getTextureByCategory(category);

        this.add(this.unitSprite = this.scene.add.sprite(0, 0, texture + '_attack').setOrigin(0.5, 0.6).setDisplaySize(size, size));
        this.add(this.HPBar = new BattleUnitHPBar(this.scene, 0, size * -0.45, [], category, this.type));
        this.HPBar.update(unitData['health'], unitData['max_health']);

        this.setSize(size, size); // Размеры контейнера для физики
        this.setPosition(
            TILE_WIDTH * (this.x - 0.5),
            TILE_HEIGHT * (this.y - 0.5)
        );

        this.moveToPlugin = new MoveTo(this, {
            speed: unitData['speed'] * 10,
        });
    }

    /**
     * Returns the texture for the unit sprite depending on the unit category.
     * @param {string} category Unit category.
     * @returns {string} Texture for the unit sprite.
     */
    getTextureByCategory(category) {
        const unitData = this.getData('unit');

        switch (category) {
            case 'commander':
            case 'character':
            case 'animal':
                return unitData['name'];
            case 'soldier':
                return `soldier_${unitData['rank']}`;
            default:
                return category;
        }
    }

    /**
     * Sets the target for the unit.
     * @param {object|null} target Target to set or `null` to clear the current target.
     */
    setTarget(target) {
        if (this.hasTarget()) {
            this.target.off('die');
            this.offAnimations();
        }

        this.target = target;

        if (target) {
            target.once('die', () => this.onTargetDie(target));
        }
    }

    /**
     * Returns the current target for the unit.
     * @returns {object|null} Current target.
     */
    getTarget() {
        return this.target;
    }

    /**
     * Checks if the unit has the target.
     * @returns {bool} 
     */
    hasTarget() {
        return this.target != null;
    }

    /**
     * The rest state of the unit. In this state unit doesn't do anything and plays the idle animation.
     */
    rest() {
        if (this.state === UNIT_STATES.IDLE) {
            return;
        }
        this.setState('idle');
        this.playAnimation('idle');
    }

    /**
     * Sets the first destination for the unit. 
     * The destination is a point where unit goes after he is created on the map.
     * @param {number} toX The X position of the point.
     * @param {number} toY The Y position of the point.
     */
    setDestination(toX, toY) {
        this.setState(UNIT_STATES.DESTINATION);

        this.destination = {
            x: (toX + 0.5) * TILE_WIDTH,
            y: (toY + 0.5) * TILE_HEIGHT
        };
    }

    /**
     * Goes to the destination (if it is set) when the player deploys the unit. 
     * Going to the destination can be ommited, then the `findTarget()` is called.
     * @param {number} toX The X position of the point.
     * @param {number} toY The Y position of the point.
     */
    goToDestination(toX, toY) {
        this.setDestination(toX, toY);
        this.findPathTo(this.destination);
    }

    /**
     * The main timer of the unit that checks every second if the suitable enemies are present on the map.
     */
    monitorTargets() {
        this.monitorTargetsTimer = this.scene.time.addEvent({
            delay: 1000,
            callback: () => this.decideAction(),
            loop: true
        });
    }

    /**
     * Choose the action of the unit in the main unit loop (timer).
     * @returns 
     */
    decideAction() {
        if (([UNIT_STATES.DEAD, UNIT_STATES.PARALYSED].includes(this.state))) {
            return;
        }

        if (['commander', 'character'].includes(this.getData('unit')['category'])) {
            this.findTarget();
        } else {
            this.checkForThreat();
        }
    }

    /**
     * Returns the array of potential targets for the unit.
     * @returns {array} Array of targets that unit is supposed to attack or avoid.
     */
    getEnemies() {
        return this.scene.data.get(this.isEnemy() ? 'army' : 'enemies');
    }

    /**
     * Returns the current position indexes on the map grid of the unit.
     * @returns {object} `{x, y}` posision of the unit.
     */
    getCurrentPosition() {
        const x = Math.floor(this.x / TILE_WIDTH);
        const y = Math.floor(this.y / TILE_HEIGHT);

        return { x, y };
    }

    /**
     * Returns the current position indexes on the map grid of the enemy unit.
     * @param {object} enemy Current enemy of the unit.
     * @returns {object} `{enemyX, enemyY}` posision of the enemy unit.
     */
    getEnemyPosition(enemy) {
        const enemyX = Math.floor(enemy.x / TILE_WIDTH);
        const enemyY = Math.floor(enemy.y / TILE_HEIGHT);

        return { enemyX, enemyY };
    }

    /**
     * Checks if scary enemies for the current unit are present on the map.
     * If unit is afraid of some type of units, he will try to avoid them and escape in the opposite direction.
     * Can be overriden for different types of units.
     */
    checkForThreat() {
        const { x, y } = this.getCurrentPosition();
        let scaryEnemiesCnt = 0;

        this.getEnemies().forEach(enemy => {
            if (!this.shouldEscapeFrom(enemy)) {
                return;
            }

            scaryEnemiesCnt++;
            const { enemyX, enemyY } = this.getEnemyPosition(enemy);

            const difX = x - enemyX;
            const difY = y - enemyY;

            const { spotExist, toX, toY } = this.checkEscapeAvailable(x, y, difX, difY);

            if (spotExist) {
                this.setTarget(null);
                this.moveToPlugin.off('complete');
                this.offAnimations();
                this.fleeFromThreat(toX, toY);
                return;
            }

            // If no path is found and distance is suitable, unit should attack the scary enemy.
            if (this.isTargetInRange(x, y, enemyX, enemyY)) {
                this.setTarget(enemy);
                this.attack(enemy);
                return;
            }

            this.findTarget();
        });

        if (scaryEnemiesCnt == 0) {
            this.findTarget();
        }
    }

    /**
     * Check if the unit should try to escape the scary enemy.
     * @param {object} enemy The enemy to check. 
     * @returns {bool} 
     */
    shouldEscapeFrom(enemy) {
        const hasFearSkill = enemy.getData('config')['skills'].includes('fear');

        const { x, y } = this.getCurrentPosition();
        const { enemyX, enemyY } = this.getEnemyPosition(enemy);
        const dist = getDistance(x, y, enemyX, enemyY);

        return hasFearSkill && dist <= 5;
    }

    /**
     * Check if the unit is able to escape from the scary enemy.
     * Try to find a path in the opposite direction from the enemy.
     * @param {number} fromX The X position of the unit.
     * @param {number} fromY The Y position of the unit.
     * @param {number} difX The X position of the enemy.
     * @param {number} difY The Y position of the enemy.
     * @returns {object} 
     */
    checkEscapeAvailable(fromX, fromY, difX, difY) {
        let toX, toY;

        if (difX > 0 && difY < 0) { toX = fromX + 1; toY = fromY - 1 }
        else if (difX > 0 && difY == 0) { toX = fromX + 1; toY = fromY }
        else if (difX > 0 && difY > 0) { toX = fromX + 1; toY = fromY + 1 }
        else if (difX == 0 && difY > 0) { toX = fromX; toY = fromY + 1 }
        else if (difX < 0 && difY > 0) { toX = fromX - 1; toY = fromY + 1 }
        else if (difX < 0 && difY == 0) { toX = fromX - 1; toY = fromY }
        else if (difX < 0 && difY < 0) { toX = fromX - 1; toY = fromY - 1 }
        else if (difX == 0 && difY < 0) { toX = fromX; toY = fromY - 1 }

        const spotExist = isPointInMatrix(this.scene.grid, toX, toY);
        return { spotExist, toX, toY };
    }

    fleeFromThreat(toX, toY) {
        this.setState(UNIT_STATES.DESTINATION);
        this.findPathTo({
            x: (toX + 0.5) * TILE_WIDTH,
            y: (toY + 0.5) * TILE_HEIGHT
        });
    }

    /**
     * Finds the suitable enemy for the specified unit to attack. 
     * Enemy can be a unit with the particular category or an object on the map.
     * Different types of units pursuit different goals and have different priority targets.
     * If unit have a non-unit target, it will search for the particulat type of an object. 
     * Some units may attack common enemies when no targets with the prior category is left.
     * @returns 
     */
    async findTarget() {
        if (this.hasTarget() || this.isDead() || this.hasDestination()) {
            return;
        }

        const targetType = this.getData('config')['target'];
        let priority = this.getData('config')['priority'];

        /**
         * The distance at which the unit triggers to attack (sees) the enemy.
         * By default, all player units see every enemy on the map.
         * @type {number}
         */
        const detectionDist = this.isEnemy() ? 12 : 100;
        let targetArray = this.getEnemies();

        if (targetType == 'extra') {
            targetArray = this.getExtraTargets();
        }

        // If Chlapidol shows up on the map as the enemy, he will attack regular units, not only animals.
        // In that case we need to set priority of prefered targets to null.
        if (this.getData('unit')['name'] == 'Chlapidol' && this.isEnemy()) {
            priority = null;
        }

        // If no targets has left on the map and unit is not dead itself, unit can take some rest ;)
        if (targetArray.length == 0) {
            this.rest();
            return;
        }

        // Wait until all of the paths are calculated
        const pathPromises = this.getPathsToEnemies(targetArray, priority);
        const results = await Promise.all(pathPromises);

        // Find a target with the minimum path length
        const bestResult = results.reduce((best, result) => {
            if (result && result.pathLength < best.pathLength) {
                return { enemy: result.enemy, pathLength: result.pathLength };
            }
            return best;
        }, { enemy: null, pathLength: Infinity });

        // If the distance is suitable, find a path to the new target
        if ((bestResult.pathLength <= detectionDist) && !this.isDead()) {
            this.setTarget(bestResult.enemy);
            this.findPathTo(bestResult.enemy);
        } else {
            this.rest();
        }
    }

    /**
     * Returns an array of promises for each path to the targets.
     * @param {array} targets Array of targets.
     * @param {string} priority The prior category of the target.
     * @returns {array} Array of promises.
     */
    getPathsToEnemies(targets, priority) {
        const { x, y } = this.getCurrentPosition();

        return targets.map((enemy) => {
            return new Promise((resolve) => {
                const { enemyX, enemyY } = this.getEnemyPosition(enemy);

                if (!isPointInMatrix(this.scene.grid, enemyX, enemyY)) {
                    return resolve(null); // Omit invalid points
                }

                if (!priority || priority === enemy.getData('unit')['category']) {
                    this.scene.easystar.findPath(x, y, enemyX, enemyY, (path) => {
                        if (path) {
                            resolve({ enemy, pathLength: path.length });
                        } else {
                            resolve(null); // If path not found
                        }
                    });
                    this.scene.easystar.calculate();
                } else {
                    resolve(null); // If unit has different priority
                }
            });
        });
    }

    /**
     * Returns the array of the extra targets if the unit has specific priority.
     * @returns The array of targets.
     */
    getExtraTargets() {
        let priority = this.getData('config')['priority'];
        let targetArray = this.scene.data.get('objects');

        let potentialTarget = targetArray.find(target => target.getData('unit')['category'] == priority);

        // If no potential targets left on the map, unit should attack common enemies.
        if (targetArray.length == 0 || !potentialTarget) {
            this.getData('config')['priority'] = null; // clear the priority by which the unit identifies targets.
            return this.getEnemies();
        }

        return targetArray;
    }

    /**
     * Checks if the distance to the selected target is suitable for attack. 
     * Distance should be smaller than unit range of attack.
     * @param {number} x The X position of the unit.
     * @param {number} y The Y position of the unit.
     * @param {number} toX The X position of the target.
     * @param {number} toY The Y position of the target.
     * @returns {bool}
     */
    isTargetInRange(x, y, toX, toY) {
        return getDistance(x, y, toX, toY) <= this.getData('unit')['range'];
    }

    /**
     * Find the best path to the selected target.
     * @param {object} target 
     * @returns 
     */
    findPathTo(target) {
        const easystar = this.scene.easystar;

        if (!target) {
            return;
        }

        const { x, y } = this.getCurrentPosition();
        const { enemyX, enemyY } = this.getEnemyPosition(target);

        if (this.isTargetInRange(x, y, enemyX, enemyY) && !this.hasDestination()) {
            this.attack(target);
            return;
        }

        easystar.findPath(x, y, enemyX, enemyY, (path) => {

            if (path === null) {
                this.rest();
                return;
            }

            this.clearCellCost(x, y);

            if (!(this.hasDestination() || this.extraPathConditions())) {
                path.pop();
            }

            this.walk(path);
        });
        easystar.calculate();
    }

    extraPathConditions() { }

    /**
     * Checks all the needed conditions and moves the unit along the path.
     * @param {array} path The path unit is going. 
     * @returns 
     */
    walk(path) {
        if (!path[1]) {
            if (this.hasDestination()) {
                this.rest();
            }
            return;
        }

        const x = path[1].x;
        const y = path[1].y;
        this.move(x, y);

        this.moveToPlugin.once('complete', () => {

            this.takeCell(x, y, 5);
            this.scene.map.sort('y');

            if (this.hasDestination()) {
                this.findPathTo(this.destination);
                return;
            }

            if (!this.hasTarget()) {
                this.rest();
                return;
            }

            this.findPathTo(this.target);
        });
    }

    /**
     * Moves the unit to the next point of the path.
     * @param {number} x The X index of the grid point.
     * @param {number} y The Y index of the grid point.
     */
    move(x, y) {
        this.setDirection({ x: x * TILE_WIDTH, y: y * TILE_HEIGHT });

        if (!this.hasDestination()) {
            this.setState(UNIT_STATES.WALK);
        }
        this.playAnimation('walk');

        this.moveToPlugin.moveTo(
            Math.floor(TILE_WIDTH * (x + 0.5)),
            Math.floor(TILE_HEIGHT * (y + 0.5))
        );
    }

    attack(target) {
        this.setState(UNIT_STATES.ATTACK);
        this.chooseAndPlayAttackAnim(target);

        this.unitSprite.off('animationupdate').on('animationupdate', () => {
            if (!this.hasTarget() || this.target.isDead()) {
                return;
            }
            this.dealDamageToTarget(target);
        });
    }

    dealDamageToTarget(target) {
        this.hitTarget(target);
    }

    hitTarget(target) {
        if (this.isDead()) {
            this.setTarget(null);
            return;
        }

        const { x, y } = this.getCurrentPosition();
        const { enemyX, enemyY } = this.getEnemyPosition(target);
        const config = this.getData('config');

        const attackFrames = config['attack_frames'];
        const unitData = this.getData('unit');

        // Обновляем анимацию каждое ее повторение
        this.unitSprite.off('animationrepeat').on('animationrepeat', () => {
            this.chooseAndPlayAttackAnim(target);
        });

        // Если цель вышла за радиус атаки, догоняем ее
        if (!this.isTargetInRange(x, y, enemyX, enemyY)) {
            this.moveToPlugin.off('complete');
            this.clearCellCost(x, y);
            this.findPathTo(target);
            return;
        }

        // Если цель идет к точке назначения, и ее начали атаковать, она атакует в ответ
        if ([UNIT_STATES.DESTINATION, UNIT_STATES.WALK].includes(target.state)) {
            target.fightBack(this);
        }

        if (this.isCloseFightEnabled(target)) {
            if (this.unitSprite.anims.currentFrame.index == 5) {

                callWithProbability(unitData['accuracy'], () => {
                    target.takeDamage(unitData['close_damage']);
                    this.extraCloseFightAction(target);
                });
            }

        } else if (attackFrames.includes(this.unitSprite.anims.currentFrame.index)) {

            if (!this.hasTarget()) return;
            this.shoot(this.x, this.y, this.dir, target);

            callWithProbability(unitData['accuracy'], () => {
                target.takeDamage(unitData['damage'], config['ignore_armor']);
                this.extraShootAction(target);
            });
        }
    }

    /**
     * Check if unit has the ability of close fight. 
     * It means that if the enemy comes too close, unit switches his normal `damage` to `close_damage`.  
     * @param {object} target 
     * @returns 
     */
    isCloseFightEnabled(target) {
        const { x, y } = this.getCurrentPosition();
        const { enemyX, enemyY } = this.getEnemyPosition(target);

        const closeDamage = this.getData('unit')['close_damage'];
        const dist = getDistance(x, y, enemyX, enemyY);

        return (closeDamage > 0) && (dist <= 1);
    }

    chooseAndPlayAttackAnim(target) {
        const anims = this.getData('config')['close_fight_anims'];
        this.setDirection(target);
        this.playAnimation(this.isCloseFightEnabled(target) ? anims : 'attack');
    }

    /**
     * Switch to the enemy that bothers the unit and distracts him from other important things and attack him.
     * @param {object} target The attacking enemy. 
     */
    fightBack(target) {
        this.offAnimations();

        this.isAttacking = true;
        this.setTarget(target);

        this.moveToPlugin.once('complete', () => this.attack(target));
    }

    /**
     * Generate an object: `bullet, lightning or other` that will be thrown to the enemy.
     * @param {number} x The X position of the unit.
     * @param {number} y The Y position of the unit.
     * @param {string} dir Direction in which the unit shoots.
     * @param {object} target Selected unit target. 
     */
    shoot(x, y, dir, target) { }

    /**
     * Describes the additional action that unit takes when he shoots the enemy.
     * @param {object} target Selected unit target. 
     */
    extraShootAction(target) { }

    /**
     * Describes the additional action that unit takes in close fight.
     * @param {object} target Selected unit target. 
     */
    extraCloseFightAction(target) { }

    /**
     * Describes what happens when the selected target dies.
     * @param {object} target 
     */
    onTargetDie(target) {
        this.setTarget(null);

        if (this.state === UNIT_STATES.WALK) {
            this.rest();
            return;
        }

        this.offAnimations();
        this.isAttacking = false;

        if (this.isEnemy()) return;

        const unitData = this.getData('unit');
        const xpCost = target.getData('config')['XP_cost'];

        this.displayPopText('XP +' + xpCost);

        if (!unitData['receivedXP']) {
            unitData['receivedXP'] = xpCost;
        } else {
            unitData['receivedXP'] += xpCost;
        }

        unitData['xp'] += xpCost;
        unitData['points']++;
    }

    /**
     * Take the damage from an enemy when he hits the unit.
     * Computes the real (clear) damage enemy causes to unit.
     * Real (clear) damage - the damage with armor.
     * @param {number} damage Damage points.
     * @param {boolean} ignoreArmor Should armor be ignored or not.
     */
    takeDamage(damage, ignoreArmor = false) {

        if (this.isDead()) return;

        const unitData = this.getData('unit');
        unitData['health'] -= this.getClearDamage(damage, ignoreArmor);

        if (unitData['health'] <= 0) {
            unitData['health'] = 0;
            this.die();
        }

        this.linkedIcon?.updateContent(unitData);
        this.HPBar.update(unitData['health'], unitData['max_health']);
    }

    /**
     * Returns the clear damage enemy causes to the unit.
     * @param {number} damage The damage of the enemy. 
     * @param {boolean} ignoreArmor If set to `true` the damage computes without armor.
     */
    getClearDamage(damage, ignoreArmor = false) {
        const damageWithArmor = damage - ~~(damage * (this.getData('unit')['armor'] / 100));
        return ignoreArmor ? damage : damageWithArmor;
    }

    /**
     * Describes what happens when unit dies.
     * @returns 
     */
    die() {
        if (this.isDead()) return;

        this.setState(UNIT_STATES.DEAD);
        this.emit('die');

        Phaser.Utils.Array.Remove(this.getUnitArray(), this);

        this.onDie();
        this.playAnimation('death');
    }

    /**
     * Describes what actions should be taken when the unit dies.
     */
    onDie() {
        this.scene.time.removeEvent(this.monitorTargetsTimer);
        this.moveToPlugin.off('complete');

        const sceneData = this.scene.data;
        const deadUnits = sceneData.get('deadUnits');
        deadUnits.push(this);

        if (this.isEnemy()) {
            this.updateKilledEnemies();

        } else {
            const armyCount = sceneData.get('army_count');
            sceneData.set('army_count', armyCount - 1);
            BattleUI.playerUI.updateContent();
        }

        this.offAnimations();
    }

    /**
     * Update (increase) the amount of killed enemies when the unit eliminates the target. 
     */
    updateKilledEnemies() {
        const name = this.getData('unit')['name'];
        const killedEnemies = this.scene.data.get('enemies_killed');

        if (name in killedEnemies) {
            killedEnemies[name]++;
        } else {
            killedEnemies[name] = 1;
        }

        BattleUI.enemyUI.updateContent();
    }

    /**
     * Returns the array of units in battle that the unit is a child of.
     * @returns {array} The array of units.
     */
    getUnitArray() {
        return this.scene.data.get(this.isEnemy() ? 'enemies' : 'army');
    }

    /**
     * Paralyze the unit so unit can't move or do anything. 
     * Unit paralazes for the specified time and wakes up after no time is left.
     * If unit is dead by the time he gets free, nothing happens.
     * @param {number} duration Duration of paralysis in `ms`.
     */
    paralyse(duration) {
        const exceptedCategories = ['pichmog', 'beles', 'beles_female'];

        // Some units can't get paralyzed due to their specific abilities or features.
        if (exceptedCategories.includes(this.getData('unit')['name'])) {
            this.destroyForeignObjects();
            return;
        }

        this.moveToPlugin.off('complete');
        this.unitSprite.anims.stop();

        this.setState(UNIT_STATES.PARALYSED);
        this.scene.time.delayedCall(duration, () => this.free());
    }

    /**
     * Make unit do something after he gets free from paralisys.
     */
    free() {
        this.destroyForeignObjects();

        if (!this.isDead()) {
            this.checkForThreat();
        }
    }

    /**
     * Destroy objects that has been assigned to the unit.
     */
    destroyForeignObjects() {
        this.coverNet?.destroy();
    }

    /**
     * Set the direction of the unit to display the correct animation. 
     * @param {object} target The enemy unit selected.
     */
    setDirection(target) {
        const { x, y } = this.getCurrentPosition();
        const { enemyX, enemyY } = this.getEnemyPosition(target);

        if (x < enemyX && y < enemyY) {
            this.dir = 'down-r';
        } else if (x > enemyX && y < enemyY) {
            this.dir = 'down-l';
        } else if (x < enemyX && y > enemyY) {
            this.dir = 'up-r';
        } else if (x > enemyX && y > enemyY) {
            this.dir = 'up-l';
        } else if (x == enemyX && y < enemyY) {
            this.dir = 'down';
        } else if (x == enemyX && y > enemyY) {
            this.dir = 'up';
        } else if (x < enemyX && y == enemyY) {
            this.dir = 'right';
        } else if (x > enemyX && y == enemyY) {
            this.dir = 'left';
        }
    }

    /**
     * Play the correct unit animation for the specified action.
     * @param {string} action The specified action: `idle, walk, attack...`.
     */
    playAnimation(action) {
        if (Array.isArray(action)) {
            action = getRandomArrayElement(action);
        }

        if (this.state === UNIT_STATES.IDLE) {
            this.dir = this.getRandomDirection();
        }

        const animation = this.getAnimationByCategory(action);

        this.unitSprite.play(animation, true);
        this.unitSprite.setDisplaySize(this.width, this.height);
    }

    /**
     * Returns the animation key for the unit depending on his category.
     * @param {string} action The specified action: `idle, walk, attack...`.
     * @returns {string} Animation key.
     */
    getAnimationByCategory(action) {
        const unitData = this.getData('unit');
        const category = unitData['category'];

        switch (category) {
            case 'soldier':
                return `${category}_${unitData['rank']}_${action}_${this.dir}`;
            case 'commander':
            case 'character':
            case 'animal':
            case 'robot':
                return `${unitData['name']}_${action}_${this.dir}`;
            default:
                return `${category}_${action}_${this.dir}`;
        }
    }

    /**
     * Returns the random animation direction if unit has no direction.
     * Used mostly for `idle` animation.
     * @returns {string} Animation direction.
     */
    getRandomDirection() {
        const dirs = ['down-r', 'down-l', 'up-r', 'up-l'];

        if (!this.dir || !dirs.includes(this.dir)) {
            return getRandomArrayElement(dirs);
        }

        return this.dir;
    }

    /**
     * Shows the pop text above the unit when specific action is done.
     * @param {string} text The text to be displayed.
     * @param {string} itemName The name of the item collected.
     * @returns 
     */
    displayPopText(text, itemName = null) {
        const h = this.unitSprite.displayHeight;
        const windowH = window.innerHeight;
        const iconSize = windowH * 0.02;

        if (this.popText) return;

        this.add(this.popText = this.scene.add.text(0, h * -0.5, text).setOrigin(itemName ? 0 : 0.5, 0.5)
            .setStyle(TEXT_STYLE_ORANGE).setFontSize(windowH * 0.015));

        if (itemName) {
            const texture = getItemTexture(itemName);

            this.add(this.popTextIcon = this.scene.add.sprite(0, h * -0.5, texture, `${itemName}.png`)
                .setDisplaySize(iconSize, iconSize).setOrigin(1, 0.5));
        }

        // Animate cteated text
        this.scene.tweens.add({
            targets: [this.popText, this.popTextIcon],
            delay: 10,
            duration: 500,
            ease: 'Linear',
            y: h * -0.7,
            repeat: false,
            onComplete: () => {
                this.remove(this.popText);
                this.popText.destroy();
                this.popText = null;

                if (this.popTextIcon) {
                    this.remove(this.popTextIcon);
                    this.popTextIcon.destroy();
                }
            },
        });
    }

    /**
     * Sets the cost for the cell where the unit stands. Cost defines that other units can't step on the cell.
     * @param {number} x The X position ot the unit.
     * @param {number} y The Y position ot the unit.
     * @param {number} cost The cost that must be set to the cell.
     */
    takeCell(x, y, cost) {
        this.scene.easystar.setAdditionalPointCost(x, y, cost);
        this.scene.easystar.setGrid(this.scene.grid);
    }

    /**
     * Sets the cost of the cell to default (`0`) so that other units can go through the cell.
     * @param {number} x The X position ot the unit.
     * @param {number} y The Y position ot the unit.
     */
    clearCellCost(x, y) {
        this.scene.easystar.removeAdditionalPointCost(x, y);
        this.scene.easystar.setGrid(this.scene.grid);
    }

    /**
     * Checks if the unit is the enemy or not.
     * @returns {bool}
     */
    isEnemy() {
        return this.type == 'enemy';
    }

    isDead() {
        return this.state === UNIT_STATES.DEAD;
    }

    /**
     * Checks if the unit has the destination or not.
     * @returns {bool}
     */
    hasDestination() {
        return this.state == UNIT_STATES.DESTINATION;
    }

    /**
     * Clear all the events related to the unit animations.
     */
    offAnimations() {
        this.unitSprite.off('animationstart').off('animationrepeat').off('animationupdate');
    }
}