import InteractiveObject from "../../../../../../main/InteractiveObject";


export class CharacterEquipment extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, unitName) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.unitName = unitName;
    }

    update(data, info, x, y, w, h) {
        this.each(p => p.destroy());
        this.setPosition(x, y);

        for (let key in data) {
            const e = data[key];
            let part = new EquimpentPart(this.scene, w * e["x"], h * e["y"], [], this.unitName, w * e["w"], h * e["h"]);
            part.draw(key, info);
            this.add(part);
        }
    }
}

export class EquimpentPart extends InteractiveObject {
    constructor(scene, x, y, children, texture, width, height) {
        super(scene, x, y, children, texture, width, height);
    }

    makeInteractive() {
        this.obj.setInteractive(this.scene.input.makePixelPerfect());
        super.makeInteractive();
    }

    draw(frame, info) {
        this.name = frame;
        this.info = info;

        this.obj.setTexture(this.texture + '_parts').setFrame(frame + '.png');
        this.obj.setDisplaySize(this.width, this.height);
        this.setAlpha(0.001);
    }

    pointerOver() {
        super.pointerOver();
        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            ease: 'Power1',
            duration: 300,
            repeat: false
        });
    }

    pointerOut() {
        super.pointerOut();
        this.scene.tweens.add({
            targets: this,
            alpha: 0.000001,
            ease: 'Power1',
            duration: 300,
            repeat: false
        });
    }

    click() {
        super.click();
        this.info.displayEquipmentInfo(this.name);
    }
}