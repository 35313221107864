import InfoTables from "../../../../scenes/ui/InfoTables";
import apiClient from "../../../../services/apiClient";
import { TEXT_STYLE_BLUE } from "../../../../utils/textStyles";
import Button from "../common/Button";

export default class AddParameterButton extends Button {
    constructor(scene, x, y, children, texture, width, height, color, par) {
        super(scene, x, y, children, texture, width, height, color);

        this.par = par;
        this.obj.setRotation(Math.PI / 6);

        this.addInfoText(width, height);
    }

    addInfoText(w, h) {
        this.add(this.infoText = this.scene.add.text(w * 1.5, h * -0.15, '').setOrigin(0, 1)
            .setStyle(TEXT_STYLE_BLUE).setFontSize(window.screen.height * 0.015));

        this.add(this.icon = this.scene.add.image(w * 0.6, 0, 'parameters_icons', 'points.png')
            .setOrigin(0, 1).setDisplaySize(window.screen.height * 0.025, window.screen.height * 0.025));

        let text;

        switch (this.par) {
            case 'health':
                text = '10';
                break;
            case 'armor':
                text = '1000';
                break;
            case 'damage':
                text = '25';
                break;
            case 'close_damage':
                text = '25';
                break;
            case 'accuracy':
                text = '125';
                break;
            case 'speed':
                text = '7500';
                break;
            case 'range':
                text = '10000';
                break;
        }

        this.infoText.setText(text);

        this.hideInfo();
    }

    pointerOver() {
        super.pointerOver();
        this.displayInfo();
    }

    pointerOut() {
        super.pointerOut();
        this.hideInfo();
    }

    async click() {
        super.click();
        const table = this.scene.game.registry.get('opened_table').tableCenter;
        const data = await apiClient.units.characters.usePoints({ id: table.getData('unit')['id'], parameter: this.par });

        table.updateInfo(data['unit']);
        InfoTables.showWarningText(data['message']);
    }

    displayInfo() {
        this.infoText.alpha = 1;
        this.icon.alpha = 1;
    }

    hideInfo() {
        this.infoText.alpha = 0;
        this.icon.alpha = 0;
    }
}