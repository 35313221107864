import JournalScrollItem from "./JournalScrollItem";
import JournalSideScroll from "./JournalSideScroll";

export default class JournalScrollCharacters extends JournalSideScroll {

    addItems() {
        const characters = this.scene.journalData['characters'];
        const data = this.scene.journalData['data']['characters'];

        for (let index in characters) {
            const d = data[characters[index]['name']];
            let shortName = d['short_name'];

            let cell = new JournalScrollItem(this.scene, 0, 0, [], 'journal_character_icons', characters[index]['name'], shortName);
            this.scroll.addItem(cell);
        }

        this.scroll.items[0].button.click(false);
    }
}