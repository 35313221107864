import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../../utils/textStyles";

export class UnitXPText extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.add(this.XP = scene.add.text(0, 0, '0').setOrigin(1, 1));
        this.add(this.maxXP = scene.add.text(tw * 0.012, 0, '0').setOrigin(0, 1));

        this.XP.setStyle(TEXT_STYLE_GREEN).setFontSize(th * 0.019);
        this.maxXP.setStyle(TEXT_STYLE_BLUE).setFontSize(th * 0.019);
    }

    update(XP, maxXP) {
        this.XP.setText(XP);
        this.maxXP.setText(maxXP);
    }
}

export class UnitParametersText extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.create(tw, th);
    }

    create(tw, th) {
        this.add(this['level'] = this.scene.add.text(0, 0, '-')
            .setOrigin(0.5, 0.5).setPosition(tw * -0.038, th * -0.092)
            .setStyle(TEXT_STYLE_BLUE).setFontSize(th * 0.025));

        this.params = [
            this["health"] = this.scene.add.text(0, 0, '-'),
            this["armor"] = this.scene.add.text(0, 0, '-'),
            this["damage"] = this.scene.add.text(0, 0, '-'),
            this["accuracy"] = this.scene.add.text(0, 0, '-'),
            this["speed"] = this.scene.add.text(0, 0, '-'),
            this["range"] = this.scene.add.text(0, 0, '-'),
        ];
        this.add(this.params);

        this.updatePositions();
    }

    update(data) {
        if (!data) {
            this['health'].setText('-');
            this['armor'].setText('-');
            this['damage'].setText('-');
            this['accuracy'].setText('-');
            this['speed'].setText('-');
            this['range'].setText('-');
            return;
        }
        for (let key in data) {
            this[key] && this[key].setText(data[key] || '-');
        }
    }

    addExtraParameter(key, index) {
        this.add(this[key] = this.scene.add.text(0, 0, '-'));
        this.params.splice(index - 1, 0, this[key]);
        this.updatePositions();
    }

    removeParameter(key) {
        if (this[key]) {
            this[key].destroy();
        } else {
            console.error('Такого параметра не существует');
        }
    }

    updatePositions() {
        var dist = 0;
        this.params.forEach(p => {
            p.y = dist;
            p.setStyle(TEXT_STYLE_GREEN).setFontSize(this.th * 0.021).setOrigin(0.5, 1);
            dist += this.th * 0.0685;
        });
    }
}

export class UnitHPBar extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.create(tw, th);
    }

    create(w, h) {
        this.add(this.hpFrame = this.scene.add.sprite(0, 0, 'UI', 'health_bar.png'));
        this.add(this.hpBar = this.scene.add.sprite(0, 0, 'UI', 'health_bar.png'));
        this.hpFrame.setDisplaySize(w * 0.06, h * 0.003).setOrigin(0, 0).setTint(0x747474);
        this.hpBar.setDisplaySize(w * 0.06, h * 0.003).setOrigin(0, 0).setTint(0x27ff00);

        this.hpBar.alpha = 0;
        this.hpFrame.alpha = 0;
    }

    show() {
        this.hpBar.alpha = 1;
        this.hpFrame.alpha = 1;
    }

    update(health, maxHealth) {
        this.hpBar.setCrop(0, 0, this.hpBar.width / maxHealth * health, this.hpBar.height);
    }
}
