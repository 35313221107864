import InfoTables from '../../../../../../scenes/ui/InfoTables.js';
import ItemIcon from '../../../../../main/icons/ItemIcon.js';
import InfoTableMainScroll from '../../../../common/scroll/InfoTableMainScroll.js';
import ArmyUnitsTable from './ArmyUnitsTable.js';
import UnitInjectCell from './components/UnitInjectCell.js';


export default class IrradiatedTable extends ArmyUnitsTable {

    async create() {
        super.create();

        const w = this.width;
        const h = this.height;
        const crystals = ['crystall_health', 'crystall_armor', 'crystall_damage', 'crystall_accuracy', 'crystall_speed', 'crystall_range'];

        this.add(this.crystalScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let key in data) {
                if (!crystals.includes(key)) continue;

                let icon = new ItemIcon(this.scene, 0, 0, [], 'items_icons', h * 0.05, h * 0.05);
                icon.setIconData({ name: key, amount: data[key] });

                icon.setPointerOverCallback(() => {
                    this.displayParamsIncrease(key);
                });

                icon.setPointerOutCallback(() => {
                    this.resetParamsIncrease();
                });

                // Колбэк при клике на иконку
                icon.setClickCallback(() => {
                    this.selectCrystall(icon);
                });

                this.crystalScroll.addItem(icon);
            }

        }, {
            x: w * 0.193,
            y: h * -0.044,
            width: w * 0.27,
            height: h * 0.197,
            mode: 'vertical',
            space: h * 0.02,
            top: h * 0.01,
            bottom: h * 0.01,
            fixWidth: true,
            type: 'custom'
        }));
    }

    async update(category) {
        await super.update(category);

        let items = this.scene.game.registry.get('items');
        this.updateCrystalScroll(items);
    }

    updateCrystalScroll(data) {
        this.crystalScroll.updateContent(data);
    }

    addContent(w, h) {
        this.healItem = 'battery_heal';
        super.addContent(w, h);
        this.add(this.injectionCell = new UnitInjectCell(this.scene, w * 0.0865, h * 0.125, [], w, h));
    }

    updateInfo(data) {
        super.updateInfo(data);
    }

    selectCrystall(icon) {
        if (!this.injectionCell.isFree) {
            InfoTables.showWarningText('Кристалл уже выбран');
            return;
        }
        if (!this.selectedUnit) {
            InfoTables.showWarningText('Необходимо выбрать облученного');
            return;
        }
        this.injectionCell.addItem(icon.getData('name'), icon, this.selectedUnit.unitData);
        let amount = icon.getData('amount');
        icon.updateAmount(--amount);
    }
}



