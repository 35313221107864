import { TEXT_STYLE_BLUE } from "../../../../../../utils/textStyles";
import ItemIcon from "../../../../../main/icons/ItemIcon";

export default class InjectionCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.tw = tw;
        this.th = th;

        this.create(tw, th);
        this.isFree = true;
    }

    create(w, h) {
        this.add(this.frame = this.scene.add.sprite(0, 0, 'UI', 'table_button.png').setOrigin(0.5, 0.5));
        this.frame.setDisplaySize(h * 0.076, h * 0.076);
        this.frame.setRotation(Math.PI / 2);
        this.frame.setTint(0xffcb6a);
        this.frame.setAlpha(0.001);

        this.add(this.parIcon = this.scene.add.sprite(this.frame.displayWidth * 0.6, 0, 'parameters_icons', 'health.png').setDisplaySize(h * 0.05, h * 0.05).setOrigin(0, 0.5));
        this.add(this.text = this.scene.add.text(this.parIcon.x + h * 0.06, 0, '+100').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.02));

        this.parIcon.setVisible(false);
        this.text.setVisible(false);
    }

    addInjection(key, injection) {
        const x = this.frame.displayWidth / 2 - this.th * 0.053 / 2;
        const y = 0;

        this.add(this.icon = new ItemIcon(this.scene, x, y, [], 'items_icons', this.th * 0.05, this.th * 0.05));
        this.icon.setIconData({ name: key, amount: 0 });
        this.icon.setClickCallback(this.removeInjection.bind(this));

        this.icon.amountText.destroy(); // удаляем ненужный текст количества
        this.frame.setAlpha(1);
        this.injection = injection;

        this.displayInjectionInfo(key);

        this.isFree = false;
    }

    displayInjectionInfo(key) {
        let parameter = '';
        let add;

        switch (key) {
            case 'injector_health':
                parameter = 'health';
                add = 25;
                break;
            case 'injector_damage':
                parameter = 'damage';
                add = 5;
                break;
            case 'injector_accuracy':
                parameter = 'accuracy';
                add = 1;
                break;
            case 'injector_speed':
                parameter = 'speed';
                add = 1;
                break;
            case 'injector_range':
                parameter = 'range';
                add = 1;
                break;
        }

        this.parIcon.setVisible(true).setFrame(`${parameter}.png`);
        this.text.setVisible(true).setText('+' + add);
    }

    removeInjection() {
        this.remove(this.icon);
        this.icon.destroy();
        this.frame.setAlpha(0.001);
        this.isFree = true;

        this.icon = null;

        this.parentContainer.checkInjectAvailable();

        this.parIcon.setVisible(false);
        this.text.setVisible(false);
    }

    clear() {
        if (!this.icon) {
            return;
        }
        this.removeInjection();
    }
}