import CharacterTable from '../tables/units/characters/CharacterTable.js';
import BuildingTableContainer from './common/BuildingTableContainer.js';
import SingleInfoTable from '../tables/common/SingleInfoTable.js';

export default class HelicopterTableContainer extends BuildingTableContainer {

    addInfoTables() {
        this.tableLeft = null;
        this.tableCenter = new CharacterTable(this.scene, 0, 0, [], this.w, this.h, 'Vovein');
        this.tableRight = null;
        // this.tableRight = new SingleInfoTable(this.scene, 0, 0, [], this.w, this.h, 'helicopter');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}
