import UIController from "../../../managers/UIController";
import { TEXT_STYLE_BLUE } from "../../../utils/textStyles";
import ActionButton from "../../main/buttons/common/ActionButton";
import apiClient from "../../../services/apiClient";
import InfoTables from "../../../scenes/ui/InfoTables";

export default class OpenBarrierTable extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.building = scene.game.registry.get('curBuilding');
        this.buildingData = this.building.objectData;

        this.create(window.screen.width);
    }

    create(w) {
        this.add(this.table = this.scene.add.image(0, 0, 'table_fix').setOrigin(0, 1)
            .setDisplaySize(w * 0.24, w * 0.084).setInteractive());

        const tw = this.table.displayWidth;
        const th = this.table.displayHeight;

        // Название здания
        this.add(this.name = this.scene.add.text(tw * 0.6, th * -0.77, this.buildingData['name'])
            .setOrigin(0.5, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(w * 0.011));

        // Кнопка восстановить
        this.add(this.button = new ActionButton(this.scene, tw * 0.203, th * -0.684, [], 'table_button', tw * 0.12, tw * 0.12, 'green', this.openBarrier.bind(this)));

        this.handleButtonClick();
        this.addText(tw, th);
    }

    handleButtonClick() {
        this.button.obj.on('pointerdown', () => {
            this.scene.input.off('pointerdown');
            this.button.obj.on('pointerup', () => {
                this.scene.map.handleMoves();
                this.scene.input.on('pointerdown', () => {
                    if (!this.scene) {
                        return;
                    }
                    this.scene.input.off('pointerdown');
                    this.building && this.building.destroyFixTable();
                });
            });
            this.scene.input.on('pointerup', () => this.scene.map.handleMoves());
        });
    }

    async openBarrier() {
        const data = await apiClient.barriers.open({
            scene: this.scene.scene.key,
            name: this.building.texture
        });

        if (data['status'] != 'success') {
            return;
        }

        if (!data['data']['opened']) {
            InfoTables.showWarningText('Не достаточно ресурсов');
            return;
        }

        InfoTables.showWarningText('Доступ открыт');
        this.building.destroyFixTable();
        this.building.setState('opened');
    }

    addText(w, h) {
        UIController.arrangeItems({
            container: this,
            itemsData: this.buildingData["open_keys"],
            x: w * 0.22,
            y: h * -0.25,
            iconSize: h * 0.2,
            fontSize: h * 0.12,
            space: w * 0.185,
        });

        this.add(this.title = this.scene.add.text(w * 0.3, h * -0.62, this.getInfoText()).setOrigin(0, 0).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.15));
        this.updateText();
    }

    getInfoText() {
        if (['south_barrier', 'east_barrier', 'north_barrier'].includes(this.building.texture)) {
            return 'Восстановить';
        }
        return 'Открыть замок';
    }

    updateText() {
        let items = this.scene.game.registry.get('items');
        UIController.updateItemsText(items, this.buildingData["open_keys"], this.itemsText);
    }
}
