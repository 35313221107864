import UIController from "../../../../../managers/UIController";
import { TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE } from "../../../../../utils/textStyles";
import ActionButton from "../../../../main/buttons/common/ActionButton";
import SingleInfoTable from "../common/SingleInfoTable";


export default class GreetingTable extends SingleInfoTable {
    constructor(scene, x, y, children, width, height, texture) {
        super(scene, x, y, children, width, height, texture);
    }

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        this.addText(w, h);
        this.addButton(w, h);

        const y = h * 0.07;
        this.add(this.scene.add.line(0, 0, 0, y, w * 0.78, y, 0xffedbb));

        this.add(this.scene.add.text(w * -0.33, h * 0.1, 'Ресурсы для начала игры:').setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.025));

        const mainItems = {
            'crystall': 100, 
            'battery_units': 100, 
            'steel': 100, 
            'silicon': 100, 
            'titan': 100, 
        };

        UIController.arrangeItems({
            container: this,
            itemsData: mainItems,
            x: w * -0.3,
            y: h * 0.155,
            iconSize: h * 0.04,
            fontSize: h * 0.025,
            space: w * 0.08,
        });

        // Creators' gift
        this.add(this.scene.add.text(w * -0.33, h * 0.21, 'Подарок от создателей игры:').setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.025));

        const gifts = {
            'morion': 100, 
            'medkit': 5, 
        };

        UIController.arrangeItems({
            container: this,
            itemsData: gifts,
            x: w * -0.3,
            y: h * 0.265,
            iconSize: h * 0.04,
            fontSize: h * 0.025,
            space: w * 0.08,
        });
    }

    addText(w, h) {
        this.text = this.scene.add.text(0, 0, '').setOrigin(0, 0).setStyle({
            fontFamily: 'Game_Font',
            fontSize: h * 0.02,
            color: '#ffedbb',
            shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 },
            align: 'justify',
            wordWrap: { width: w * 0.66 },
        }).setLineSpacing(h * 0.02);

        const width = w * 0.66;
        const height = h * 0.45;

        this.add(this.contentText = this.scene.rexUI.add.textArea({
            x: 0,
            y: h * -0.38 + height / 2,
            width: width,
            height: height,
            origin: 0,

            // background: this.scene.rexUI.add.roundRectangle(0, 0, 0, 0, 0, 0x000000, 0.5),
            text: this.text,
            content: '',

            mouseWheelScroller: {
                focus: true,
                speed: 0.5,
            },

            space: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                text: {
                    top: height * 0.07,
                    bottom: height * 0.07
                },
                header: 0,
                footer: 0,
            }
        }).layout());
    }

    addButton(w, h) {
        const x = w * 0.25;
        const y = h * 0.25;
        const size = h * 0.205;

        this.add(this.buttonBg = this.scene.add.sprite(x, y, 'UI', 'long_button.png').setDisplaySize(size, size * 0.216));
        this.add(this.btnText = this.scene.add.text(x, y, 'Забрать').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.022));
        this.add(this.button = new ActionButton(this.scene, x, y, [], 'shop_button', size, size * 0.216, 'green', this.close.bind(this)));
        this.button.blink();
    }

    async update(category) {
        await super.update(category);

        const data = this.parentContainer.getData('data');
        this.contentText.setText(data['greeting_text']);
    }
    
    close() {
        this.parentContainer.close();
    }
}