import eventBus from "../../../services/eventBus";
import { ChangeSceneButton } from "./ChangeSceneButton";

export class ButtonJournal extends ChangeSceneButton {

    create() {
        const x = window.screen.height * 0.05;
        this.add(this.arrows = this.scene.add.container(x, 0));

        const dist = window.screen.height * 0.015;
        const arrowSize = window.screen.height * 0.035;

        for (let i = 0; i < 3; i++) {
            this.arrows.add(this.scene.add.sprite(i * dist, 0, 'UI', 'arrow.png')
                .setDisplaySize(arrowSize, arrowSize * 1.1).setRotation(Math.PI));
        }

        super.create();
        this.blinkOff();
    }

    blink(color, scene) {
        this.scene = scene;
        super.blink(color);
        this.scene.tweens.killTweensOf(this.arrows);
        this.arrows.each(arrow => arrow.setTint(color));
        this.startAnimation();
    }

    blinkOff() {
        super.blinkOff();
        this.scene.tweens.killTweensOf(this.arrows);
        this.arrows.setAlpha(0);
    }

    startAnimation() {
        this.arrows.setAlpha(1);
        this.arrows.setPosition(window.screen.height * 0.05, 0);

        this.arrowTween = this.scene.tweens.add({
            targets: this.arrows,
            x: window.screen.height * 0.04,
            duration: 700,
            loop: -1,
            yoyo: true,
        });
    }

    click() {
        super.click();
        localStorage.setItem('mission_completed', false);
    }
}