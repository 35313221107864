import InfoTables from "../../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../../scenes/ui/UI";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import Button from "../../../../../main/buttons/common/Button";

export default class AchievementCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, title, category, id) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.title = title;
        this.category = category;
        this.id = id;

        this.create(tw, th);
    }

    create(w, h) {
        this.add(this.cell = this.scene.add.image(0, 0, 'UI', 'achievement.png').setDisplaySize(h * 0.067 * 8.347, h * 0.067));

        const cw = this.cell.displayWidth;
        const ch = this.cell.displayHeight;

        this.add(this.icon = this.scene.add.image(cw * -0.43, 0, 'items_icons', `${this.category}.png`)
            .setDisplaySize(ch * 0.67, ch * 0.67));
        this.add(this.title = this.scene.add.text(cw * -0.35, ch * -0.1, this.title).setOrigin(0, 1));

        this.add(this.buttonIcon = this.scene.add.image(cw * 0.453, 0, 'items_icons', 'check.png')
            .setDisplaySize(ch * 0.4, ch * 0.4));
        this.add(this.button = new Button(this.scene, cw * 0.453, 0, [], 'table_button', ch * 0.78, ch * 0.78, 'green'));
        this.button.disable();

        this.add(this.progress = this.scene.add.text(cw * 0.38, ch * -0.1, '10/100').setOrigin(1, 1));
        this.add(this.frame = this.scene.add.image(cw * -0.431, 0, 'UI', 'table_button.png')
            .setDisplaySize(ch * 1.15, ch * 1.15));

        this.frame.setTint(0x79ff84);
        this.setSize(cw, ch);
        this.addRewards(cw, ch);
        this.reset();
    }

    addRewards(cw, ch) {
        this.add(this.morionIcon = this.scene.add.image(cw * -0.35, ch * 0.3, 'items_icons', `morion.png`)
            .setOrigin(0, 0.5).setDisplaySize(ch * 0.5, ch * 0.5));
        this.add(this.batteryIcon = this.scene.add.image(cw * -0.15, ch * 0.3, 'items_icons', `battery_units.png`)
            .setOrigin(0, 0.5).setDisplaySize(ch * 0.5, ch * 0.5));
        this.add(this.XPIcon = this.scene.add.image(cw * 0.05, ch * 0.3, 'items_icons', `player_XP.png`)
            .setOrigin(0, 0.5).setDisplaySize(ch * 0.5, ch * 0.5));

        this.add(this.morionText = this.scene.add.text(cw * -0.29, ch * 0.3, '100').setOrigin(0, 0.5));
        this.add(this.batteryText = this.scene.add.text(cw * -0.09, ch * 0.3, '100').setOrigin(0, 0.5));
        this.add(this.XPText = this.scene.add.text(cw * 0.11, ch * 0.3, '100').setOrigin(0, 0.5));
    }

    updateContent(data) {
        this.reset();
        this.achievementData = data;

        let done = data['progress'];
        let max = data['required'];
        const lvl = data['level'];

        if (done >= max) {
            this.complete();
        }

        this.progress.setText(`${done}/${max}`);
        this.morionText.setText(`${1 + 2 * (lvl - 1)}`);
        this.batteryText.setText(`${3 + 3 * (lvl - 1)}`);
        this.XPText.setText(`${10 + 20 * (lvl - 1)}`);

        this.lvl = lvl;

        if (done > 1000) done = Math.floor(done / 1000) + 'k';
        if (max > 1000) max = Math.floor(max / 1000) + 'k';
    }

    complete() {
        const ch = this.cell.displayHeight;

        this.progress.setStyle(TEXT_STYLE_GREEN).setFontSize(ch * 0.3);
        this.title.setStyle(TEXT_STYLE_GREEN).setFontSize(ch * 0.3);

        this.morionText.setStyle(TEXT_STYLE_GREEN).setFontSize(ch * 0.3);
        this.batteryText.setStyle(TEXT_STYLE_GREEN).setFontSize(ch * 0.3);
        this.XPText.setStyle(TEXT_STYLE_GREEN).setFontSize(ch * 0.3);

        this.frame.setAlpha(1);
        this.button.makeInteractive();
        this.button.blink();

        this.button.off('click').on('click', () => this.claimRewards());
    }


    async claimRewards() {
        const data = await apiClient.achievements.claimRewards({ id: this.id + 1 });
        this.scene.game.registry.set('items', data['items']);
        this.updateContent(data['achievement']);
        updateUI(this.scene);

        this.scene.events.emit('profile-level-up');
    }

    reset() {
        const ch = this.cell.displayHeight;

        this.progress.setStyle(TEXT_STYLE_BLUE).setFontSize(ch * 0.3);
        this.title.setStyle(TEXT_STYLE_ORANGE).setFontSize(ch * 0.3);

        this.morionText.setStyle(TEXT_STYLE_BLUE).setFontSize(ch * 0.3);
        this.batteryText.setStyle(TEXT_STYLE_BLUE).setFontSize(ch * 0.3);
        this.XPText.setStyle(TEXT_STYLE_BLUE).setFontSize(ch * 0.3);

        this.frame.setAlpha(0.001);
        this.button.disable();
    }

    isAvailable() {
        const data = this.achievementData;
        return data['progress'] >= data['required'];
    }
}