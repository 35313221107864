import barrierData from "../../../../../../config/scenes/barrierData";
import { TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import { ChangeSceneButton } from "../../../../../main/buttons/ChangeSceneButton";
import LookoutSectionItem from "./LookoutSectionItem";

export default class LookoutSection extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, area, cellData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.width = tw * 0.65;
        this.height = th * 0.18;
        this.area = area;
        this.cellData = cellData;

        this.create(tw, th);
    }

    create(w, h) {
        this.add(this.buttonFrame = this.scene.add.sprite(w * 0.29, 0, 'UI', 'hex.png').setDisplaySize(h * 0.045, h * 0.045));
        this.add(this.buttonScene = new ChangeSceneButton(this.scene, w * 0.29, 0, [], 'table_button', h * 0.045, h * 0.045, 'green', this.cellData['scene']));
        this.add(this.locationMark = this.scene.add.sprite(w * 0.29, 0, 'battle_ui', 'location_mark.png').setDisplaySize(h * 0.025, h * 0.025).setTintFill(0x79ff84));

        this.add(this.areaName = this.scene.add.text(w * -0.3, h * -0.01, barrierData[this.area]['name']).setOrigin(0, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.022));

        this.add(this.line = this.scene.add.line(0, 0, 0, 0, 0, 0, 0xb0f1ff).setAlpha(0.8));
        this.line.setTo(w * -0.3, 0, w * 0.27, 0);

        this.addCells();
    }

    update(data) {
        this.reset();
        this.cells.forEach(cell => cell.update(data));
    }

    addCells() {
        const amount = this.cellData['max'];
        const w = this.tw;
        const h = this.th;
        let dist = 0;
        this.cells = [];

        for (let i = 0; i < amount; i++) {
            let cell = new LookoutSectionItem(this.scene, w * -0.3 + dist, h * 0.07, [], w, h, i);

            cell.update(this.cellData);
            this.cells.push(cell);
            this.add(cell);

            dist += h * 0.22;
        }
    }

    reset() {
        this.cells.forEach(cell => {
            if (cell.isOpened) {
                cell.reset();
            }
        });
    }

    prepare() {
        this.cells.forEach(cell => {
            if (cell.isOpened) {
                cell.prepare();
            }
        });
    }

    getLookoutCount() {
        return this.cells.filter(c => !c.isEmpty).length;
    }
}