import { TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE } from "../../../../utils/textStyles";
import Button from "../../../main/buttons/common/Button";

export default class JournalScrollItem extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, texture, frame, name, id = 0) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.texture = texture;
        this.frame = frame;
        this.name = name;
        this.id = id;

        this.create(window.screen.height * 0.07 * 6, window.screen.height * 0.07);
    }

    create(w, h) {
        this.add(this.cell = this.scene.add.image(0, 0, 'journal_UI', 'task_cell.png').setDisplaySize(w, h));

        this.add(this.icon = this.scene.add.image(-w / 2, 0, this.texture, `${this.frame}.png`)
            .setOrigin(0, 0.5).setDisplaySize(h * 1.15, h * 1.15));

        this.add(this.iconFrame = this.scene.add.image(-w / 2, 0, 'UI', 'table_button.png')
            .setOrigin(0, 0.5).setDisplaySize(h * 1.15, h * 1.15).setTint(0x79ff84).setAlpha(0));

        this.add(this.name = this.scene.add.text(w * -0.29, h * -0.1, this.name)
            .setOrigin(0, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.25));

        this.add(this.completeText = this.scene.add.text(w * -0.29, h * 0.1, 'Забрать награду')
            .setOrigin(0, 0).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.25).setAlpha(0));

        this.add(this.button = new Button(this.scene, w * 0.442, 0, [], 'table_button', h * 0.7, h * 0.7, 'green'));

        this.add(this.buttonIcon = this.scene.add.image(w * 0.442, 0, 'items_icons', 'story.png')
            .setDisplaySize(h * 0.4, h * 0.4));

        this.setSize(w, h);
        this.button.off('click').on('click', this.handleButtonClick, this);
    }

    handleButtonClick() {
        // this.table.table.curTaskId = this.id;
        // console.log(this.table.table.curTaskId);
        const page = this.parentContainer.parentContainer;

        page.info.displayCurrentInfo(this.frame, this.id, this.isComplete, this);
        for (let item of page.scroll.scroll.items) {
            item.button.deselect();
        }
        this.button.select();
    }

    complete() {
        this.iconFrame.alpha = 1;
        this.button.blink();
        this.name.setStyle(TEXT_STYLE_GREEN).setFontSize(window.screen.height * 0.07 * 0.25);
        this.completeText.alpha = 1;

        this.isComplete = true;
    }

    claim() {
        this.iconFrame.setAlpha(0);
        this.cell.alpha = 0.5;
        this.icon.alpha = 0.5;
        this.completeText.alpha = 1;
        this.name.setColor('#9B9B9B');
        this.completeText.setText('Выполнено').setColor('#9B9B9B');
        this.isComplete = false;
    }
}