const achievementData = {
    1: {
        name: "Восстановить строения",
        category: "upgrade",
    },
    2: {
        name: "Улучшить строения",
        category: "upgrade",
    },
    3: {
        name: "Добыть ресурсы",
        category: "production",
    },
    4: {
        name: "Начать производство",
        category: "production",
    },
    5: {
        name: "Произвести предметы",
        category: "production",
    },
    6: {
        name: "Выиграть бой",
        category: "fight",
    },
    7: {
        name: "Уничтожить врагов",
        category: "fight",
    },
    8: {
        name: "Клонировать",
        category: "embryo",
    },
    9: {
        name: "Мутировать",
        category: "mutant",
    },
    10: {
        name: "Облучить",
        category: "irradiated",
    },
    11: {
        name: "Тренировать солдата",
        category: "soldier",
    },
    12: {
        name: "Вылечить солдат",
        category: "heal",
    },
    13: {
        name: "Повысить уровень армии",
        category: "upgrade",
    },
    14: {
        name: "Улучшить снаряжение",
        category: "upgrade",
    },
    15: {
        name: "Зайти в игру (дней)",
        category: "calendar",
    },
    16: {
        name: "Провести часов в игре",
        category: "clock",
    },
    17: {
        name: "Использовать апречки",
        category: "medkit",
    },
}

export default achievementData;