import loadAssets from '../../../../../../managers/AssetManager.js';
import InfoTables from '../../../../../../scenes/ui/InfoTables.js';
import { updateUI } from '../../../../../../scenes/ui/UI.js';
import apiClient from '../../../../../../services/apiClient.js';
import { TEXT_STYLE_BLUE, TEXT_STYLE_ORANGE } from '../../../../../../utils/textStyles.js';
import { setImageSizeByHeight } from '../../../../../../utils/utils.js';
import ActionButton from '../../../../../main/buttons/common/ActionButton.js';
import UnitIcon from '../../../../../main/icons/UnitIcon.js';
import InfoTableMainScroll from '../../../../common/scroll/InfoTableMainScroll.js';
import UnitTable from '../common/UnitTable.js';


export default class ArmyUnitsTable extends UnitTable {

    async create() {
        super.create();

        const w = this.width;
        const h = this.height;

        // Добавляем скроллинг и объявляем колбэк добавления юнитов
        this.add(this.unitScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let index in data) {
                if (['lookout', 'healing'].includes(data[index]['status'])) {
                    continue;
                }
                let unit = new UnitIcon(this.scene, 0, 0, [], 'button', h * 0.1, h * 0.1);
                unit.setUnitData(data[index], index);

                // Колбэк при клике на иконку юнита
                unit.setClickCallback(() => {
                    this.unitScroll.deselectAll();
                    unit.select();
                    this.selectedUnit = unit;
                    this.selectedUnitIndex = unit.index;

                    // Обновление информации о юните в обеих табличках
                    this.updateInfo({ unit: unit.unitData });

                    this.evolutionTable = this.detectEvolutionTable();
                    if (this.evolutionTable) {
                        this.evolutionTable.updateInfo(unit.unitData);
                    }
                });

                this.unitScroll.addItem(unit);
            }

        }));

        this.on('setvisible-true', () => this.add(this.unitScroll));
    }

    detectEvolutionTable() {
        // Перемещение скроллинга из одной таблички в другую при их смене (пока так, потом может придумаю получше)
        const table = this.parentContainer.tableLeft;

        if (table && table.texture.includes('_evolution')) {
            table.on('setvisible-true', () => table.addUnitScroll(this.unitScroll));
            table.on('setvisible-false', () => {
                table.removeUnitScroll();
                this.add(this.unitScroll);
            });
            return table;
        }

        return null;
    }

    async update(category) {
        this.unitCategory = this.texture;
        const data = await apiClient.units.getByCategory({ category: this.unitCategory });
        await this.loadData(this.unitCategory);
        await super.update(category);

        if (this.unitCategory == 'robot') {
            return;
        }

        this.fullBodyImage.setVisible(data['units'].length != 0);
        this.updateUnitScroll(data['units']);
    }

    async loadData(category) {
        const texture = 'full_body_' + category;

        // Для каждого типа юнитов надо сделать отдельный атлас
        await loadAssets(this.scene, 'atlas', texture,
            `UI/info_tables/common/units/full_body_images/${category}.png`,
            `UI/info_tables/common/units/full_body_images/${category}.json`);

        // Пока так, потом может придумаю что-нибудь получше
        if (category == 'soldier') {
            await loadAssets(this.scene, 'atlas', texture + '_extra',
                `UI/info_tables/common/units/full_body_images/soldiers_extra.png`,
                `UI/info_tables/common/units/full_body_images/soldiers_extra.json`);

            await loadAssets(this.scene, 'atlas', 'full_body_hero',
                `UI/info_tables/common/units/full_body_images/hero.png`,
                `UI/info_tables/common/units/full_body_images/hero.json`);
        }
    }

    addContent(w, h) {
        super.addContent(w, h);
        this.add(this.fullBodyImage = this.scene.add.sprite(w * -0.08, h * 0.25, '').setOrigin(0.5, 0.95));

        this.XP.setPosition(w * -0.225, h * -0.28);
        this.params.level.setPosition(w * -0.035, h * -0.087);

        this.add(this.levelUpButton = new ActionButton(this.scene, w * 0.0558, h * -0.305, [], 'table_button', h * 0.057, h * 0.057, 'green', this.levelUp.bind(this)));
        this.add(this.morionText = this.scene.add.text(w * 0.255, h * -0.29, '').setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.025));

        const text = 'У вас нет юнитов данного типа, либо они заняты другими делами';

        this.add(this.infoText = this.scene.add.text(0, h * 0.29, text).setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.022).setVisible(false));
    }

    setUnitSize() {
        setImageSizeByHeight(this.fullBodyImage, this.height * 0.65);
    }

    addHealingItemInfo(w, h) {
        super.addHealingItemInfo(w, h);
        this.buttonHeal.setPosition(w * -0.1985, h * 0.218);
        this.healItemAmount.setPosition(w * -0.276, h * 0.213);
        this.healItemIncreaseText.setPosition(w * -0.254, h * 0.235);
        this.healItemIcon.setPosition(w * -0.268, h * 0.235);
    }

    updateInfo(data) {
        super.updateInfo(data);

        if (!data) {
            return;
        }

        this.name.setText(data['unit']['name']);
        this.fullBodyImage.setTexture(`full_body_${this.unitCategory}`);

        // Если солдат, кадр - ранг
        if (this.unitCategory == 'soldier') {
            if (!Number.isInteger(data['unit']['rank'])) {
                this.fullBodyImage.setTexture(`full_body_${this.unitCategory}_extra`);
            }
            this.morionText.setText(Math.ceil(50 * 1.2 * data['unit']['level'] - 1));
            this.fullBodyImage.setFrame(data['unit']['rank'] + '.png');
        } else {
            // пока просто common.png (потом изменю, когда появятся разные типы мутантов и облученных)
            this.fullBodyImage.setFrame('common.png');
        }

        // Размеры картинки можно менять только после назначения текстуры
        this.setUnitSize();
    }

    updateUnitScroll(data) {
        this.infoText.setVisible(false);
        this.fullBodyImage.setVisible(true);
        this.unitScroll.updateContent(data);

        if (this.unitScroll.getAll().length == 0) {
            this.showPlaceholder();
            return;
        }

        const unit = this.unitScroll.getItem(this.selectedUnitIndex || 0);
        unit && unit.click();
    }

    showPlaceholder() {
        this.infoText.setVisible(true);
        this.fullBodyImage.setVisible(false);
    }

    levelUp() {
        InfoTables.openConfirmationTable({
            title: 'Армия',
            text: 'Повысить уровень юнита за 50 мориона?',
        }, this.confirmLevelUp.bind(this));
    }

    async confirmLevelUp() {
        const data = await apiClient.units.morionLevelUp({ id: this.getData('unit')['id'] });
        console.log(data);

        InfoTables.showWarningText(data['message']);
        updateUI(this.scene);

        this.update();
    }

    displayParamsIncrease(key) {
        const params = this.params;
        
        switch (key) {
            case 'injector_health':
            case 'mutagen_health':
            case 'crystall_health':
                params['health'].setText(params['health'].text + ' +25');
                break;
            case 'injector_damage':
            case 'mutagen_damage':
            case 'crystall_damage':
                params['damage'].setText(params['damage'].text + ' +5');
                break;
            case 'injector_accuracy':
            case 'mutagen_accuracy':
            case 'crystall_accuracy':
                params['accuracy'].setText(params['accuracy'].text + ' +1');
                break;
            case 'injector_speed':
            case 'mutagen_speed':
            case 'crystall_speed':
                params['speed'].setText(params['speed'].text + ' +1');
                break;
            case 'injector_range':
            case 'mutagen_range':
            case 'crystall_range':
                params['range'].setText(params['range'].text + ' +1');
                break;
        }
    }

    resetParamsIncrease() {
        this.params.update(this.getData('unit'));
    }
}



