import BattleUnit from "../BattleUnit";

export default class BattleBeles extends BattleUnit {

    create(unitData, linkedIcon) {
        super.create(unitData, linkedIcon);
        this.getData('config')['XP_cost'] = unitData['xp_cost'];
    }

    checkForThreat() {
        this.findTarget();
    }
}