import loadAssets from "../../../../../managers/AssetManager";
import InfoTables from "../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../scenes/ui/UI";
import apiClient from "../../../../../services/apiClient";
import { TEXT_STYLE_BLUE } from "../../../../../utils/textStyles";
import { divideUnitsByCategory } from "../../../../../utils/units";
import ActionButton from "../../../../main/buttons/common/ActionButton";
import ItemIcon from "../../../../main/icons/ItemIcon";
import UnitIcon from "../../../../main/icons/UnitIcon";
import InfoTableMainScroll from "../../../common/scroll/InfoTableMainScroll";
import ArmyUnitsContainer from "../army/ArmyUnitsContainer";
import SoldierMiniTable from "../units/army_units/evolution/common/mini_tables/SoldierMiniTable";
import BuildingTable from "./BuildingTable";
import InjectionCell from "./hospital/InjectionCell";

export default class HospitalTable extends BuildingTable {

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        this.addContent(w, h);
        this.add(this.unitMiniTable = new SoldierMiniTable(this.scene, w * 0.1625, h * 0.16, [], w * 0.253, h * 0.29));

        this.add(this.mainScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let index in data) {
                let unit = new UnitIcon(this.scene, 0, 0, [], 'button', h * 0.1, h * 0.1);
                unit.setUnitData(data[index], index);

                // Колбэк при клике на иконку юнита
                unit.setClickCallback(() => {
                    if (this.unitCell.count() < this.unitCell.availableCells) {
                        this.selectUnit(unit);
                    } else {
                        InfoTables.showWarningText('Нет места');
                    }
                });

                unit.setPointerOverCallback(() => {
                    this.unitMiniTable.show(data[index], { rank: data[index]['rank'] });
                });

                unit.setPointerOutCallback(() => {
                    this.unitMiniTable.hide();
                })

                this.mainScroll.addItem(unit);
            }
        }));

        this.add(this.itemsScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let key in data) {
                if (!key.includes('injector')) continue;

                let icon = new ItemIcon(this.scene, 0, 0, [], 'items_icons', h * 0.06, h * 0.06);
                icon.setIconData({ name: key, amount: data[key] });

                // Колбэк при клике на иконку
                icon.setClickCallback(() => {
                    console.log('item click');
                    this.selectInjection(icon);
                });

                this.itemsScroll.addItem(icon);
            }
        }, {
            x: w * 0.182,
            y: h * 0.185,
            width: w * 0.27,
            height: h * 0.255,
            mode: 'vertical',
            space: h * 0.02,
            top: h * 0.01,
            bottom: h * 0.01,
            fixWidth: true,
            type: 'custom'
        }));
    }

    async update(category) {
        await super.update(category);
        await this.loadData(this.unitCategory);

        const data = await apiClient.units.getAll();

        this.units = divideUnitsByCategory(data['units'])['soldier'];
        this.updateMainScroll(this.units);

        this.injections = this.scene.game.registry.get('items');
        this.itemsScroll.updateContent(this.injections);
        this.unitCell.clear();
        this.injectionCell.clear();
    }

    async loadData() {
        await loadAssets(this.scene, 'atlas', 'full_body_soldier_extra',
            `UI/info_tables/common/units/full_body_images/soldiers_extra.png`,
            `UI/info_tables/common/units/full_body_images/soldiers_extra.json`);

        await loadAssets(this.scene, 'atlas', 'full_body_soldier',
            `UI/info_tables/common/units/full_body_images/soldier.png`,
            `UI/info_tables/common/units/full_body_images/soldier.json`);
    }

    updateMainScroll(data) {
        this.mainScroll.updateContent(data);
    }

    updateItemsScroll(data) {
        this.itemsScroll.updateContent(data);
    }

    addContent(w, h) {
        this.add(this.unitCell = new ArmyUnitsContainer(this.scene, w * 0.132, h * -0.213, [], w, h));
        this.add(this.injectionCell = new InjectionCell(this.scene, w * 0.202, h * -0.213, [], w, h));
        this.add(this.injectButton = new ActionButton(this.scene, w * 0.0772, h * -0.213, [], 'button_up', h * 0.048, h * 0.055, 'green', () => {
            this.injectUnit();
        }));
        this.injectButton.disable();

        this.add(this.buyMedkitBtn = new ActionButton(this.scene, w * -0.066, h * 0.212, [], 'table_button', h * 0.063, h * 0.063, 'green', this.buyMedkit.bind(this)));
        this.add(this.morionText = this.scene.add.text(w * -0.22, h * 0.25, '15').setStyle(TEXT_STYLE_BLUE).setOrigin(0, 1));

        const text = `
        Здесь вы можете ввести солдату один из нескольких типов инъекций, которые повышают определенные параметры юнита.
        
        Количество вводимых инъекций не ограничено, и действие препарата сохраняется навсегда`;

        this.add(this.text = this.scene.add.text(w * 0.047, h * -0.16, text).setOrigin(0, 0).setStyle({
            fontFamily: 'Game_Font',
            fontSize: h * 0.02,
            color: '#ffedbb',
            shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 },
            align: 'justify',
            wordWrap: { width: w * 0.275 },
        }).setLineSpacing(h * 0.001));
    }

    selectUnit(unit) {
        if (this.unitCell.count() == 1) {
            InfoTables.showWarningText('Солдат уже выбран');
            return;
        }

        const unitArray = this.units;
        const id = unitArray.indexOf(unit.unitData);
        if (id != -1) unitArray.splice(id, 1);

        let selectedUnit = new UnitIcon(this.scene, 0, 0, [], 'button', this.height * 0.112, this.height * 0.112);
        selectedUnit.setUnitData(unit.unitData, unit.index);

        selectedUnit.setClickCallback(() => {
            this.unitCell.removeUnit(selectedUnit);
            unitArray.push(selectedUnit.unitData);
            this.updateMainScroll(unitArray);
            this.checkInjectAvailable();
        });

        this.updateMainScroll(unitArray);
        this.unitCell.add(selectedUnit, true);

        this.checkInjectAvailable();
    }

    selectInjection(injection) {
        const name = injection.getData('name');
        const items = this.scene.game.registry.get('items');

        if (items[name] <= 0) {
            InfoTables.showWarningText('У вас нет инъекций данного типа');
            return;
        }

        this.injectionCell.addInjection(name, injection);
        this.checkInjectAvailable();
    }

    checkInjectAvailable() {
        const injectionSelected = !this.injectionCell.isFree;
        const unitSelected = this.unitCell.count() == 1;

        if (!injectionSelected || !unitSelected) {
            this.injectButton.disable();
            return;
        }
        this.injectButton.makeInteractive();
        this.injectButton.blink();
    }

    async injectUnit() {
        const unitId = this.unitCell.first.unitData['id'];
        const item = this.injectionCell.icon.getData('name');

        const data = await apiClient.units.inject({ id: unitId, item: item });
        InfoTables.showWarningText(data['message']);

        this.injectionCell.removeInjection();

        this.updateItemsScroll(data['items']);
    }

    async buyMedkit() {
        const data = await apiClient.units.buyMedkit({});

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(data['message']);
        this.scene.game.registry.set('items', data['items']);
        updateUI(this.scene);
    }
}