import AudioManager from "../../../../../managers/AudioManager";
import Bullet from "../../common/Bullet";
import BattleCharacter from "./BattleCharacter";

export default class BattleMainHero extends BattleCharacter {
    
    setUnitData(unitData, linkedIcon) {
        super.setUnitData(unitData, linkedIcon);
        this.getData('unit')['damage'] *= 2;
        this.getData('config')['close_fight_anims'] = ['close_fight_1', 'close_fight_2', 'close_fight_3'];
        this.getData('config')['attack_frames'] = [6, 8];
    }

    die() {
        super.die();
        AudioManager.playSound('death', 'main_hero');
    }

    shoot(x, y, dir, target) {
        const w = this.unitSprite.displayWidth;

        const positions = {
            'right': { x: w * 0.3, y: w * -0.2, x1: w * 0.3, y1: w * -0.25 },
            'left': { x: w * -0.3, y: w * -0.2, x1: w * -0.3, y1: w * -0.25 },
            'down-r': { x: w * 0.1, y: w * -0.2, x1: w * 0.2, y1: w * -0.2 },
            'down-l': { x: w * -0.1, y: w * -0.2, x1: w * -0.2, y1: w * -0.2 },
            'up-r': { x: w * 0.15, y: w * -0.25, x1: w * 0.05, y1: w * -0.3 },
            'up-l': { x: w * -0.15, y: w * -0.25, x1: w * -0.05, y1: w * -0.3 },
            'up': { x: w * -0.1, y: w * -0.3, x1: w * 0.1, y1: w * -0.3 },
            'down': { x: w * -0.1, y: w * 0.2, x1: w * 0.1, y1: w * 0.2 },
        }

        const bullet1 = new Bullet(this.scene, x + positions[dir].x, y + positions[dir].y, 'UI', 'MC_bullet.png');
        const bullet2 = new Bullet(this.scene, x + positions[dir].x1, y + positions[dir].y1, 'UI', 'MC_bullet.png');

        const localPos = target.unitSprite.displayWidth / 2;

        bullet1.create(x, y, target.x + localPos, target.y + localPos);
        bullet1.move(target.x, target.y);
        
        bullet2.create(x, y, target.x + localPos, target.y + localPos);
        bullet2.move(target.x, target.y);

        AudioManager.playSound('attack', 'main_hero');
    }
}