import AudioManager from "../../../../managers/AudioManager";
import Bullet from "../common/Bullet";
import BattleUnit from "./BattleUnit";

export default class BattleSoldier extends BattleUnit {

    setUnitData(unitData, linkedIcon) {
        super.setUnitData(unitData, linkedIcon);
        let frames = [4];

        switch (this.getData('unit')['rank']) {
            case 3.1:
            case 3.2:
                frames = [2, 6];
                break;
            case 5.1:
            case 5.2:
                frames = [2];
                break;
        }

        this.getData('config')['attack_frames'] = frames;
    }

    shoot(x, y, dir, target) {
        const w = this.unitSprite.displayWidth;
        const rank = this.getData('unit')['rank'];

        let texture = 'bullet.png';
        let positions = {
            'right': { x: w * 0.4, y: w * -0.2 },
            'left': { x: w * -0.4, y: w * -0.2 },
            'down-r': { x: w * 0.25, y: w * -0.1 },
            'down-l': { x: w * -0.25, y: w * -0.1 },
            'up-r': { x: w * 0.4, y: w * -0.35 },
            'up-l': { x: w * -0.4, y: w * -0.35 },
            'up': { x: w * 0.07, y: w * -0.5 },
            'down': { x: w * 0, y: w * 0.2 },
        };

        switch (rank) {
            case 3.1:
            case 3.2:
                if (this.unitSprite.anims.currentFrame.index == 2) {
                    positions = {
                        'right': { x: w * 0.305, y: w * -0.327 },
                        'left': { x: w * -0.305, y: w * -0.327 },
                        'down-r': { x: w * 0.266, y: w * -0.155 },
                        'down-l': { x: w * -0.266, y: w * -0.155 },
                        'up-r': { x: w * 0.15, y: w * -0.4 },
                        'up-l': { x: w * -0.15, y: w * -0.4 },
                        'up': { x: w * 0.0625, y: w * -0.44 },
                        'down': { x: w * 0.07, y: w * 0.1 },
                    }
                } else {
                    positions = {
                        'right': { x: w * 0.246, y: w * -0.268 },
                        'left': { x: w * -0.246, y: w * -0.268 },
                        'down-r': { x: w * 0.14, y: w * -0.182 },
                        'down-l': { x: w * -0.14, y: w * -0.182 },
                        'up-r': { x: w * 0.226, y: w * -0.38 },
                        'up-l': { x: w * -0.226, y: w * -0.38 },
                        'up': { x: w * 0.07, y: w * -0.44 },
                        'down': { x: w * -0.078, y: w * -0.1625 },
                    }
                }
                break;
            case 5.1:
            case 5.2:
                texture = 'balls_bullet.png';
                positions = {
                    'right': { x: w * 0.34, y: w * -0.2 },
                    'left': { x: w * -0.34, y: w * -0.2 },
                    'down-r': { x: w * 0.19, y: 0 },
                    'down-l': { x: w * -0.19, y: 0 },
                    'up-r': { x: w * 0.28, y: w * -0.34 },
                    'up-l': { x: w * -0.28, y: w * -0.34 },
                    'up': { x: w * 0.074, y: w * -0.376 },
                    'down': { x: w * -0.074, y: w * -0.06 },
                }
                break;
            case 7.1:
            case 7.2:
                positions = {
                    'right': { x: w * 0.26, y: w * -0.06 },
                    'left': { x: w * -0.26, y: w * -0.06 },
                    'down-r': { x: w * 0.137, y: w * -0.037 },
                    'down-l': { x: w * -0.137, y: w * -0.037 },
                    'up-r': { x: w * 0.26, y: w * -0.16 },
                    'up-l': { x: w * -0.26, y: w * -0.16 },
                    'up': { x: w * 0.08, y: w * -0.25 },
                    'down': { x: w * -0.08, y: w * 0.05 },
                }
                break;
        }

        const bullet = new Bullet(this.scene, x + positions[dir].x, y + positions[dir].y, 'UI', texture);
        if ([7.1, 7.2].includes(rank)) {
            bullet.setTint(0x79ff84);
        }

        bullet.create(x, y, target.x + target.unitSprite.displayWidth / 2, target.y + target.unitSprite.displayWidth / 2);
        bullet.move(target.x, target.y);

        AudioManager.playSound('attack', 'soldier');
    }

    die() {
        super.die();
        AudioManager.playSound('death', 'soldier');
    }
}