import loadAssets from "../../../../../../../../../managers/AssetManager";
import { TEXT_STYLE_GREEN } from "../../../../../../../../../utils/textStyles";

export default class UnitMiniTable extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, w, h) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.width = w;
        this.height = h;

        this.create(w, h);
    }

    async create(w, h) {
        await this.loadData();

        this.add(this.bg = this.scene.add.image(0, 0, 'unit_mini_table')
            .setOrigin(0, 0).setDisplaySize(this.width, this.height));

        this.add(this.unitImage = this.scene.add.sprite(w * 0.6, h * 0.91, 'full_body_soldier', '0.png').setOrigin(0, 1));

        this.paramKeys = ['health', 'armor', 'damage', 'accuracy', 'speed', 'range'];
        this.params = [];

        let dist = 0;

        this.paramKeys.forEach(key => {
            this.params.push(this[key] = this.scene.add.text(0, 0, '-')
                .setOrigin(0.5, 1).setPosition(w * 0.38, h * 0.21 + dist)
                .setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.05));

            dist += h * 0.117;
        });

        this.add(this.params);
        this.hide();
    }

    async loadData() {
        await loadAssets(this.scene, 'image', 'unit_mini_table',
            `UI/info_tables/common/units/mini_tables/unit_mini_table.png`);
    }

    hide() {
        this.setVisible(false);
    }

    show(unitData, data) {
        this.setVisible(true);
        this.updateContent(unitData, data);
    }

    updateContent(unitData, data) {
        this.paramKeys.forEach(key => {
            this[key].setText(`${unitData[key]}`);
        });

        this.setUnitSize();
    }

    setUnitSize() {
        const img = this.unitImage;
        const dh = this.height * 0.8;
        const aspectRatio = img.width / img.height;
        img.setDisplaySize(dh * aspectRatio, dh);
    }
}