import BattleUnit from "../BattleUnit";

export default class BattleCharacter extends BattleUnit {

    create(unitData, linkedIcon) {
        super.create(unitData, linkedIcon);
        const config = this.getData('config');
        
        config['weakness'] = [];
    }
}