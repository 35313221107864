import apiClient from "../../../../../../../services/apiClient";
import eventBus from "../../../../../../../services/eventBus";
import ItemIcon from "../../../../../../main/icons/ItemIcon";

export default class ParameterCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, table, category) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.table = table; 
        this.category = category; // Категория ячейки (параметр)

        this.free = true;
        this.level = null;

        this.create(tw, th);
    }

    create(w, h) {
        this.add(this.frame = this.scene.add.sprite(0, 0, 'UI', 'table_button.png').setOrigin(0.5, 0.5));
        this.frame.setDisplaySize(h * 0.07, h * 0.07);
        this.frame.setRotation(Math.PI / 2);

        this.frame.setTint(0xffcb6a).setAlpha(0.001);
    }

    addModule(key, unitId) {
        this.add(this.module = new ItemIcon(this.scene, 0, 0, [], 'soldier_module_icons', this.th * 0.04, this.th * 0.04));
        this.module.setIconData({ name: key, amount: 0 });
        this.module.obj.setOrigin(0.5, 0.5);

        this.module.amountText.destroy();

        this.moduleName = key;
        this.unitId = unitId;
        this.frame.setAlpha(1);
        this.free = false;

        this.module.setClickCallback(this.removeModule.bind(this));
    }

    async removeModule() {
        const data = await apiClient.units.soldiers.removeModule({
            module_name: this.moduleName,
            soldier_id: this.unitId
        });

        if (data['status'] === 'success') {
            this.scene.game.registry.set('items', data['items']);
            this.table.update();
            this.clear();
            eventBus.emit('useModule');
        }
    }

    clear() {
        if (this.module) {
            this.remove(this.module);
            this.module.destroy();
            this.frame.setAlpha(0.001);
            this.free = true;
        }
    }
}