const terrainData = {
    'highland': {
        units: {
            1: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 100 },
            ],
            2: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 60 },
                { type: 'units', category: 'animal', name: 'zazulic_soldier', chance: 40 },
            ],
            3: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 40 },
                { type: 'units', category: 'animal', name: 'zazulic_soldier', chance: 40 },
                { type: 'units', category: 'animal', name: 'pichmog', chance: 20 },
            ],
            4: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 40 },
                { type: 'units', category: 'animal', name: 'zazulic_soldier', chance: 40 },
                { type: 'units', category: 'animal', name: 'pichmog', chance: 20 },
            ],
        }
    },
    'steppe': {
        units: {
            1: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 60 },
                { type: 'units', category: 'animal', name: 'pichmog', chance: 20 },
                { type: 'units', category: 'animal', name: 'vugda', chance: 20 },
            ],
            2: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 60 },
                { type: 'units', category: 'animal', name: 'pichmog', chance: 20 },
                { type: 'units', category: 'animal', name: 'vugda', chance: 20 },
            ],
            3: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 60 },
                { type: 'units', category: 'animal', name: 'pichmog', chance: 20 },
                { type: 'units', category: 'animal', name: 'vugda', chance: 20 },
            ],
            4: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 60 },
                { type: 'units', category: 'animal', name: 'pichmog', chance: 20 },
                { type: 'units', category: 'animal', name: 'vugda', chance: 20 },
            ],
        }
    },
    'forest': {
        units: {
            1: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 60 },
                { type: 'units', category: 'animal', name: 'pichmog', chance: 20 },
                { type: 'units', category: 'animal', name: 'beles', chance: 20 },
            ],
            2: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 60 },
                { type: 'units', category: 'animal', name: 'pichmog', chance: 20 },
                { type: 'units', category: 'animal', name: 'beles', chance: 20 },
            ],
            3: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 60 },
                { type: 'units', category: 'animal', name: 'pichmog', chance: 20 },
                { type: 'units', category: 'animal', name: 'beles', chance: 20 },
            ],
            4: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 60 },
                { type: 'units', category: 'animal', name: 'pichmog', chance: 20 },
                { type: 'units', category: 'animal', name: 'beles', chance: 20 },
            ],
        }
    },
    'genetic': {
        units: {
            1: [
                { type: 'units', category: 'unit', name: 'mutant', chance: 1 },
            ],
        }
    },
    'research': {
        units: {
            1: [
                { type: 'units', category: 'unit', name: 'irradiated', chance: 1 },
            ],
        }
    },
    'cave': {
        units: {
            1: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 100 },
            ],
            2: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 60 },
                { type: 'units', category: 'animal', name: 'zazulic_soldier', chance: 40 },
            ],
            3: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 50 },
                { type: 'units', category: 'animal', name: 'zazulic_soldier', chance: 50 },
            ],
            4: [
                { type: 'units', category: 'animal', name: 'zazulic', chance: 40 },
                { type: 'units', category: 'animal', name: 'zazulic_soldier', chance: 60 },
            ],
        }
    },
};

export default terrainData;