import { OpenInfoTableButton } from "./common/UIButtons";

export class ButtonBattles extends OpenInfoTableButton {

    create() {
        this.add(this.arrows = this.scene.add.container());
        this.arrows.setAlpha(0);

        const x = window.screen.height * 0.04;
        const dist = window.screen.height * 0.015;
        const arrowSize = window.screen.height * 0.035;

        for (let i = 0; i < 3; i++) {
            this.arrows.add(this.scene.add.sprite(-x - i * dist, 0, 'UI', 'arrow.png')
                .setDisplaySize(arrowSize, arrowSize * 1.1).setRotation().setTint(0xFF5656));
        }

        super.create();
        if (this.scene.game.registry.get('battlesChecked') == true) {
            return;
        }
        this.blink(0xFF5656);
    }

    blink(color) {
        super.blink(color);
        this.startAnimation();
    }

    blinkOff() {
        super.blinkOff();
        this.arrowTween && this.arrowTween.remove();
        this.arrows.setAlpha(0);
    }

    startAnimation() {
        this.arrows.setAlpha(1);
        this.arrowTween = this.scene.tweens.add({
            targets: this.arrows,
            x: window.screen.height * -0.01,
            duration: 700,
            loop: -1,
            yoyo: true,
        });
    }

    click() {
        super.click();
        this.scene.game.registry.set('battlesChecked', true);
    }
}