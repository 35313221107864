import mapMachineData from "../../../../../../config/machines/mapMachineData";
import sceneNames from "../../../../../../config/scenes/sceneNames";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import { ChangeSceneButton } from "../../../../../main/buttons/ChangeSceneButton";
import ActionButton from "../../../../../main/buttons/common/ActionButton";
import Button from "../../../../../main/buttons/common/Button";
import OreMiningStatsSectionItem from "./OreMiningStatsSectionItem";

export default class OreMiningStatsSection extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, targetScene, cellData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.width = width;
        this.height = height;
        this.cellData = cellData;
        this.targetScene = targetScene;

        this.create(width, height);
    }

    create(w, h) {
        this.add(this.cellBg = this.scene.add.image(0, 0, 'UI', 'store_cell.png').setDisplaySize(w, h).setOrigin(0.5, 0.5));

        this.add(this.buttonScene = new ChangeSceneButton(this.scene, w * -0.185, h * -0.365, [], 'table_button', h * 0.3, h * 0.3, 'green', this.targetScene));
        this.add(this.sceneName = this.scene.add.text(w * -0.47, h * -0.33, sceneNames[this.targetScene]).setOrigin(0, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.17));

        // Collect ore button
        const oreType = mapMachineData[this.targetScene]['ore'];

        this.add(this.scene.add.sprite(w * -0.145, h * -0.365, 'UI', 'hex.png').setDisplaySize(h * 0.3, h * 0.3));
        this.add(this.scene.add.sprite(w * -0.145, h * -0.365, 'items_icons', `${oreType}.png`).setDisplaySize(h * 0.2, h * 0.2));
        this.add(this.buttonCollectOre = new ActionButton(this.scene, w * -0.145, h * -0.365, [], 'table_button', h * 0.3, h * 0.3, 'green', this.collectOre.bind(this)));

        this.addItems();
    }

    addItems() {
        this.cells = [];
        let dist = 0;

        for (let i = 0; i < 5; i++) {
            let cell = new OreMiningStatsSectionItem(this.scene, this.width * -0.459 + dist, this.height * 0.183, [], this, i);
            this.cells.push(cell);

            dist += this.width * 0.1958;
        }

        this.add(this.cells);
    }

    update(data) {
        this.reset();
        this.cells.forEach(cell => cell.update(data));
    }

    prepare() {
        this.cells.forEach(cell => {
            if (cell.isOpened) {
                cell.prepare();
            }
        });
    }

    reset() {
        this.cells.forEach(cell => cell.reset());
    }

    async collectOre() {
        const data = await apiClient.machines.collectOreByScene({
            scene: this.targetScene,
            ore: mapMachineData[this.targetScene]['ore']
        });

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(data['message']);
        this.scene.game.registry.set('items', data['items']);
    }
}