import UIController, { ScrollManager } from '../../../../../managers/UIController.js';
import SingleInfoTable from '../common/SingleInfoTable.js';
import apiClient from '../../../../../services/apiClient.js';
import OreMiningStatsSection from './components/OreMiningStatsSection.js';
import InfoTableMainScroll from '../../../common/scroll/InfoTableMainScroll.js';
import MachineIcon from '../../../../main/icons/MachineIcon.js';


export default class OreMiningStatsTable extends SingleInfoTable {

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        this.add(this.mainScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let index in data) {
                let icon = new MachineIcon(this.scene, 0, 0, [], 'machine_icons', h * 0.09, h * 0.09, '');
                icon.setIconData(data[index], index);

                // Колбэк при клике на иконку
                icon.setClickCallback(() => {
                    this.mainScroll.deselectAll();
                    this.selectMachine(icon);
                });

                if (data[index]['status'] != 'inactive') {
                    this.installMachine(icon);
                } else {
                    this.mainScroll.addItem(icon);
                }
            }
        }));
    }

    addInfo(w, h) {
        this.arrangeCells(w, h);
    }

    async arrangeCells(w, h) {
        const scrollW = w * 0.65;
        const scrollH = h * 0.69;
        const spaceTop = h * 0.03;

        this.scroll = new ScrollManager(this.scene, {
            x: 0,
            y: h * -0.033,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                panel: {
                    top: spaceTop,
                    bottom: spaceTop,
                }
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.addSections(w, h);
        this.add(this.scroll.scrollPanel);
    }

    async update(category) {
        await super.update(category);

        const data = await apiClient.machineCells.get();
        this.machineCellsData = data['data'];

        if (!this.sectionsAdded) {
            this.addInfo(this.width, this.height);
        }

        this.updateInfo(this.machineCellsData['cells']);
        this.updateMainScroll(this.machineCellsData['machines']);
    }

    addSections(w, h) {
        const data = this.machineCellsData;

        for (let key in data['cells']) {
            let section = new OreMiningStatsSection(this.scene, 0, 0, [], w * 0.647, w * 0.073, key, data['cells'][key]);
            this.scroll.addItem(section);
        }

        this.sectionsAdded = true;
    }

    async updateInfo(data) {
        for (let item of this.scroll.items) {
            item.update(data);
        }
    }

    updateMainScroll(data) {
        this.mainScroll.updateContent(data);
    }

    resetCells() {
        const sections = this.scroll.getAll();
        sections.forEach(section => section.reset());
    }

    selectMachine(icon) {
        icon.select();
        this.scene.game.registry.set('machineToInstall', icon);
        
        const machine = icon.iconData;
        const sections = this.scroll.getAll();

        sections.forEach(section => section.reset());

        let scenes = [];
        switch (machine['name']) {
            case 'miner':
                scenes = ['Iron_Mine', 'Titan_Mine'];
                break;
            case 'crystall_collector':
                scenes = ['Krychon_Cave'];
                break;
            case 'truck':
                scenes = ['Silicon_Quarry'];
                break;
        }

        sections.forEach(section => {
            if (scenes.includes(section.targetScene)) {
                section.prepare();
            }
        })
    }

    installMachine(icon) {
        const scene = icon.iconData['scene'];
        const sections = this.scroll.getAll();

        sections.forEach(section => {
            if (section.targetScene == scene) {
                let freeCell = section.cells.find(cell => cell.isEmpty && cell.index == icon.iconData['cell_number'] - 1);
                
                if (freeCell) {
                    freeCell.addMachine(icon);
                }
            }
        })
    }
}
