export default class GameScene extends Phaser.Scene {
    constructor(config) {
        super(config);
        this.config = config;
    }

    init() { }

    destroyPreload() {
        this.children.removeAll();
    }

    preload() {
        this.loadingText ||= 'Загрузка: ';

        let w = window.innerWidth;
        let h = window.innerHeight;

        let preloadBG = 'load_bg_1';
        switch (this.scene.key) {
            case 'MainBase':
                preloadBG = 'load_bg_3';
                break;
            case 'UI':
            case 'InfoTables':
            case 'Journal':
                preloadBG = 'load_bg_1';
                break;
            default:
                preloadBG = 'load_bg_world';
                break;
        }

        let bg = this.add.image(w / 2, h / 2, preloadBG).setOrigin(0.5, 0.5);

        let loadText = this.add.text(w / 2 - w * 0.45, h * 0.42, '').setOrigin(0, 0.5).setStyle({
            fontFamily: 'Game_Font',
            fontSize: window.screen.height * 0.02 + 'px',
            color: '#FFE6AF',
            shadow: { fill: true, color: '#000000', offsetX: w * 0.001, offsetY: w * 0.001, blur: w * 0 }
        });

        this.load.on('progress', (value) => {
            loadText.setText(this.loadingText + (value * 100).toFixed().toString() + '%');
        });

        this.load.on('loaderror', (file) => {
            switch (file['type']) {
                case 'image':
                    this.load.image(file['key'], file['url']);
                    break;
                case 'audio':
                    this.load.audio(file['key'], file['url']);
                    break;
                case 'tilemapTiledJSON':
                    this.load.tilemapTiledJSON(file['key'], file['url']);
                    break;
                case 'spritesheet':
                    this.load.spritesheet(file['key'], file['url'], { frameWidth: file['config']['frameWidth'], frameHeight: file['config']['frameHeight'] });
                    break;
            }
        });


        let resize = (w, h) => {
            if (w > h / 0.562) {
                bg.setDisplaySize(w, w * 0.562);
            } else {
                bg.setDisplaySize(h / 0.562, h);
            }

            bg.setPosition(w / 2, h / 2);
            loadText.setPosition(w / 2 - w * 0.45, h * 0.42);
        }

        resize(w, h);
        window.onresize = function () {
            resize(window.innerWidth, window.innerHeight);
        }
    }

    create() {
        this.destroyPreload();

        window.onbeforeunload = () => {
            navigator.sendBeacon('/users/update-user');
        };
    }

    update() { }
}