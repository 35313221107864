import SceneManager from "../../../../managers/SceneManager";
import InfoTables from "../../../../scenes/ui/InfoTables";
import Button from "../common/Button";

export default class StartBattleButton extends Button {
    constructor(scene, x, y, children, texture, width, height, color, table) {
        super(scene, x, y, children, texture, width, height, color);

        this.table = table;
    }

    click() {
        super.click();
        const table = this.table;
        const army = table.getSelectedArmy();
        const battleData = table.getBattleData();

        const commanderSelected = army['commander'].unit;
        const characterSelected = army['character'].unit;

        if (!commanderSelected) {
            InfoTables.showWarningText('Выберите командира');
            return;
        }

        if (!characterSelected) {
            InfoTables.showWarningText('Выберите дополнительного персонажа');
            return;
        }

        const items = this.scene.game.registry.get('items');

        if (items['battery_units'] < battleData['resources']) {
            InfoTables.showWarningText('Не достаточно ресурсов для боя');
            return;
        }

        this.scene.game.registry.get('opened_table').close();
        this.scene.game.registry.set('battle_data', table.getBattleData());
        SceneManager.loadScene(this.scene, 'BattleUI');
    }
}