import TimeManager from "../../../../../../managers/TimeManager";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../../utils/textStyles";
import ActionButton from "../../../../../main/buttons/common/ActionButton";
import UnitIcon from "../../../../../main/icons/UnitIcon";

export default class HealingCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, index) {
        super(scene, x, y, children, tw, th);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;
        this.index = index;

        this.create(tw, th);
    }

    setAvailable(available = true) {
        this.available = available;
        this.isFree = available;

        if (!available) {
            this.addLock(this.tw, this.th);
        }
    }

    create(w, h) {
        this.add(this.timer = this.scene.add.text(w * 0.077, h * 0.035, '-')
            .setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.022));

        this.add(this.morionText = this.scene.add.text(w * 0.09, h * -0.019, '-')
            .setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.02));

        this.add(this.finishButton = new ActionButton(this.scene, w * 0.174, h * -0.019, [], 'button_up', h * 0.051, h * 0.058, 'green', this.openConfirmationTable.bind(this)));
        this.finishButton.disable();

        this.add(this.moduleLock = this.scene.add.sprite(w * 0.054, h * 0.022, 'UI', 'lock.png').setDisplaySize(h * 0.03, h * 0.03));
    }

    async addUnit(icon) {
        this.add(this.icon = new UnitIcon(this.scene, 0, 0, [], 'button', this.th * 0.18, this.th * 0.18));
        this.icon.setUnitData(icon.unitData, icon.index);

        this.icon.disable();
        this.icon.deselect();

        this.isFree = false;
    }

    addLock(w, h) {
        this.add(this.lock = this.scene.add.sprite(0, 0, 'UI', 'lock.png').setOrigin(0.7, 0.5).setDisplaySize(h * 0.06, h * 0.06));
    }

    clear(buildingLevel) {
        this.buildingLevel = this.buildingLevel ?? buildingLevel;

        if (this.icon) {
            TimeManager.removeTimer(this.icon.unitData['id']);
            this.icon.destroy();
        }

        if (this.lock) {
            this.lock.destroy();
        }

        this.setAvailable(this.buildingLevel >= this.index);

        this.timer.setText('-');
        this.timer.setStyle(TEXT_STYLE_BLUE);

        this.morionText.setText('-');
        this.morionText.setStyle(TEXT_STYLE_BLUE);

        this.finishButton.disable();
    }

    async startHealing(data) {
        this.finishButton.makeInteractive();
        this.timer.setStyle(TEXT_STYLE_GREEN);
        const unitId = this.icon.unitData['id'];

        TimeManager.createTimer(this.scene, unitId, data['duration'],
            this.finishHealing.bind(this),
            (remainingTime) => {
                this.timer.setText(TimeManager.formatFullTime(remainingTime));
                const morionAmount = Math.ceil(remainingTime / 60);
                this.morionText.setText(morionAmount);
            }
        );
        
        const building = this.scene.game.registry.get('curBuilding');
        building.addProcessToStack(data);

        this.finishButton.makeInteractive();
    }

    async finishHealing() {
        const data = await apiClient.processes.finish({ category: 'healing', name: 'unit_' + this.icon.unitData['id'] });
        this.onFinishHealing();
    }

    onFinishHealing() {
        this.clear();
        InfoTables.showWarningText('Лечение завершено, ваш юнит полностью здоров');
    }

    openConfirmationTable() {
        const morionAmount = Math.ceil(TimeManager.getRemaining(this.icon.unitData['id']) / 60000);

        InfoTables.openConfirmationTable({
            title: 'Лечение',
            text: `Завершить лечение за ${morionAmount} мориона?`,
            button: 'Завершить'
        }, this.finishByMorion.bind(this));
    }

    async finishByMorion() {
        const building = this.scene.game.registry.get('curBuilding');
        
        const data = await apiClient.processes.finishByMorion({
            category: 'healing',
            name: 'unit_' + this.icon.unitData['id'],
        });

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(data['message']);

        if (data['message'] == 'Не достаточно мориона') {
            return;
        }

        const processIcon = building.getProcessFromStack('unit_' + this.icon.unitData['id']);
        building.removeProcessFromStack(processIcon);
        this.onFinishHealing();
    }

}