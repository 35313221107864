import InfoTables from "../../../../../../scenes/ui/InfoTables";
import apiClient from "../../../../../../services/apiClient";
import ActionButton from "../../../../../main/buttons/common/ActionButton";
import ConstructionItemCell from "./ConstructionItemCell";

export default class MachineConstructionInfo extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.create(tw, th);
    }

    create(w, h) {
        this.cells = [
            new ConstructionItemCell(this.scene, w * 0.077, h * -0.20, [], w, h),
            new ConstructionItemCell(this.scene, w * 0.077, h * -0.102, [], w, h),
            new ConstructionItemCell(this.scene, w * 0.077, h * -0.003, [], w, h),
            new ConstructionItemCell(this.scene, w * 0.077, h * 0.095, [], w, h),
            new ConstructionItemCell(this.scene, w * 0.207, h * -0.102, [], w, h),
            new ConstructionItemCell(this.scene, w * 0.207, h * -0.003, [], w, h),
            new ConstructionItemCell(this.scene, w * 0.207, h * 0.095, [], w, h),
        ]
        this.add(this.cells);

        this.add(this.assembleButton = new ActionButton(this.scene, w * 0.289, h * -0.2, [], 'table_button', h * 0.063, h * 0.063, 'green', this.construct.bind(this)));
    }

    updateInfo(machine) {
        this.assembleButton.makeInteractive();
        this.machine = machine;
        const bodyType = machine.iconData['name'];
        this.extraItem;

        // Выбираем нужный дополнительный ресурс
        switch (bodyType) {
            case 'builder_body':
                this.extraItem = { name: 'machine_jack', amount: 1 };
                break;
            case 'miner_body':
                this.extraItem = { name: 'cutter', amount: 2 };
                break;
            case 'crystall_collector_body':
                this.extraItem = { name: 'machine_claw', amount: 2 };
                break;
            case 'truck_body':
                this.extraItem = { name: 'machine_bucket', amount: 1 };
                break;
        }

        this.reqItems = [
            { name: bodyType, amount: 1 },
            { name: 'machine_wheel', amount: 4 },
            { name: 'machine_engine', amount: 1 },
            { name: 'machine_arm', amount: 2 },
            { name: 'shaft', amount: 2 },
            { name: 'machine_brain', amount: 1 },
            { name: this.extraItem['name'], amount: this.extraItem['amount'] },
        ];

        this.cells.forEach((cell, index) => cell.updateText(this.reqItems[index]));
    }

    async construct() {
        const data = await apiClient.machines.create({ 
            body: this.machine.iconData['name'], 
            name: this.machine.iconData['name'].slice(0, -5), 
            extra_item: this.extraItem 
        });
        
        if (data['status'] !== 'success') {
            return;
        }
        
        InfoTables.showWarningText(data['message']);

        if (data['message'] == 'Не достаточно ресурсов') {
            return;
        }
        
        this.scene.game.registry.set('items', data['items']);
        this.parentContainer.updateContent();
    }

    disable() {
        this.assembleButton.disable();
    }
}