import GameScene from './gameScene.js';
import apiClient from '../../services/apiClient.js';
import Building from '../../modules/scene/objects/Building.js';
import { SceneObject } from '../../modules/scene/objects/SceneObject.js';
import GameMap from '../../modules/scene/common/GameMap.js';
import { MapPichmog } from '../../modules/scene/objects/units/MapPichmog.js';
import { MapUnit } from '../../modules/scene/objects/units/MapUnit.js';
import MapItem from '../../modules/scene/objects/MapItem.js';
import AnimationManager from '../../managers/AnimationManager.js';
import mapMachineData from '../../config/machines/mapMachineData.js';
import MapMachine from '../../modules/scene/objects/MapMachine.js';
import InfoTables from '../ui/InfoTables.js';
import Barrier from '../../modules/scene/objects/Barrier.js';
import animalData from '../../config/units/animalData.js';

export default class MapScene extends GameScene {

    async preload() {
        this.loadingText = 'Загружаем локации и объекты: ';

        super.preload();
        this.scene.bringToTop();

        this.sceneEvent = this.sceneEvent || new Phaser.Events.EventEmitter();

        const data = await apiClient.locations.getData({ scene: this.scene.key });

        console.log(data);
        this.sceneInfo = data;
        this.loadAssets(data);
    }

    loadAssets(data) {
        const sceneName = this.scene.key;

        // Карта
        this.load.image(`${sceneName}_tiles`, `./scenes/${sceneName}/Map.jpg`);
        this.load.tilemapTiledJSON(`${sceneName}_map`, `./scenes/${sceneName}/Map.json`);

        // Здания
        let buildings = data['buildings'];
        let buildingsConfig = data['data']['buildings'][sceneName];

        for (let key in buildings) {
            let name = buildings[key]['name'];

            if (buildingsConfig && name in buildingsConfig) {
                let level = buildings[key]['level'];
                this.load.image(name, `./scenes/${sceneName}/buildings/${name}/${level}.png`);
            }
        }

        // Границы
        let barriers = data['barriers'];
        let barrierConfig = data['data']['barriers'][sceneName];

        for (let key in barriers) {
            if (barrierConfig && key in barrierConfig) {
                this.load.image(key, `./scenes/${sceneName}/buildings/${key}/0.png`);
            }
        }

        // Объекты
        let objectsConfig = data['data']['objects'][sceneName];

        for (let key in objectsConfig) {
            this.load.image(key, `./scenes/${sceneName}/objects/${key}.png`);
        }

        this.loadAnims(data);

        this.load.start();
        this.load.once('complete', () => {
            this.createBaseAnimations();
            this.sceneEvent.emit('loaded');
            this.sceneLoaded = true;
        });
    }

    async create() {
        let createScene = () => {
            super.create();
            // Register new login if the scene (MainBase) is loaded first time
            const loginRegistered = this.game.registry.get('login_registered');
            if (!loginRegistered || loginRegistered == false) {
                this.game.registry.set('login_registered', true);
                apiClient.users.registerNewLogin();
            }

            this.createScene();
        }

        /** 
         * Update scene data on wake or create 
         * @param makeRequest Defines either request should be sent or not
         * */
        let update = async (makeRequest = true) => {
            const sceneName = this.scene.key;

            if (makeRequest) {
                const data = await apiClient.locations.getData({ scene: sceneName });
                this.sceneInfo = data;
                console.log(data);
            }

            const data = this.sceneInfo;

            this.addBattles(data['battles'], data['data']['battles'][sceneName]);
            this.addBarrierBattles(data['barrier_battles'], data['data']['barrier_battles'][sceneName]);
            this.addItems(data['collectable_items'], data['data']['items'][sceneName]);
            this.updateMachinesOnMap(data['machines']);
        }

        if (this.sceneLoaded) {
            await update();
            super.create();
            this.createScene();
        } else {
            this.sceneEvent.once('loaded', () => createScene());
        }

        this.events.off('wake').on('wake', () => {
            update();
        });
    }

    updateMachinesOnMap(data) {
        const sceneName = this.scene.key;

        if (!mapMachineData[sceneName]) {
            return;
        }

        if (this.machines) {
            this.machines.forEach(machine => machine.destroy());
        }
        this.machines = [];

        for (let id in data) {
            const m = data[id];
            const machineData = mapMachineData[sceneName];
            const pos = machineData['positions'];
            const texture = machineData['machineTexture'];
            const anims = machineData['anims'];

            const machine = new MapMachine(this, pos[id].x, pos[id].y, [], texture, 0.05, 0.05, {
                cellNumber: m['cell_number'],
                id: m['id'],
                status: m['status'],
                scene: m['scene'],
                name: m['name'],
                anim: anims[id],
                rate: m['rate'],
                ore: machineData['ore']
            });
            this.machines.push(machine);
        }

        this.map.add(this.machines);
    }

    /**
     * Adds essential scene objects
     */
    createScene() {
        this.scene.bringToTop('UI');
        this.scene.bringToTop('InfoTables');

        const sceneName = this.scene.key;
        const data = this.sceneInfo;
        this.barrierData = data['barriers'];

        this.addMap(data['data']['scene'][sceneName]);
        this.addBuildings(data['buildings'], data['data']['buildings'][sceneName]);
        this.addObjects(data['data']['objects'][sceneName]);
        this.addBarriers(data['barriers'], data['data']['barriers'][sceneName], data['lookouts']);
        this.addBattles(data['battles'], data['data']['battles'][sceneName]);
        this.addBarrierBattles(data['barrier_battles'], data['data']['barrier_battles'][sceneName]);
        this.addItems(data['collectable_items'], data['data']['items'][sceneName]);

        this.updateMachinesOnMap(data['machines']);
        this.displayProcesses(data['processes']);
    }

    /**
     * Adds a map to the scene
     * @param {*} data The map data. Contains map's position and size
     */
    addMap(data) {
        let w = window.screen.width * data['w'];
        let h = window.screen.width * data['h'];
        let x = w * data['x'];
        let y = h * data['y'];

        if (data['x'] == 100) x = window.innerWidth - w;
        if (data['y'] == 100) y = window.innerHeight - h;

        // Создание карты
        this.map = new GameMap(this, x, y, [], this.scene.key, w, h);
    }

    /**
     * Adds buildings to the map.
     * @param buildingData The data from database. 
     * @param config The config data that describes the main properties of the building.
     */
    addBuildings(buildingData, config) {
        // if (this.buildings) {
        //     this.buildings.forEach(building => building.destroy());
        // }
        this.buildings = [];

        for (let key in buildingData) {
            let name = buildingData[key]['name'];
            let level = buildingData[key]['level'];

            if (config && name in config && (level > 0 || config[name]['built'] == false)) {
                let building = new Building(
                    this,
                    config[name]["x"],
                    config[name]["y"],
                    [],
                    name,
                    config[name]["w"],
                    config[name]["h"],
                    config[name],
                );

                // Устанавливаем состояние здания
                if (!config[name]['available']) {
                    building.setState('update');

                } else if (level == 0) {
                    building.setState('broken');

                } else {
                    building.setState('opened');
                }

                building.setLevel(level);
                building.updateInfo(level);
                this.buildings.push(building);

                // Добавляем здание на сцену
                this.map.add(building);
            }
        }
    }

    /**
     * Adds barriers and walls to the map.
     * @param buildingData The data from database. 
     * @param config The config data that describes the main properties of the structure.
     */
    addBarriers(buildingData, config, lookoutData) {
        this.barriers = [];

        for (let key in buildingData) {
            const isOpened = buildingData[key]['opened'];

            if (config && key in config) {
                const isBuilt = config[key]['built'];

                if (isBuilt && !isOpened) {
                    continue;
                }
                let building = new Barrier(
                    this,
                    config[key]["x"],
                    config[key]["y"],
                    [],
                    key,
                    config[key]["w"],
                    config[key]["h"],
                    config[key],
                );

                // Устанавливаем состояние здания
                if (!config[key]['available']) {
                    building.setState('update');

                } else {
                    building.setState(isOpened ? 'opened' : 'closed');
                }

                building.updateLookoutIcons(this.getLookoutCount(lookoutData, key));

                this.barriers.push(building);

                // Добавляем здание на сцену
                this.map.add(building);
            }
        }
    }

    /**
     * Return the amount of lookouts for the specified area.
     * @param {object} data Units data.
     * @param {string} area The name of the area.
     */
    getLookoutCount(data, area) {
        let lookouts = 0;

        for (let unitId in data) {
            if (data[unitId]['area'] == area) {
                lookouts++;
            }
        }

        return lookouts;
    }

    /**
     * Adds scene changers to the map.
     * @param info The config data that describes the main properties of the object.
     */
    addObjects(info) {
        for (let key in info) {
            this.map.add(new SceneObject(
                this,
                info[key]["x"],
                info[key]["y"],
                [],
                key,
                info[key]["w"],
                info[key]["h"],
                {
                    link_x: info[key]["link_x"],
                    link_y: info[key]["link_y"],
                    name: info[key]["name"],
                    scene: info[key]["scene"]
                }
            ));
        }
    }

    /**
     * Adds enemies to the map. If enemy is defeated, it doesn't appear.
     * @param battleData The data from database. 
     * @param info The config data that describes the main properties of the enemy.
     * @returns 
     */
    addBattles(battleData, info) {
        const sceneName = this.scene.key;
        if (this.fights) {
            this.fights.forEach(fight => fight.destroy());
        }
        this.fights = [];

        for (let id in battleData) {
            let enemy = battleData[id];

            if (enemy['scene'] != sceneName || enemy['defeated']) {
                continue;
            }

            const order = enemy['order'];
            const data = info[order];
            const size = animalData[data['unit']]['size'];
            let unit;

            switch (data['unit']) {
                case 'pichmog':
                    this.fights.push(unit = new MapPichmog(this, data['x'], data['y'], [], 'pichmog', 0.017, 0.017, data, order));
                    break;
                default:
                    this.fights.push(unit = new MapUnit(this, data['x'], data['y'], [], 'zazulic', size, size, data, order));
                    break;
            }
        }

        this.map.add(this.fights);
        this.map.emit('addbattles', this.map);

        // Making enemies available to attack
        if (!this.fights.length) {
            return;
        }

        if (info[this.fights[0].Id]['prev'] == null) {
            this.fights[0].setAvailable(true);
            return;
        }

        this.fights.forEach(enemy => {
            const prevId = info[enemy.Id]['prev'];
            let previousEnemy = this.fights.find(unit => unit.Id == prevId);

            if ((typeof prevId == 'string' && this.barrierData[prevId]['opened']) ||
                (!previousEnemy && prevId != null && typeof prevId != 'string')
            ) {
                enemy.setAvailable(true, 'barrier');
            }
        });
    }

    addBarrierBattles(battleData, info) {
        if (!info) {
            return;
        }

        const sceneName = this.scene.key;
        if (this.barrierBattles) {
            this.barrierBattles.forEach(enemy => enemy.destroy());
        }
        this.barrierBattles = [];

        for (let id in info) {
            // let enemy = battleData[id];

            // if (enemy['scene'] != sceneName || enemy['defeated']) {
            //     continue;
            // }

            // const order = enemy['order'];
            const data = info[id];
            let unit;

            switch (data['unit']) {
                case 'pichmog':
                    this.barrierBattles.push(unit = new MapPichmog(this, data['x'], data['y'], [], 'pichmog', 0.017, 0.017, data, id));
                    break;
                default:
                    this.barrierBattles.push(unit = new MapUnit(this, data['x'], data['y'], [], 'zazulic', 0.017, 0.017, data, id));
                    break;
            }
        }

        this.map.add(this.barrierBattles);
        this.map.emit('addbarrierbattles', this.map);

        // Making enemies available to attack
        if (!this.barrierBattles.length) {
            return;
        }

        // if (this.fights[0].Id == 1) {
        //     this.fights[0].setAvailable(true);
        // } else {
        //     this.fights.forEach(enemy => {
        //         if (info[enemy.Id]['prev'] == info[this.fights[0].Id]['prev']) {
        //             enemy.setAvailable(true);
        //         }
        //     });
        // }
    }

    /**
     * Adds collectable items to the map.
     * @param itemsData The data from database. 
     * @param info The config data that describes the parameters of the item.
     */
    addItems(itemsData, info) {
        if (this.collectableItems) {
            this.collectableItems.forEach(item => item.destroy());
        }
        this.collectableItems = [];
        const sceneName = this.scene.key;

        for (let key in info) {
            if (!itemsData[key]['scene'] == sceneName) {
                continue;
            }
            if (itemsData[key]['collected']) {
                continue;
            }

            this.collectableItems.push(new MapItem(
                this,
                info[key]["x"],
                info[key]["y"],
                [],
                key,
                info[key]["size"],
                info[key]["size"],
                {
                    name: info[key]['name']
                }
            ));
        }

        this.map.add(this.collectableItems);
    }

    sortUnits() {
        const data = this.sceneInfo;
        const battles = data['data']['battles'][this.scene.key];
        const units = {};
        for (let id in battles) {
            if (!units[battles[id]['unit']]) {
                units[battles[id]['unit']] = battles[id]['category'];
            }
        }

        return units;
    }

    /**
     * Loads the basic animations of units (enemies on map)
     */
    loadAnims() {
        const units = this.sortUnits();

        for (let name in units) {
            let size = 128;
            if (['pichmog', 'vugda', 'vugda_mini', 'vugda_female', 'beles', 'beles_mini', 'beles_female', 'mutant', 'irradiated', 'Chlapidol'].includes(name)) {
                size = 256;
            }
            AnimationManager.load(this, 'units', units[name], size, { name: name });
        }
    }

    createBaseAnimations() {
        // miner
        AnimationManager.createSingleAnimation(this, 'miner_anims', 'miner_up-r', { start: 0, end: 9 }, 6, -1);
        AnimationManager.createSingleAnimation(this, 'miner_anims', 'miner_up-l', { start: 10, end: 19 }, 6, -1);
        AnimationManager.createSingleAnimation(this, 'miner_anims', 'miner_up-half_l', { start: 20, end: 29 }, 6, -1);
        AnimationManager.createSingleAnimation(this, 'miner_anims', 'miner_up-half_r', { start: 30, end: 39 }, 6, -1);
        AnimationManager.createSingleAnimation(this, 'miner_anims', 'miner_left', { start: 40, end: 49 }, 6, -1);
        // truck
        AnimationManager.createSingleAnimation(this, 'truck_anims', 'truck_up-r', { start: 0, end: 9 }, 4, -1);
        AnimationManager.createSingleAnimation(this, 'truck_anims', 'truck_up-l', { start: 10, end: 19 }, 4, -1);
        AnimationManager.createSingleAnimation(this, 'truck_anims', 'truck_up-half_l', { start: 20, end: 29 }, 4, -1);
        AnimationManager.createSingleAnimation(this, 'truck_anims', 'truck_up-half_r', { start: 30, end: 39 }, 4, -1);
        // crystall collector
        AnimationManager.createSingleAnimation(this, 'crystall_collector_anims', 'crystall_collector_up-r', { start: 0, end: 9 }, 4, -1);
        AnimationManager.createSingleAnimation(this, 'crystall_collector_anims', 'crystall_collector_up-l', { start: 10, end: 19 }, 4, -1);
        AnimationManager.createSingleAnimation(this, 'crystall_collector_anims', 'crystall_collector_down-r', { start: 20, end: 29 }, 4, -1);
        AnimationManager.createSingleAnimation(this, 'crystall_collector_anims', 'crystall_collector_up-half_r', { start: 30, end: 39 }, 4, -1);
        // builder
        AnimationManager.create(this, 'builder', 'build', 1, 28, 6, -1);
        AnimationManager.createSingleAnimation(this, 'builder', 'builder_extract', { start: 30, end: 57 }, 6, -1);

        const units = this.sortUnits();

        for (let name in units) {
            switch (name) {
                case 'zazulic':
                case 'zazulic_soldier':
                case 'zazulic_mini':
                case 'zazulic_sand':
                case 'zazulic_sand_soldier':
                case 'zazulic_sand_mini':
                    AnimationManager.create(this, name, 'huff', 4, 18, 6, -1);
                    AnimationManager.create(this, name, 'dig', 8, 10, 6, -1);
                    break;
                case 'pichmog':
                    AnimationManager.create(this, name, 'wake', 4, 5, 6, false);
                    break;
                case 'vugda':
                case 'vugda_mini':
                case 'vugda_female':
                    AnimationManager.create(this, name, 'idle', 4, 20, 6, -1);
                    break;
                case 'beles':
                case 'beles_mini':
                case 'beles_female':
                    AnimationManager.create(this, name, 'idle', 4, 20, 5, -1);
                    break;
                case 'mutant':
                    AnimationManager.create(this, name, 'idle', 8, 10, 5, -1);
                    break;
                case 'irradiated':
                    AnimationManager.create(this, name, 'idle', 8, 10, 5, -1);
                    break;
                case 'Chlapidol':
                    AnimationManager.create(this, name, 'idle', 8, 10, 2, -1);
                    break;
            }
        }
    }

    displayProcesses(processes) {
        for (let key in processes) {
            const process = processes[key];
            const extraCategories = ['exploration', 'container_hacking'];

            if (process['finished'] || extraCategories.includes(process['category'])) {
                continue;
            }

            const building = this.buildings.find(b => b.texture == process['building_name']);

            if (building) {
                if (process['category'] == 'building') {
                    const table = InfoTables.tables.get(process['building_name']).tableRight;
                    building.startBuilding(process, table && table.info);
                } else {
                    building.addProcessToStack(process);
                }
            }
        }
    }
}