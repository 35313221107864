import GreetingTable from '../tables/player/GreetingTable.js';
import InfoTableContainer from './common/InfoTableContainer.js';

export default class GreetingTableContainer extends InfoTableContainer {

    addInfoTables() {
        this.tableLeft = null;
        this.tableCenter = new GreetingTable(this.scene, 0, 0, [], this.w, this.h, 'greeting');
        this.tableRight = null;

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }

    addButtons(w, h) {
        super.addButtons(w, h);
        this.exitButton.setVisible(false);
        this.leftButton.setVisible(false);
        this.rightButton.setVisible(false);
    }
}
