import Phaser from 'phaser';

import BootScene from './src/scenes/boot/boot.js';
import UI from './src/scenes/ui/UI.js';
import Journal from './src/scenes/ui/journal.js';
import InfoTables from './src/scenes/ui/InfoTables.js';
import MainBase from './src/scenes/common/mainBase.js';
import IronBase from './src/scenes/common/ironBase.js';
import ClanHall from './src/scenes/common/clanHall.js';
import SiliconQuarry from './src/scenes/common/siliconQuarry.js';
import RockyCoast from './src/scenes/common/rockyCoast.js';
import TitanBase from './src/scenes/common/titanBase.js';
import NorthBarrier from './src/scenes/common/northBarrier.js';
import EastBarrier from './src/scenes/common/eastBarrier.js';
import Spaceship from './src/scenes/common/spaceship.js';
import KrychonCave from './src/scenes/underground/caves/KrychonCave.js';
import World from './src/scenes/overworld/world.js';
import Underworld from './src/scenes/overworld/underworld.js';
import IronMine from './src/scenes/underground/mines/ironMine.js';
import TitanMine from './src/scenes/underground/mines/titanMine.js';
import RogonoraHole from './src/scenes/underground/caves/rogonoraHole.js';
import ZazulicNest from './src/scenes/underground/caves/zazulicNest.js';
// Плагины
import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import BoardPlugin from 'phaser3-rex-plugins/plugins/board-plugin.js';
import MoveToPlugin from 'phaser3-rex-plugins/plugins/moveto-plugin.js';
import AwaitLoaderPlugin from 'phaser3-rex-plugins/plugins/awaitloader-plugin.js';
import GlowFilterPipelinePlugin from 'phaser3-rex-plugins/plugins/glowfilterpipeline-plugin.js';

import BattleUI from './src/scenes/ui/BattleUI.js';
// Боевые сцены
import MainBaseBattle1 from './src/scenes/battle/MainBaseBattle1.js';
import MainBaseBattle2 from './src/scenes/battle/MainBaseBattle2.js';
import MainBaseBattle3 from './src/scenes/battle/MainBaseBattle3.js';
import MainBaseBattle4 from './src/scenes/battle/MainBaseBattle4.js';
import MainBaseBattle5 from './src/scenes/battle/MainBaseBattle5.js';
// Iron Base
import IronBaseBattle1 from './src/scenes/battle/IronBaseBattle1.js';
import IronBaseBattle2 from './src/scenes/battle/IronBaseBattle2.js';
import IronBaseBattle3 from './src/scenes/battle/IronBaseBattle3.js';
// Silicon Quarry
import SiliconQuarryBattle1 from './src/scenes/battle/SiliconQuarryBattle1.js';
import SiliconQuarryBattle2 from './src/scenes/battle/SiliconQuarryBattle2.js';
import SiliconQuarryBattle3 from './src/scenes/battle/SiliconQuarryBattle3.js';
import SiliconQuarryBattle4 from './src/scenes/battle/SiliconQuarryBattle4.js';
import SiliconQuarryBattle5 from './src/scenes/battle/SiliconQuarryBattle5.js';
// Titan Base
import TitanBaseBattle1 from './src/scenes/battle/TitanBaseBattle1.js';
import TitanBaseBattle2 from './src/scenes/battle/TitanBaseBattle2.js';
import TitanBaseBattle3 from './src/scenes/battle/TitanBaseBattle3.js';
import TitanBaseBattle4 from './src/scenes/battle/TitanBaseBattle4.js';
import TitanBaseBattle5 from './src/scenes/battle/TitanBaseBattle5.js';
// East Barrier
import EastBarrierBattle1 from './src/scenes/battle/EastBarrierBattle1.js';
import EastBarrierBattle2 from './src/scenes/battle/EastBarrierBattle2.js';
// North Barrier
import NorthBarrierBattle1 from './src/scenes/battle/NorthBarrierBattle1.js';
import NorthBarrierBattle2 from './src/scenes/battle/NorthBarrierBattle2.js';
import NorthBarrierBattle3 from './src/scenes/battle/NorthBarrierBattle3.js';
// Rocky Coast
import RockyCoastBattle1 from './src/scenes/battle/RockyCoastBattle1.js';
import RockyCoastBattle2 from './src/scenes/battle/RockyCoastBattle2.js';
// Iron Mine
import IronMineBattle1 from './src/scenes/battle/IronMineBattle1.js';
import IronMineBattle2 from './src/scenes/battle/IronMineBattle2.js';
import IronMineBattle3 from './src/scenes/battle/IronMineBattle3.js';
import IronMineBattle4 from './src/scenes/battle/IronMineBattle4.js';
// Titan Mine
import TitanMineBattle1 from './src/scenes/battle/TitanMineBattle1.js';
import TitanMineBattle2 from './src/scenes/battle/TitanMineBattle2.js';
import TitanMineBattle3 from './src/scenes/battle/TitanMineBattle3.js';
// Krychon Cave
import KrychonCaveBattle1 from './src/scenes/battle/KrychonCaveBattle1.js';
import KrychonCaveBattle2 from './src/scenes/battle/KrychonCaveBattle2.js';
// Zazulic Nest
import ZazulicNestBattle1 from './src/scenes/battle/ZazulicNestBattle1.js';
import ZazulicNestBattle2 from './src/scenes/battle/ZazulicNestBattle2.js';
import ZazulicNestBattle3 from './src/scenes/battle/ZazulicNestBattle3.js';
import ZazulicNestBattle4 from './src/scenes/battle/ZazulicNestBattle4.js';


const config = {
    renderer: Phaser.AUTO,
    width: 1080,
    height: 640,

    scale: {
        // ignore aspect ratio:
        mode: Phaser.Scale.RESIZE,
        autoCenter: Phaser.Scale.NO_CENTER,
    },

    physics: {
        default: false
    },

    audio: {
        noAudio: false
    },

    autoRound: false,

    scene: [
        BootScene,
        UI,
        BattleUI,
        Journal,
        InfoTables,
        MainBase,
        IronBase,

        ClanHall,

        SiliconQuarry,
        RockyCoast,
        TitanBase,
        NorthBarrier,
        EastBarrier,
        Spaceship,
        KrychonCave,
        World,
        Underworld,
        // Шахты
        IronMine,
        TitanMine,
        // Подземелье
        RogonoraHole,
        ZazulicNest,

        // Боевые сцены
        MainBaseBattle1,
        MainBaseBattle2,
        MainBaseBattle3,
        MainBaseBattle4,
        MainBaseBattle5,
        // Iron Base
        IronBaseBattle1,
        IronBaseBattle2,
        IronBaseBattle3,
        // Silicon Quarry
        SiliconQuarryBattle1,
        SiliconQuarryBattle2,
        SiliconQuarryBattle3,
        SiliconQuarryBattle4,
        SiliconQuarryBattle5,
        // Titan Base
        TitanBaseBattle1,
        TitanBaseBattle2,
        TitanBaseBattle3,
        TitanBaseBattle4,
        TitanBaseBattle5,
        // East Barrier
        EastBarrierBattle1,
        EastBarrierBattle2,
        // North Barrier
        NorthBarrierBattle1,
        NorthBarrierBattle2, 
        NorthBarrierBattle3,
        // Rocky Coast
        RockyCoastBattle1,
        RockyCoastBattle2,
        // Iron Mine
        IronMineBattle1,
        IronMineBattle2,
        IronMineBattle3,
        IronMineBattle4,
        // Titan Mine
        TitanMineBattle1,
        TitanMineBattle2,
        TitanMineBattle3,
        // Zazulic Nest
        ZazulicNestBattle1,
        ZazulicNestBattle2,
        ZazulicNestBattle3,
        ZazulicNestBattle4,
        // Krychon Cave
        KrychonCaveBattle1,
        KrychonCaveBattle2,
    ],
    isGame: true,

    fps: {
        min: 10,
        target: 60,
        forceSetTimeOut: false,
        deltaHistory: 10
    },

    loader: {
        baseURL: '../../assets',
        path: '',
        enableParallel: true,
        maxParallelDownloads: 4,
        crossOrigin: undefined,
    },

    plugins: {
        global: [
            {
                key: 'rexAwaitLoader',
                plugin: AwaitLoaderPlugin,
                start: true
            },
            {
                key: 'rexMoveTo',
                plugin: MoveToPlugin,
                start: true
            },
            {
                key: 'rexGlowFilterPipeline',
                plugin: GlowFilterPipelinePlugin,
                start: true
            },
        ],
        scene: [
            {
                key: 'rexUI',
                plugin: UIPlugin,
                mapping: 'rexUI'
            },
            {
                key: 'rexBoard',
                plugin: BoardPlugin,
                mapping: 'rexBoard'
            },
        ]
    },

    disableContextMenu: true,
};

const game = new Phaser.Game(config);
export default game;