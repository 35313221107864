import UIController, { ScrollManager } from '../../../../../managers/UIController.js';
import SingleInfoTable from '../common/SingleInfoTable.js';
import apiClient from '../../../../../services/apiClient.js';
import BattleSection from './components/BattleSection.js';


export default class BattlesTable extends SingleInfoTable {

    create() {
        super.create();
    }

    addInfo(w, h) {
        this.arrangeCells(w, h);
    }

    async arrangeCells(w, h) {
        const scrollW = w * 0.65;
        const scrollH = h * 0.69;
        const spaceTop = h * 0.03;

        this.scroll = new ScrollManager(this.scene, {
            x: 0,
            y: h * -0.033,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                panel: {
                    top: spaceTop,
                    bottom: spaceTop * 2,
                }
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
    }

    async update(category) {
        await super.update(category);

        const data = await apiClient.battle.getAll();

        if (data['status'] != 'success') {
            return;
        }

        this.enemies = data['enemies'];
        this.battleData = data['battles'];
        console.log(data);

        if (!this.scroll) {
            this.addInfo(this.width, this.height);
        }

        if (this.sectionsAdded) {
            this.scroll.removeItems();
        }

        this.addSections(this.width, this.height);
    }

    addSections(w, h) {
        const data = this.enemies;

        for (let key in data) {
            let section = new BattleSection(this.scene, 0, 0, [], w, h, key, data[key], this.battleData);
            this.scroll.addItem(section);
        }

        this.sectionsAdded = true;
    }

    async updateInfo(data) {
        for (let item of this.scroll.items) {
            item.update(data);
        }
    }
}
