import mapMachineData from "../../../../../../config/machines/mapMachineData";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import ActionButton from "../../../../../main/buttons/common/ActionButton";

export default class OreMiningStatsSectionItem extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, cell, index) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.cell = cell;
        this.index = index;

        this.create(this.cell.cellBg.displayWidth, this.cell.cellBg.displayHeight);
    }

    create(w, h) {
        const size = h * 0.73;
        const oreType = mapMachineData[this.cell.targetScene]['ore'];

        this.add(this.darkCover = this.scene.add.image(0, 0, 'UI', 'skill_cell.png').setDisplaySize(size, size / 1.16).setOrigin(0.5, 0.5));
        this.add(this.batteryIcon = this.scene.add.image(0, 0, 'items_icons', `battery_tech.png`).setDisplaySize(h * 0.25, h * 0.25).setOrigin(0.5, 1));
        this.add(this.openAmountText = this.scene.add.text(0, 0, `${this.index * 10}`)
            .setOrigin(0.5, -0.1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.17));

        this.add(this.rateText = this.scene.add.text(w * 0.05, h * -0.05, '-').setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.17));
        this.add(this.oreText = this.scene.add.text(w * 0.07, h * 0.05, '-').setOrigin(0, 0).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.17));
        this.add(this.oreIcon = this.scene.add.image(w * 0.04, h * 0.05, 'items_icons', `${oreType}.png`).setDisplaySize(h * 0.2, h * 0.2).setOrigin(0, 0));

        this.add(this.frame = new ActionButton(this.scene, 0, 0, [], 'table_button', size, size, '', this.onClick.bind(this)));

        this.setButtonEvents();
    }

    update(data) {
        this.clear();
        this.lock();
        if (data[this.parentContainer.targetScene]['opened'] > this.index) {
            this.open();
        }
    }

    lock() {
        this.isOpened = false;

        this.darkCover.setAlpha(1);
        this.batteryIcon.setAlpha(1);
        this.openAmountText.setAlpha(1);
    }

    onClick() {
        if (!this.isOpened) {
            this.unlock();
        } else if (this.isPrepared && this.isEmpty) {
            const icon = this.scene.game.registry.get('machineToInstall');
            icon && this.installMachine(icon);
            this.scene.game.registry.set('machineToInstall', null);
        } else {
            InfoTables.showWarningText('Выберите машину');
        }
    }

    async unlock() {
        const data = await apiClient.machineCells.unlock({
            scene: this.parentContainer.targetScene,
            index: this.index
        });

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(data['data']['message']);
        this.update(data['data']['cells']);
    }

    open() {
        this.isOpened = true;
        this.isEmpty = true;

        this.darkCover.setAlpha(0.001);
        this.batteryIcon.setAlpha(0.001);
        this.openAmountText.setAlpha(0.001);

        if (this.isPrepared) {
            this.frame.obj.setAlpha(1);
            this.frame.obj.setTint(0x79ff84);
        }
    }

    setButtonEvents() {
        this.frame.on('hover', () => {
            this.frame.obj.setTint(0x00e2ff);
        });

        this.frame.on('out', () => {
            if (this.isPrepared) {
                this.frame.obj.setAlpha(1);
                this.frame.obj.setTint(0x79ff84);
            }
        });
    }

    prepare() {
        this.isPrepared = true;
        this.frame.obj.setAlpha(1);
        this.frame.obj.setTint(0x79ff84);
    }

    stop() {
        if (!this.machine) {
            return;
        }
        this.machine.blink(0xffedbb);
    }

    break() {
        if (!this.machine) {
            return;
        }
        this.machine.disable();
        this.machine.obj.setTint(0xFF7979);
    }

    clear() {
        this.isEmpty = true;
        this.machine && this.machine.destroy();
        this.rateText.setText('-').setStyle(TEXT_STYLE_BLUE);
    }

    reset() {
        this.isPrepared = false;
        this.frame.obj.setAlpha(0.001);
        this.frame.obj.setTint(0x00e2ff);
    }

    async installMachine(icon) {
        const data = await apiClient.machines.install({
            machine: icon.iconData,
            scene: this.parentContainer.targetScene,
            cell_number: this.index + 1
        });

        if (data['status'] != 'success') {
            return;
        }

        icon.iconData = data['machine'];
        this.addMachine(icon);
    }

    addMachine(icon) {
        this.isEmpty = false;
        this.machine = icon;
        const machineData = icon.iconData;
        let rate = machineData['rate'];
        let textStyle = TEXT_STYLE_BLUE;
        let oreStyle = TEXT_STYLE_GREEN;

        this.add(icon);
        icon.setPosition(0, 0);
        icon.resize(this.cell.height * 0.74, this.cell.height * 0.74);

        InfoTables.tables.get('production').tableLeft.resetCells();

        if (machineData['status'] == 'stopped') {
            this.stop();
            rate = 0;
            textStyle = TEXT_STYLE_ORANGE;
            oreStyle = TEXT_STYLE_ORANGE;

        } else if (machineData['status'] == 'broken') {
            this.break();
            rate = 0;
        }

        this.rateText.setText(`${rate} р./ч.`).setStyle(textStyle).setFontSize(window.screen.height * 0.016);
        this.oreText.setText(machineData['collected_ore']).setStyle(oreStyle).setFontSize(window.screen.height * 0.016);

        icon.setClickCallback(async () => {
            const data = await apiClient.machines.remove(machineData);

            if (data['status'] != 'success') {
                return;
            }

            this.clear();
            InfoTables.tables.get('production').update();
        });
    }
}