import TimeManager from "../../../../../../managers/TimeManager";
import UIController from "../../../../../../managers/UIController";
import InfoTables from "../../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../../scenes/ui/UI";
import apiClient from "../../../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../../utils/textStyles";
import { multiplyObjectValues } from "../../../../../../utils/utils";
import ActionButton from "../../../../../main/buttons/common/ActionButton";


export default class MakeUnitFromCloneCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, unitCategory, unitData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.width = width;
        this.height = height;

        this.unitCategory = unitCategory;

        this.resText = [];
        this.create(width, height);
    }

    create(w, h) {
        this.add(this.button = new ActionButton(this.scene, w * 0.432, 0, [], 'table_button', h * 0.57, h * 0.57, 'green', this.createUnit.bind(this)));
    }

    async update() {
        const processes = await apiClient.processes.getAll();
        this.checkProcessExists(processes);

        if (this.unitData) {
            this.updateText();
            return;
        }

        const data = await apiClient.units.getData();
        this.unitData = data[this.unitCategory];
        this.addInfo(this.width, this.height);
        this.updateText();
    }

    addInfo(w, h) {
        UIController.arrangeItems({
            container: this,
            itemsData: this.unitData['material'],
            x: w * -0.19,
            y: h * 0.22,
            iconSize: h * 0.3,
            fontSize: h * 0.17,
            space: w * 0.2,
        });

        this.add(this.timeText = this.scene.add.text(w * 0.34, h * 0.22, '3:00').setOrigin(1, 0.5));
    }

    updateText() {
        const fontSize = this.height * 0.19;

        this.timeText.setText(TimeManager.formatFullTime(this.unitData['creation_time']));
        this.timeText.setStyle(this.isCreating ? TEXT_STYLE_GREEN : TEXT_STYLE_BLUE).setFontSize(fontSize);

        const items = this.scene.game.registry.get('items');
        UIController.updateItemsText(items, this.unitData['material'], this.itemsText);
    }

    deactivate() {
        this.setAlpha(0.3);
        this.button.disable();
    }

    activate() {
        this.setAlpha(1);
        this.button.makeInteractive();
    }

    async createUnit() {
        const name = this.unitCategory;
        const building = this.scene.game.registry.get('curBuilding');

        const data = await apiClient.processes.start({
            category: 'unit_creation',
            name: name,
            building_name: building.texture
        });

        console.log(data);

        if (data['msg'] == '200') {
            this.startProcess(data['data']);
            updateUI(this.scene);
        } else {
            InfoTables.showWarningText(data['msg']);
        }
    }

    // data - информация о процессе производства
    startProcess(data) {
        this.isCreating = true;

        this.button.disable();
        this.timeText.setStyle(TEXT_STYLE_GREEN);

        TimeManager.createTimer(this.scene, this.unitCategory, data['duration'],
            this.finishProcess.bind(this),
            (remainingTime) => {
                this.timeText.setText(TimeManager.formatFullTime(remainingTime));
            }
        );

        const building = this.scene.game.registry.get('curBuilding');
        building.addProcessToStack(data);
    }

    async finishProcess() {
        this.isCreating = false;
        const data = await apiClient.processes.finish({ category: 'unit_creation', name: this.unitCategory });
        console.log(data);

        this.button.makeInteractive();
        this.updateText();

        console.log('process finished by timer');
        updateUI(this.scene);
        InfoTables.curTable.update();
    }

    checkProcessExists(processes) {
        const process = processes[this.unitCategory];

        if (process) {
            if (process['finished'] == true) {
                console.log('process finished by update');
            } else {
                this.startProcess(process);
            }
        }
    }
}