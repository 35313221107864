import UnitTable from '../common/UnitTable.js';
import { CharacterEquipment } from './equipment/CharacterEquipment.js';
import { CharacterParameterButtons, CharacterPointsText } from './characterStatsModule.js';
import { CharacterSkills } from './characterSkillsModule.js';
import apiClient from '../../../../../../services/apiClient.js';
import loadAssets from '../../../../../../managers/AssetManager.js';
import { CharacterEquipmentInfo } from './equipment/CharacterEquipmentInfo.js';


export default class CharacterTable extends UnitTable {

    create() {
        this.unitName = this.texture;
        super.create();
    }

    async update(category) {
        await super.update(category);

        const data = await apiClient.units.getСharacterByName({ name: this.unitName });

        await this.loadEquipmentParts(data);
        this.updateInfo(data);
    }

    async loadEquipmentParts() {
        const texture = this.unitName + '_parts';
        const buildingName = this.scene.game.registry.get('curBuilding').texture;

        await loadAssets(this.scene, 'atlas', texture,
            `UI/info_tables/tables/${buildingName}/armor/armor.png`,
            `UI/info_tables/tables/${buildingName}/armor/armor.json`);
    }

    addContent(w, h) {
        if (this.unitName == 'Chlapidol') {
            this.healItem = 'mutagen_heal';
            this.healItemIncrease = 200;
        } else if (this.unitName == 'Shtryzik') {
            this.healItem = 'battery_heal';
            this.healItemIncrease = 200;
        }
        super.addContent(w, h);

        this.params.addExtraParameter('close_damage', 4);
        this.add(this.paramButtons = new CharacterParameterButtons(this.scene, w * -0.221, h * -0.231, [], w, h));

        this.add(this.points = new CharacterPointsText(this.scene, w * -0.265, h * -0.285, '-', h));
        this.add(this.skills = new CharacterSkills(this.scene, 0, 0, [], w, h));

        this.add(this.equipment = new CharacterEquipment(this.scene, 0, 0, [], w, h, this.unitName));
        this.add(this.equipmentInfo = new CharacterEquipmentInfo(this.scene, 0, 0, [], w, h, this.unitName));
    }

    updateInfo(data) {
        super.updateInfo(data);

        if (data) {
            this.points.update(data['unit']['points']);
            this.equipment.update(data['data']['equipment'], this.equipmentInfo, 0, 0, this.width, this.height);
            this.equipmentInfo.update(data['data']['equipment'], data['equipment']);
        }
    }
}

