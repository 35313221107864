import InfoTables from "../../../../../scenes/ui/InfoTables";
import eventBus from "../../../../../services/eventBus";
import ItemIcon from "../../../../main/icons/ItemIcon";
import InfoTableMainScroll from "../../../common/scroll/InfoTableMainScroll";
import BuildingTable from "./BuildingTable";
import ModuleCell from "./barraks/ModuleCell";
import ModuleUpgradeCell from "./barraks/ModuleUpgradeCell";
import MakeUnitFromCloneCell from "./common/MakeUnitFromCloneCell";

export default class BarraksTable extends BuildingTable {

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        this.add(this.mainScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {
            data = this.scene.game.registry.get('items');

            for (let key in data) {
                if (!key.includes('soldier_module') || data[key] <= 0) continue;

                let icon = new ItemIcon(this.scene, 0, 0, [], 'soldier_module_icons', h * 0.07, h * 0.07);
                icon.setIconData({ name: key, amount: data[key] });

                // Колбэк при клике на иконку
                icon.setClickCallback(() => {
                    this.selectModule(icon);
                });

                this.mainScroll.addItem(icon);
            }

        }));

        this.addContent(w, h);
        eventBus.on('useModule', this.updateMainScroll.bind(this));
    }

    addContent(w, h) {
        this.moduleCells = [
            new ModuleCell(this.scene, w * 0.162, h * -0.216, [], w, h, this),
            new ModuleCell(this.scene, w * 0.215, h * -0.216, [], w, h, this),
            new ModuleCell(this.scene, w * 0.268, h * -0.216, [], w, h, this),
        ];
        this.add(this.moduleCells);

        this.add(this.newModuleCell = new ModuleUpgradeCell(this.scene, w * 0.096, h * -0.216, [], w, h, this));
        this.add(this.unitCreationCell = new MakeUnitFromCloneCell(this.scene, w * -0.17, h * 0.212, [], w * 0.24, w * 0.06063, 'soldier'));

        const text = "Здесь присходит улучшение модулей. Из трех модулей одного уровня получается один модуль следующего уровня. \n\nОбъединять можно только модули одного типа (например, нельзя объединить модуль здоровья с модулем меткости). \n\nНажмите на модуль внизу, чтобы выбрать его. Модуль автоматически добавится в соответствующую ячейку.";

        this.add(this.text = this.scene.add.text(w * 0.047, h * -0.13, text).setOrigin(0, 0).setStyle({
            fontFamily: 'Game_Font',
            fontSize: h * 0.02,
            color: '#ffedbb',
            shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 },
            align: 'justify',
            wordWrap: { width: w * 0.28 },
        }).setLineSpacing(h * 0.001));
    }

    selectModule(module) {
        const freeCell = this.moduleCells.find(cell => cell.free);

        if (!freeCell) {
            InfoTables.showWarningText('Все модули выбраны');
            return;
        }

        let amount = module.getData('amount');

        if (amount > 0) {
            module.updateAmount(--amount);
            freeCell.addModule(module.getData('name'), module);
        } else {
            InfoTables.showWarningText('Не достаточно модулей данного типа');
        }
    }

    async update(category) {
        await super.update(category);

        const items = this.scene.game.registry.get('items');
        this.updateMainScroll(items);
        this.unitCreationCell.update();
    }

    updateMainScroll(data) {
        this.mainScroll.updateContent(data);
    }
}