import BattleUI from "../../../../scenes/ui/BattleUI";
import { TILE_HEIGHT, TILE_WIDTH } from "../../../../utils/const";
import { TEXT_STYLE_ORANGE } from "../../../../utils/textStyles";
import { isPointInMatrix, getDistance, getRandomArrayElement, callWithProbability } from "../../../../utils/utils";
import BattleUnitHPBar from "./components/BattleUnitHPBar";
import MoveTo from 'phaser3-rex-plugins/plugins/moveto.js';

export default class BattleUnit extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, type, size) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.type = type; // player, enemy
        this.size = size;
    }

    setUnitData(unitData, linkedIcon) {
        this.setData('unit', unitData);
        this.linkedIcon = linkedIcon || null;
        this.initialRange = unitData['range'];

        // По умолчанию, юниты атакуют все цели без приоритета
        this.setData('config', {
            "target": 'unit', // unit, crystall, extra
            "priority": null, // Категория цели
            "close_fight": false,
            "close_fight_anims": ['attack'],
            "attack_frames": [5],
            "skills": [],
            "weakness": ['fear'],
            "XP_cost": 1,
        });

        this.create();
    }

    create() {
        const size = window.screen.height * this.size;
        const unitData = this.getData('unit');
        const category = unitData['category'];
        let texture = category;

        switch (category) {
            case 'commander':
            case 'character':
            case 'animal':
                texture = unitData['name'];
                break;
            case 'soldier':
                texture = `soldier_${unitData['rank']}`;
                break;
        }

        this.add(this.unitSprite = this.scene.add.sprite(0, 0, texture + '_attack').setOrigin(0.5, 0.6).setDisplaySize(size, size));
        this.add(this.HPBar = new BattleUnitHPBar(this.scene, 0, size * -0.45, [], category, this.type));
        this.HPBar.update(unitData['health'], unitData['max_health']);

        this.setSize(size, size); // Размеры контейнера для физики
        this.setPosition(
            TILE_WIDTH * (this.x - 0.5),
            TILE_HEIGHT * (this.y - 0.5)
        );

        this.moveToPlugin = new MoveTo(this, {
            speed: this.getData('unit')['speed'] * 10,
        });

        this.rest();
        this.monitorTargets();
    }

    /**
     * The rest state of the unit. In this state unit doesn't do anything and plays the idle animation.
     */
    rest() {
        this.setState('idle');
        this.playAnimation('idle');
    }

    /**
     * Sets the first destination, when the player deploys the unit. 
     * The destination is a point where unit goes after he is created on the map.
     * Setting the first destination can be ommited, then the `findTarget()` is called.
     * @param {number} toX The X position of the point.
     * @param {number} toY The Y position of the point.
     */
    setFirstDestination(toX, toY) {
        this.target = {
            x: (toX + 0.5) * TILE_WIDTH,
            y: (toY + 0.5) * TILE_HEIGHT
        };
        this.setState('destination');
        this.findPathTo(this.target);
    }

    /**
     * The main timer of the unit that checks every second if the suitable enemies are present on the map.
     */
    monitorTargets() {
        this.monitorTargetsTimer = this.scene.time.addEvent({
            delay: 1000,
            callback: () => {
                if ((['dead', 'paralysed'].includes(this.state))) {
                    return;
                }

                if (['commander', 'character'].includes(this.getData('unit')['category'])) {
                    if (!this.target) {
                        this.findTarget();
                    }
                } else {
                    this.checkForThreat();
                }
            },
            loop: true
        });
    }

    /**
     * Checks if scary enemies for the current unit are present on the map.
     * If unit is afraid of some type of units, he will try to avoid them and escape in the opposite direction.
     * Can be overriden for different types of units.
     */
    checkForThreat() {
        const army = this.scene.data.get('army');
        const enemies = this.scene.data.get('enemies');

        // Array of targets that unit is supposed to attack or avoid
        const targetArray = this.isEnemy() ? army : enemies;

        // The current position of the unit.
        const x = Math.floor(this.x / TILE_WIDTH);
        const y = Math.floor(this.y / TILE_HEIGHT);

        /**
         * The number of scary enemies detected near the unit.
         */
        let scaryEnemiesCnt = 0;

        targetArray.forEach(enemy => {
            /**
             * @type {bool}
             * @readonly If the enemy has fear skill in his array of skills or not.
             */
            const hasFearSkill = enemy.getData('config')['skills'].includes('fear');

            // The position of the enemy
            const enemyX = Math.floor(enemy.x / TILE_WIDTH);
            const enemyY = Math.floor(enemy.y / TILE_HEIGHT);

            // The difference between unit position and enemy position
            const difX = x - enemyX;
            const difY = y - enemyY;

            /**
             * @type {number}
             * @readonly The distance between the unit and the threat.
             */
            const dist = getDistance(x, y, enemyX, enemyY);

            if (hasFearSkill && dist <= 5) {
                scaryEnemiesCnt++;

                // Check if the unit is able to escape from the scary enemy.
                const escape = this.isAbleToEscape(x, y, difX, difY);

                if (escape.spotExist) {
                    this.target = null;
                    this.moveToPlugin.off('complete');
                    this.offAnimations();
                    this.fleeFromThreat(escape.toX, escape.toY);

                } else {
                    // If no path is found and distance is suitable, unit should attack the scary enemy.
                    if (!this.isTargetInRange(x, y, enemyX, enemyY, () => {
                        this.target = enemy;
                        this.attack(enemy);
                    })) {
                        this.findTarget();
                    }
                }
            }
        });

        if (scaryEnemiesCnt == 0 && !this.target) {
            this.findTarget();
        }
    }

    /**
     * Check if the unit is able to escape from the scary enemy.
     * Try to find a path in the opposite direction from the enemy.
     * @param {number} fromX The X position of the unit.
     * @param {number} fromY The Y position of the unit.
     * @param {number} difX The X position of the enemy.
     * @param {number} difY The Y position of the enemy.
     * @returns {object} 
     */
    isAbleToEscape(fromX, fromY, difX, difY) {
        let toX, toY;

        if (difX > 0 && difY < 0) { toX = fromX + 1; toY = fromY - 1 }
        else if (difX > 0 && difY == 0) { toX = fromX + 1; toY = fromY }
        else if (difX > 0 && difY > 0) { toX = fromX + 1; toY = fromY + 1 }
        else if (difX == 0 && difY > 0) { toX = fromX; toY = fromY + 1 }
        else if (difX < 0 && difY > 0) { toX = fromX - 1; toY = fromY + 1 }
        else if (difX < 0 && difY == 0) { toX = fromX - 1; toY = fromY }
        else if (difX < 0 && difY < 0) { toX = fromX - 1; toY = fromY - 1 }
        else if (difX == 0 && difY < 0) { toX = fromX; toY = fromY - 1 }

        const spotExist = isPointInMatrix(this.scene.grid, toX, toY);
        return { spotExist: spotExist, toX: toX, toY: toY };
    }

    fleeFromThreat(toX, toY) {
        this.state = 'destination';
        this.findPathTo({
            x: (toX + 0.5) * TILE_WIDTH,
            y: (toY + 0.5) * TILE_HEIGHT
        });
    }

    /**
     * Finds the suitable enemy for the specified unit to attack. 
     * Enemy can be a unit with the particular category or an object on the map.
     * Different types of units pursuit different goals and have different priority targets.
     * If unit have a non-unit target, it will search for the particulat type of an object. 
     * Some units may attack common enemies when no targets with the prior category is left.
     * @returns 
     */
    async findTarget() {
        if (this.target) {
            return;
        }
        
        // If unit is going after some destination, he is not interested in other targets ;)
        if (['destination', 'dead'].includes(this.state)) {
            return;
        }

        const army = this.scene.data.get('army');
        const enemies = this.scene.data.get('enemies');
        const objects = this.scene.data.get('objects');
        const targetType = this.getData('config')['target'];
        let priority = this.getData('config')['priority'];

        /**
         * The distance at which the unit triggers to attack (sees) the enemy.
         * By default, all player units see every enemy on the map.
         * @type {number}
         */
        const detectionDist = this.isEnemy() ? 12 : 100;

        /**
         * The array of enemies and potential targets
         * @type {BattleUnit[]}
         */
        let targetArray = this.isEnemy() ? army : enemies;

        // The current position of the unit
        const x = Math.floor(this.x / TILE_WIDTH);
        const y = Math.floor(this.y / TILE_HEIGHT);

        // If unit prefers other type of targets rather than common enemies, he has the different target array.
        if (targetType == 'extra') {
            targetArray = objects;
            let potentialTarget = targetArray.find(target => target.getData('unit')['category'] == priority);

            // If no potential targets left on the map, unit should attack common enemies.
            if (targetArray.length == 0 || !potentialTarget) {
                priority = null; // clear the priority by which the unit identifies his targets.
                targetArray = this.isEnemy() ? army : enemies;
            }
        }

        // If Chlapidol shows up on the map as the enemy, he will attack regular units, not only animals.
        // In that case we need to set priority of prefered targets to null.
        if (this.getData('unit')['name'] == 'Chlapidol' && this.isEnemy()) {
            priority = null;
        }

        // If no targets has left on the map and unit is not dead itself, unit can take some rest ;)
        if (targetArray.length == 0) {
            (!['dead', 'idle'].includes(this.state)) && this.rest();
            return;
        }

        let curTarget = null;
        // Set current path length as a big number to reduce it while choosing the enemy.
        let curPathLength = 1000;

        for (let enemy of targetArray) {
            let toX = Math.floor(enemy.x / TILE_WIDTH);
            let toY = Math.floor(enemy.y / TILE_HEIGHT);

            // If the specified point doesn't exist in matrix, continue the iteration for the next enemy.
            if (!isPointInMatrix(this.scene.grid, toX, toY)) {
                console.log('point out of grid');
                continue;
            }

            if (!priority || (priority == enemy.getData('unit')['category'])) {
                this.scene.easystar.findPath(x, y, toX, toY, (path) => {
                    if (path && (path.length < curPathLength)) {
                        curPathLength = path.length;
                        curTarget = enemy;
                    }
                });
                this.scene.easystar.calculate();
            }
        };

        this.scene.time.delayedCall(100, () => {
            if (curPathLength <= detectionDist && this.state != 'dead') {
                this.target = curTarget;
                this.findPathTo(this.target);
            }
        });
    }

    /**
     * Checks if the distance to the selected target is suitable for attack. 
     * Distance should be smaller than unit range of attack.
     * @param {number} x The X position of the unit.
     * @param {number} y The Y position of the unit.
     * @param {number} toX The X position of the target.
     * @param {number} toY The Y position of the target.
     * @param {Function} callback Function that is called if the distance is suitable.
     * @returns {bool}
     */
    isTargetInRange(x, y, toX, toY, callback = () => { }) {
        const targetInRange = getDistance(x, y, toX, toY) <= this.getData('unit')['range'];

        if (targetInRange && !this.hasDestination()) {
            callback();
            return true;
        }
        return false;
    }

    /**
     * Find the best path to the selected target.
     * @param {object} target 
     * @returns 
     */
    findPathTo(target) {
        const easystar = this.scene.easystar;

        if (!target) {
            return;
        }

        let fromX = Math.floor(this.x / TILE_WIDTH);
        let fromY = Math.floor(this.y / TILE_HEIGHT);
        let toX = Math.floor(target.x / TILE_WIDTH);
        let toY = Math.floor(target.y / TILE_HEIGHT);

        // Если цель в радиусе поражения, сразу делаем атаку
        if (this.isTargetInRange(fromX, fromY, toX, toY, () => this.attack(target))) {
            return;
        }

        easystar.findPath(fromX, fromY, toX, toY, (path) => {
            if (path === null) {
                console.log('Путь до цели не найден');
                this.rest();
            } else {
                this.clearCellCost(fromX, fromY);
                !(this.hasDestination() || this.extraPathConditions()) && path.pop();

                this.walk(path);
            }
        });
        easystar.calculate();
    }
    extraPathConditions() { }

    walk(path) {
        if (!path[1]) {
            if (this.hasDestination()) {
                this.target = null;
                this.setState('idle');
                this.rest();
            }
            return;
        }

        let x = path[1].x;
        let y = path[1].y;

        this.moveToPlugin.once('complete', () => {
            this.takeCell(x, y, 5);
            this.findPathTo(this.target);
            // Сортировка объектов на карте по Y позиции
            this.scene.map.sort('y');

            if (!this.hasDestination() && this.target) {
                this.target.once('die', () => this.target = null);
                
            } else if (!this.target) {
                this.rest();
            }
        });

        this.move(x, y);
    }

    move(x, y) {
        this.setDirection({ x: x * TILE_WIDTH, y: y * TILE_HEIGHT });
        !this.hasDestination() && this.setState('walk');
        this.playAnimation('walk');

        this.moveToPlugin.moveTo(
            Math.floor(TILE_WIDTH * (x + 0.5)),
            Math.floor(TILE_HEIGHT * (y + 0.5))
        );
    }

    attack(target) {
        this.setState('attack');

        this.chooseAttackAnim(target);
        this.unitSprite.off('animationupdate').on('animationupdate', () => {
            this.dealDamageToTarget(target);
        });

        if (this.target) {
            this.target.once('die', () => this.onTargetDie(target));
        }
    }

    dealDamageToTarget(target) {
        this.hitTarget(target);
    }

    hitTarget(target) {
        const x = Math.floor(this.x / TILE_WIDTH);
        const y = Math.floor(this.y / TILE_HEIGHT);
        const enemyX = Math.floor(target.x / TILE_WIDTH);
        const enemyY = Math.floor(target.y / TILE_HEIGHT);

        const anims = this.getData('config')['close_fight_anims'];
        const attackFrames = this.getData('config')['attack_frames'];
        const unitData = this.getData('unit');

        // Обновляем анимацию каждое ее повторение
        this.unitSprite.off('animationrepeat').on('animationrepeat', () => {
            this.chooseAttackAnim(target, anims);
        });

        // Если цель вышла за радиус атаки, догоняем ее
        if (!this.isTargetInRange(x, y, enemyX, enemyY)) {
            this.moveToPlugin.off('complete');
            this.clearCellCost(x, y);
            this.findPathTo(target);
            return;
        }

        // Если цель идет к точке назначения, и ее начали атаковать, она атакует в ответ
        if (['destination', 'walk'].includes(target.state)) {
            target.fightBack(this);
        }

        if (this.isCloseFightEnabled(target)) {
            if (this.unitSprite.anims.currentFrame.index == 5) {
                callWithProbability(unitData['accuracy'], () => {
                    target.takeDamage(unitData['close_damage']);
                    this.extraCloseFightAction(target);
                });
            }
        } else if (attackFrames.includes(this.unitSprite.anims.currentFrame.index)) {
            this.shoot(this.x, this.y, this.dir, target);

            callWithProbability(unitData['accuracy'], () => {
                target.takeDamage(unitData['damage']);
                this.extraShootAction(target);
            });
        }
    }

    isCloseFightEnabled(target) {
        const x = Math.floor(this.x / TILE_WIDTH);
        const y = Math.floor(this.y / TILE_HEIGHT);
        const enemyX = Math.floor(target.x / TILE_WIDTH);
        const enemyY = Math.floor(target.y / TILE_HEIGHT);

        const unitData = this.getData('unit');
        const closeFightEnabled = unitData['close_damage'] > 0 && getDistance(x, y, enemyX, enemyY) <= 1;

        return closeFightEnabled;
    }

    chooseAttackAnim(target) {
        const anims = this.getData('config')['close_fight_anims'];
        this.setDirection(target);
        this.playAnimation(this.isCloseFightEnabled(target) ? anims : 'attack');
    }

    fightBack(target) {
        this.isFighting = true;
        this.target = target;
        this.offAnimations();
        this.moveToPlugin.once('complete', () => this.attack(target));
    }

    shoot(x, y, dir, target) { }
    extraShootAction(target) { }
    extraCloseFightAction(target) { }

    /**
     * Describes what happens when the selected target dies.
     * @param {object} target 
     */
    onTargetDie(target) {
        this.isFighting = false;
        this.offAnimations();
        this.target = null;
        const unitData = this.getData('unit');
        const xpCost = target.getData('config')['XP_cost'];

        // Начисление опыта
        if (!this.isEnemy()) {
            this.createPopText('XP +' + xpCost);

            if (!unitData['receivedXP']) {
                unitData['receivedXP'] = xpCost;
            } else {
                unitData['receivedXP'] += xpCost;
            }

            unitData['xp'] += xpCost;
            unitData['points']++;
        }
    }

    takeDamage(damage) {
        const unit = this.getData('unit');

        let clearDamage = damage - ~~(damage * (unit['armor'] / 100));
        unit['health'] -= clearDamage;

        if (this.linkedIcon) {
            this.linkedIcon.updateContent(unit);
        }

        if (unit['health'] <= 0) {
            unit['health'] = 0;

            if (this.state != 'dead') {
                this.die();
            }
        }

        this.HPBar.update(unit['health'], unit['max_health']);
    }

    die() {
        if (this.state == 'dead') {
            return;
        }
        this.scene.time.removeEvent(this.monitorTargetsTimer);
        this.emit('die', this);
        this.setState('dead');
        const unitData = this.getData('unit');
        const category = unitData['category'];
        const name = unitData['name'];
        const objectCategories = ['crystall', 'device'];

        const data = this.scene.data;
        let array = this.isEnemy() ? data.get('enemies') : data.get('army');

        if (!objectCategories.includes(category)) {
            (category != 'obstacle') && this.moveToPlugin.off('complete');
        } else {
            array = data.get('objects');
        }

        Phaser.Utils.Array.Remove(array, this);
        const deadUnits = data.get('deadUnits');
        if (!objectCategories.includes(category)) {
            deadUnits.push(this);
        }

        if (!this.isEnemy() && !objectCategories.includes(category)) {
            const armyCount = data.get('army_count');
            data.set('army_count', armyCount - 1);
            BattleUI.playerUI.updateContent();

        } else if (!objectCategories.includes(category)) {
            const killedEnemies = data.get('enemies_killed');
            killedEnemies[name]++ || (killedEnemies[name] = 1);
            BattleUI.enemyUI.updateContent();
        }

        this.offAnimations();
        if (category != 'device') this.playAnimation('death');
    }

    /**
     * Paralyze the unit so unit can't move or do anything. 
     * Unit paralazes for the specified time and wakes up after no time is left.
     * If unit is dead by the time he gets free, nothing happens.
     * @param {number} delay Duration of paralysis
     */
    paralyse(delay) {
        const exceptedCategories = ['pichmog', 'beles', 'beles_female'];

        // Some units can't get paralyzed due to their specific abilities or features.
        if (exceptedCategories.includes(this.getData('unit')['name'])) {
            return;
        }

        this.moveToPlugin.off('complete');
        this.unitSprite.anims.stop();

        this.setState('paralysed');

        // Make a delayed call to tell unit to do something after he gets free.
        this.scene.time.delayedCall(delay, () => {
            this.coverNet && this.coverNet.destroy();
            if (this.state != 'dead') {
                this.checkForThreat();
            }
        });
    }

    setDirection(target) {
        let x = Math.floor(this.x / TILE_WIDTH);
        let y = Math.floor(this.y / TILE_HEIGHT);
        let targetX = Math.floor(target.x / TILE_WIDTH);
        let targetY = Math.floor(target.y / TILE_HEIGHT);

        if (x < targetX && y < targetY) {
            this.dir = 'down-r';
        } else if (x > targetX && y < targetY) {
            this.dir = 'down-l';
        } else if (x < targetX && y > targetY) {
            this.dir = 'up-r';
        } else if (x > targetX && y > targetY) {
            this.dir = 'up-l';
        } else if (x == targetX && y < targetY) {
            this.dir = 'down';
        } else if (x == targetX && y > targetY) {
            this.dir = 'up';
        } else if (x < targetX && y == targetY) {
            this.dir = 'right';
        } else if (x > targetX && y == targetY) {
            this.dir = 'left';
        }
    }

    playAnimation(action) {
        const unitData = this.getData('unit');
        const category = unitData['category'];

        if (Array.isArray(action)) {
            action = getRandomArrayElement(action);
        }

        if (this.state == 'idle') {
            const dirs = ['down-r', 'down-l', 'up-r', 'up-l'];
            if (!this.dir || !dirs.includes(this.dir)) {
                this.dir = getRandomArrayElement(dirs);
            }
        }

        let animation = `${category}_${action}_${this.dir}`;

        if (category == 'soldier') {
            animation = `${category}_${unitData['rank']}_${action}_${this.dir}`;

        } else if (['commander', 'character', 'animal', 'robot'].includes(category)) {
            animation = `${unitData['name']}_${action}_${this.dir}`;
        }

        this.unitSprite.anims.play(animation, true);
        this.unitSprite.setDisplaySize(this.width, this.height);
    }

    createPopText(text, item) {
        const h = this.unitSprite.displayHeight;
        const windowH = window.innerHeight;
        const iconSize = windowH * 0.02;

        if (this.popText) return;

        if (item) {
            let texture = 'items_icons';
            if (item.includes('soldier_module')) {
                texture = 'soldier_module_icons';
            }
            this.add(this.icon = this.scene.add.sprite(0, h * -0.5, texture, `${item}.png`).setDisplaySize(iconSize, iconSize).setOrigin(1, 0.5));
        }

        this.add(this.popText = this.scene.add.text(0, h * -0.5, text).setOrigin(item ? 0 : 0.5, 0.5)
            .setStyle(TEXT_STYLE_ORANGE).setFontSize(windowH * 0.015));

        this.scene.tweens.add({
            targets: [this.popText, this.icon],
            delay: 10,
            duration: 500,
            ease: 'Linear',
            y: h * -0.7,
            repeat: false,
            onComplete: () => {
                this.remove(this.popText);
                this.icon && this.remove(this.icon);
                this.popText.destroy();
                this.icon && this.icon.destroy();

                this.popText = null;
            },
        });
    }

    /**
     * Sets the cost for the cell where the unit stands. Cost defines that other units can't step on the cell.
     * @param {number} x The X position ot the unit.
     * @param {number} y The Y position ot the unit.
     * @param {number} cost The cost that must be set to the cell.
     */
    takeCell(x, y, cost) {
        this.scene.easystar.setAdditionalPointCost(x, y, cost);
        this.scene.easystar.setGrid(this.scene.grid);
    }

    /**
     * Sets the cost of the cell to default (`0`) so that other units can go through the cell.
     * @param {number} x The X position ot the unit.
     * @param {number} y The Y position ot the unit.
     */
    clearCellCost(x, y) {
        this.scene.easystar.removeAdditionalPointCost(x, y);
        this.scene.easystar.setGrid(this.scene.grid);
    }

    /**
     * Checks if the unit is the enemy or not.
     * @returns {bool}
     */
    isEnemy() {
        return this.type == 'enemy';
    }

    /**
     * Checks if the unit has the destination or not.
     * @returns {bool}
     */
    hasDestination() {
        return this.state == 'destination';
    }

    /**
     * Clear all the events related to the unit animations.
     */
    offAnimations() {
        this.unitSprite.off('animationstart').off('animationrepeat').off('animationupdate');
    }
}