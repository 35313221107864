import apiClient from "../../../../../../services/apiClient";
import eventBus from "../../../../../../services/eventBus";
import ActionButton from "../../../../../main/buttons/common/ActionButton";
import ItemIcon from "../../../../../main/icons/ItemIcon";

export default class ModuleUpgradeCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, table) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.table = table;
        this.tw = tw;
        this.th = th;
        this.free = true;

        this.create(tw, th);
    }

    create(w, h) {
        this.add(this.frame = this.scene.add.sprite(0, 0, 'UI', 'button.png').setOrigin(0.5, 0.5));
        this.frame.setDisplaySize(h * 0.11, h * 0.11)

        this.frame.setTint(0xff6a6a);

        this.add(this.upgradeButton = new ActionButton(this.scene, w * -0.03, 0, [], 'button_up', h * 0.044, h * 0.052, 'green', this.upgradeModule.bind(this)));

        this.upgradeButton.disable();
        this.upgradeButton.setRotation(Math.PI / 6);
    }

    checkModulesIdentity() {
        const cells = this.table.moduleCells;

        const identical = cells.every(cell => cell.moduleLevel && cell.moduleLevel === cells[0].moduleLevel);

        if (!identical) {
            this.removeModule();
            return;
        }
        this.addModule(cells);
    }

    addModule(cells) {
        const level = cells[0].moduleLevel;
        const key = cells[0].moduleKey;
        this.frame.setTint(0x6aff70);

        this.add(this.module = new ItemIcon(this.scene, 0, 0, [], 'soldier_module_icons', this.th * 0.06, this.th * 0.06));

        this.module.setIconData({ name: `${key}${level + 1}`, amount: 0 });
        this.module.obj.setOrigin(0.5, 0.5);

        this.module.obj.removeInteractive();
        this.module.amountText.destroy();

        this.upgradeButton.makeInteractive();
        this.upgradeButton.blink();
    }

    removeModule() {
        if (this.module) {
            this.remove(this.module);
            this.module.destroy();
        }
        this.frame.setTint(0xff6a6a);
        this.upgradeButton.disable();
    }

    
    async upgradeModule() {
        const cells = this.table.moduleCells;
        const moduleToReduce = cells[0].moduleIcon.getData('name');

        const data = await apiClient.items.modules.upgrade({ name: moduleToReduce });

        if (data['status'] !== 'success') {
            return;
        }

        this.remove(this.module);
        this.module.destroy();

        cells.forEach(cell => cell.deleteModule());
        this.table.updateMainScroll(data['items']);
        this.upgradeButton.disable();
        
        eventBus.emit('upgradeModule');
    }
}