import { TEXT_STYLE_GREEN } from "../../../utils/textStyles";
import ObjectLink from "./ObjectLink";

export default class SceneObjectLink extends ObjectLink {
    create(w) {
        this.type = 'scene';
        super.create(w);
        this.nameText.setStyle(TEXT_STYLE_GREEN).setFontSize(w * 0.011);

        const lw = this.link.displayWidth;
        const lh = this.link.displayHeight;
        this.add(this.hex = this.scene.add.sprite(lw * 0.203, lh * -0.687, 'UI', 'hex.png').setDisplaySize(lh * 0.34, lh * 0.34));
        this.add(this.icon = this.scene.add.sprite(lw * 0.203, lh * -0.687, 'battle_ui', 'location_mark.png').setDisplaySize(lh * 0.2, lh * 0.2));

        this.icon.setTint(0x79ff84);
    }

    flip() {  
        super.flip();
        this.hex.setPosition(this.link.displayWidth * -0.203, this.link.displayHeight * -0.687);
        this.icon.setPosition(this.link.displayWidth * -0.203, this.link.displayHeight * -0.687);
    }
};