import JournalScrollItem from "./JournalScrollItem";
import JournalSideScroll from "./JournalSideScroll";

export default class JournalScrollAnimals extends JournalSideScroll {

    addItems() {
        const animals = this.scene.journalData['animals'];
        const data = this.scene.journalData['data']['animals'];

        for (let name in animals) {
            if (animals[name] == 0) {
                continue;
            }
            let cell = new JournalScrollItem(this.scene, 0, 0, [], 'journal_animal_icons', name, data[name]['name']);
            this.scroll.addItem(cell);
        }

        if (!this.scroll.items.length) {
            return;
        }
        this.scroll.items[0].button.click(false);
    }
}