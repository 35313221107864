import UIController, { ScrollManager } from '../../../../../managers/UIController.js';
import { TEXT_STYLE_BLUE, TEXT_STYLE_ORANGE, TEXT_STYLE_GREEN } from '../../../../../utils/textStyles.js';
import SingleInfoTable from '../common/SingleInfoTable.js';
import Button from '../../../../main/buttons/common/Button.js';
import apiClient from '../../../../../services/apiClient.js';


export default class StoreTable extends SingleInfoTable {

    addInfo(w, h) {
        const fontSize = h * 0.025;

        this.add(this.capacityTitle = this.scene.add.text(w * -0.32, h * -0.33, 'Вместимость склада:')
            .setOrigin(0, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(fontSize));

        const capacity = this.scene.game.registry.get('items')['store_capacity'];

        this.add(this.storedText = this.scene.add.text(w * 0.27, h * -0.33, '100').setOrigin(1, 1).setStyle(TEXT_STYLE_GREEN).setFontSize(fontSize));
        this.add(this.capacityText = this.scene.add.text(w * 0.27, h * -0.33, '').setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(fontSize));
        // Линия
        this.add(this.scene.add.line(0, 0, 0, h * -0.32, w * 0.64, h * -0.32, 0x87e9ff));

        this.arrangeCells(w, h);
    }

    async arrangeCells(w, h) {
        const scrollW = w * 0.65;
        const scrollH = h * 0.63;
        const spaceTop = h * 0.03;

        this.scroll = new ScrollManager(this.scene, {
            x: 0,
            y: h * -0.003,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                panel: {
                    top: spaceTop,
                    bottom: spaceTop,
                }
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.addSections(w, h);
        this.add(this.scroll.scrollPanel);
    }

    async update(category) {
        await super.update(category);

        if (!this.sectionsAdded) {
            const data = await apiClient.items.store();
            console.log(data);

            this.itemsData = data['data'];
            this.addInfo(this.width, this.height);
        } 
        
        this.updateInfo();
    }

    addSections(w, h) {
        const data = this.itemsData;

        for (let key in data['items']) {
            if (key != 'spaceship') {
                let curScene = '';

                for (let sceneKey in data['buildings']) {
                    if (data['buildings'][sceneKey][key]) {
                        curScene = sceneKey;
                    }
                }

                let section = new StoreSection(this.scene, 0, 0, [], w * 0.647, w * 0.073, data['buildings'][curScene][key]['name'], data['items'][key], curScene);
                this.scroll.addItem(section);
            }
        }

        this.sectionsAdded = true;
    }

    updateInfo() {
        const items = this.scene.game.registry.get('items');
        const capacity = items['store_capacity'];
        this.capacityText.setText('/' + capacity);

        const itemsStored = obj => Object.values(obj).reduce((a, b) => a + b, 0);
        this.storedText.setText(itemsStored(items));

        for (let item of this.scroll.items) {
            item.update();
        }
    }
}


class StoreSection extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, category, data, targetScene) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.width = width;
        this.height = height;
        this.category = category;
        this.itemsData = data;
        this.targetScene = targetScene;

        this.create(width, height);
    }

    create(w, h) {
        this.add(this.cellBg = this.scene.add.image(0, 0, 'UI', 'store_cell.png').setDisplaySize(w, h).setOrigin(0.5, 0.5));

        this.add(this.button = new Button(this.scene, w * -0.185, h * -0.365, [], 'table_button', h * 0.3, h * 0.3, 'green'));
        this.add(this.buildingName = this.scene.add.text(w * -0.47, h * -0.33, this.category).setOrigin(0, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.17));

        this.addItems(this.itemsData);
    }

    addItems(data) {
        this.cells = [];
        let dist = 0;

        for (let key in data) {
            if (['steel', 'silicon', 'titan', 'clone'].includes(key)) {
                continue;
            }
            let cell = new StoreSectionItem(this.scene, this.width * -0.4595 + dist, this.height * 0.183, [], this.cellBg, key);
            this.cells.push(cell);

            dist += this.width * 0.1958;
        }

        this.add(this.cells);
    }

    update() {
        let items = this.scene.game.registry.get('items');

        this.cells.forEach(cell => {
            cell.update(items[cell.itemName]);
        });
    }

}


export class StoreSectionItem extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, cell, itemName) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.cell = cell;
        this.itemName = itemName;

        this.create(this.cell.displayWidth, this.cell.displayHeight);
    }

    create(w, h) {
        this.add(this.icon = this.scene.add.image(0, 0, 'items_icons', `${this.itemName}.png`).setDisplaySize(h * 0.45, h * 0.45));
        this.add(this.amountText = this.scene.add.text(w * 0.05, h * -0.05, '0').setOrigin(0, 1).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.17));
    }

    update(itemAmount) {
        this.amountText.setText(itemAmount);
    }
}
