import apiClient from "../../../../../services/apiClient.js";
import SingleInfoTable from "../common/SingleInfoTable.js";
import { ScrollManager } from "../../../../../managers/UIController.js";
import FactoryProductionCell from "./components/FactoryProductionCell.js";


export default class FactoryProductionTable extends SingleInfoTable {
    constructor(scene, x, y, children, width, height, texture) {
        super(scene, x, y, children, width, height, texture);
    }

    async update(category) {
        await super.update(category);
        const data = await apiClient.production.getData({ buildingName: category });
        console.log(data);
        
        this.itemsData = data['data'];
        this.processes = data['processes'];

        if (!this.sectionsAdded) {
            this.arrangeCells(this.width, this.height);
        } 

        this.updateContent();
    }

    async arrangeCells(w, h) {
        const scrollW = w * 0.65;
        const scrollH = h * 0.69;
        const spaceTop = h * 0.03;

        this.scroll = new ScrollManager(this.scene, {
            x: 0,
            y: h * -0.033,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                panel: {
                    top: spaceTop,
                    bottom: spaceTop,
                }
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
        this.scroll.sizer.setOrigin(-0.05, 0);
        this.addProductionCells();
    }

    addProductionCells() {
        for (let key in this.itemsData) {
            if (['steel', 'silicon', 'titan'].includes(key)) {
                continue;
            }
            let cell = new FactoryProductionCell(this.scene, 0, 0, [], this.width * 0.55, this.width * 0.06226, key, this.itemsData[key]);
            this.scroll.addItem(cell);
        }
        this.sectionsAdded = true;
    }

    updateContent() {
        for (let item of this.scroll.items) {
            item.update();
            item.checkProcessExists(this.processes);
        }
    }
}