import UIController from "../../../../../../managers/UIController";
import { TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import ArrowButton from "../../../../../main/buttons/production/ArrowButton";
import StartProductionButton from "../../../../../main/buttons/production/StartProductionButton";
import ProductionCell from "./ProductionCell";


export default class FactoryProductionCell extends ProductionCell {
    constructor(scene, x, y, children, width, height, itemName, itemData) {
        super(scene, x, y, children, width, height, itemName, itemData);
    }

    create(w, h) {
        this.add(this.cell = this.scene.add.image(0, 0, 'UI', 'factory_cell.png').setDisplaySize(w, h));

        this.add(this.name = this.scene.add.text(w * -0.34, h * -0.22, this.itemData['name'])
            .setOrigin(0, 0.5).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.22));

        this.add(this.buttonIcon = this.scene.add.sprite(w * 0.47, 0, 'items_icons', 'production.png').setDisplaySize(h * 0.3, h * 0.3));
        this.add(this.button = new StartProductionButton(this.scene, w * 0.468, 0, [], 'table_button', h * 0.54, h * 0.54, 'green', this));

        this.add(this.minus = new ArrowButton(this.scene, w * 0.32, h * -0.22, [], 'UI', h * 0.25, h * 0.25, 'decrease', this));
        this.add(this.plus = new ArrowButton(this.scene, w * 0.4, h * -0.22, [], 'UI', h * 0.25, h * 0.25, 'increase', this));

        this.addInfo(w, h);
    }

    addInfo(w, h) {
        this.add(this.itemIcon = this.scene.add.sprite(w * -0.435, 0, 'items_icons', `${this.itemName}.png`)
            .setDisplaySize(h * 0.55, h * 0.55));

        UIController.arrangeItems({
            container: this,
            itemsData: this.itemData['material'],
            x: w * -0.3,
            y: h * 0.27,
            iconSize: h * 0.33,
            fontSize: h * 0.2,
            space: w * 0.12,
        });

        this.add(this.amountText = this.scene.add.text(w * 0.36, h * -0.22, '1').setOrigin(0.5, 0.5));
        this.add(this.timeText = this.scene.add.text(w * 0.4, h * 0.22, '3:00').setOrigin(1, 0.5));

        this.updateText();
    }

    updateText() {
        super.updateText();
        this.name.setText(this.itemData['name']);
    }
}