import AudioManager from "../../../../managers/AudioManager";
import InfoTables from "../../../../scenes/ui/InfoTables";
import Button from "./Button";

export class ToggleFullScreenButton extends Button {

    click() {
        super.click();

        if (this.scene.scale.isFullscreen) {
            this.scene.scale.stopFullscreen();
        } else {
            this.scene.scale.startFullscreen();
        }
    }
}


export class ToggleAudioButton extends Button {
    click() {
        super.click();
        AudioManager.toggleAudio();
    }
}


export class ToggleMusicButton extends Button {
    click() {
        super.click();
        AudioManager.toggleMusic();
    }
}


export class OpenInfoTableButton extends Button {
    constructor(scene, x, y, children, texture, width, height, color, infoTable, tablePos) {
        super(scene, x, y, children, texture, width, height, color);

        this.infoTable = infoTable;
        this.tablePos = tablePos;
    }

    click() {
        super.click();
        InfoTables.openInfoTable(this.infoTable, { position: this.tablePos });
        localStorage.setItem('achievement_gained', false);
    }

    blink(color, scene) {
        this.scene ??= scene;
        super.blink(color);
    }
}


export class NoActionButton extends Button {
    click() {
        super.click();
        InfoTables.showWarningText('Будет доступно в одном из обновлений');
    }
}