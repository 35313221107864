import { TEXT_STYLE_RED } from "../../../utils/textStyles";
import ObjectLink from "./ObjectLink";

export default class UnitLink extends ObjectLink {
    constructor(scene, x, y, children, name, objectData) {
        super(scene, x, y, children, name, objectData);
    }

    create(w) {
        this.type = 'unit';
        super.create(w);
        this.link.setDisplaySize(w * 0.24, w * 0.084);

        const lw = this.link.displayWidth;
        const lh = this.link.displayHeight;
        this.nameText.setPosition(lw * 0.38, lh * -0.4).setStyle(TEXT_STYLE_RED);

        this.add(this.amount = this.scene.add.text(lw * 0.21, lh * -0.63, '')
            .setOrigin(0, 1).setStyle(TEXT_STYLE_RED).setFontSize(w * 0.011));

        this.add(this.icon = this.scene.add.sprite(lw * 0.145, lh * -0.5, 'unit_category_icons', this.objectData['category'] + '.png')
            .setDisplaySize(lh * 0.23, lh * 0.23));
    }

    setAmount(amount) {
        amount && this.amount.setText(amount.toString());
    }
};