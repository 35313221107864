const barrierData = {
    "barraks_tower": {
        name: "Казармы",
        icons: [
            {x: 0.21, y: 0.05},
            {x: 0.77, y: 0.5},
            {x: -0.8, y: -1.05},
        ]
    },
    "genetic_wall": {
        name: "Зона генетики",
        icons: [
            {x: 0.1, y: 0},
            {x: 0.07, y: 0.05},
            {x: 0.13, y: 0.05},
        ]
    },
    "research_wall": {
        name: "Зона исследований",
        icons: [
            {x: 0.1, y: 0},
            {x: 0.05, y: 0.1},
            {x: 0.15, y: 0.1},
        ]
    },
    "bio_wall": {
        name: "Зона биологии",
        icons: [
            {x: 0.3, y: 0.4},
            {x: 0.2, y: 0},
            {x: 0.2, y: 0},
        ]
    },
    "cave_barrier": {
        name: "Блокпост Крыхона",
        icons: [
            {x: 0.5, y: 0.11},
            {x: 0.2, y: 0.07},
            {x: 0.8, y: 0.17},
        ]
    },
    "south_barrier": {
        name: "Южная граница",
        icons: [
            {x: 0.5, y: 0.2},
            {x: 0.25, y: 0.2},
            {x: 0.75, y: 0.2},
            {x: 0.375, y: 0.1},
            {x: 0.625, y: 0.1},
        ]
    },
    "east_barrier": {
        name: "Восточная граница",
        icons: [
            {x: 0.45, y: 0.25},
            {x: 0.55, y: 0.1},
            {x: 0.35, y: 0.4},
            {x: 0.2, y: 0.325},
            {x: 0.3, y: 0.175},
        ]
    },
    "north_barrier": {
        name: "Северная граница",
        icons: [
            {x: 0.58, y: 0.18},
            {x: 0.45, y: 0.1},
            {x: 0.71, y: 0.26},
            {x: 0.4, y: 0.22},
            {x: 0.53, y: 0.3},
        ]
    },
    "titan_mine_wall": {
        name: "Шахта титана",
        icons: [
            {x: 0.5, y: 0.3},
            {x: 0.3, y: 0.15},
            {x: 0.7, y: 0.45},
        ]
    },
    "zazulic_nest_wall": {
        name: "Пещера зазюликов",
        icons: [
            {x: 0.165, y: 0.05},
            {x: 0.14, y: 0.17},
            {x: 0.19, y: 0.17},
        ]
    },
}

export default barrierData;