import { TEXT_STYLE_BLUE, TEXT_STYLE_ORANGE } from "../../../../../../utils/textStyles";
import ActionButton from "../../../../../main/buttons/common/ActionButton";
import Button from "../../../../../main/buttons/common/Button";

export default class MailCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, mailData) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.mailData = mailData;

        this.create(tw, th);
    }

    create(w, h) {
        const data = this.mailData;

        this.add(this.cell = this.scene.add.image(0, 0, 'UI', 'achievement.png').setDisplaySize(h * 0.067 * 8.347, h * 0.067));

        const cw = this.cell.displayWidth;
        const ch = this.cell.displayHeight;

        this.add(this.icon = this.scene.add.image(cw * -0.43, 0, 'items_icons', `mail.png`)
            .setDisplaySize(ch * 0.67, ch * 0.67));
        this.add(this.title = this.scene.add.text(cw * -0.35, ch * -0.1, data['subject']).setOrigin(0, 1));
        this.add(this.text = this.scene.add.text(cw * -0.35, ch * 0.1, '').setOrigin(0, 0));

        this.add(this.button = new ActionButton(this.scene, cw * 0.453, 0, [], 'table_button', ch * 0.78, ch * 0.78, 'green', this.selectMail.bind(this)));

        this.add(this.frame = this.scene.add.image(cw * -0.431, 0, 'UI', 'table_button.png')
            .setDisplaySize(ch * 1.15, ch * 1.15));

        this.add(this.buttonIcon = this.scene.add.image(cw * 0.453, 0, 'items_icons', 'story.png')
        .setDisplaySize(ch * 0.4, ch * 0.4));

        this.frame.setTint(0x79ff84);
        this.setSize(cw, ch);
        this.reset();
        this.update();
    }

    selectMail() {
        this.parentContainer.parentContainer.updateInfo(this);
    }

    update() {
        if (!this.mailData['is_collected']) {
            return;
        }

        this.setAlpha(0.5);
        this.text.setText('Прочитано').setStyle(TEXT_STYLE_BLUE);
    }

    reset() {
        const ch = this.cell.displayHeight;

        this.title.setStyle(TEXT_STYLE_ORANGE).setFontSize(ch * 0.3);
        this.frame.setAlpha(0.001);
    }
}