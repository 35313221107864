import SingleInfoTable from "../common/SingleInfoTable";
import BuildingInfo from "./BuildingInfo";

export default class BuildingTable extends SingleInfoTable {
    
    create() {
        super.create();
        this.add(this.info = new BuildingInfo(this.scene, 0, 0, [], this.width, this.height));
    }

    async update(category) {
        await super.update(category);
        this.updateInfo();
    }

    updateInfo(buildingLevel) {
        const level = buildingLevel ?? this.scene.game.registry.get('curBuilding').level;
        this.info.updateContent(level);
    }
}