import InfoTables from "../../../../../../scenes/ui/InfoTables";
import apiClient from "../../../../../../services/apiClient";
import UnitIcon from "../../../../../main/icons/UnitIcon";
import InfoTableMainScroll from "../../../../common/scroll/InfoTableMainScroll";
import SingleInfoTable from "../../common/SingleInfoTable";
import HealingCellContainer from "./HealingCellContainer";

export default class HospitalHealingTable extends SingleInfoTable {

    create() {
        super.create();
        const w = this.width;
        const h = this.height;
        const category = this.texture.slice(9);
        this.unitCategory = category;

        this.add(this.mainScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let index in data) {
                if (data[index]['status'] == 'lookout') {
                    continue;
                }
                let unit = new UnitIcon(this.scene, 0, 0, [], 'button', h * 0.1, h * 0.1);
                unit.setUnitData(data[index], index);

                // Колбэк при клике на иконку юнита
                unit.setClickCallback(() => {
                    const freeCell = this.cellContainer.findFreeCell();
                    if (freeCell) {
                        this.selectUnit(unit, freeCell);
                    } else {
                        InfoTables.showWarningText('Нет свободных капсул');
                    }
                });

                this.mainScroll.addItem(unit);
            }
        }));

        this.addContent(w, h);
    }

    async update(category) {
        await super.update(category);
        this.cellContainer.clearAll(this.parentContainer.buildingLevel);

        const data = await apiClient.units.getAll();
        const processes = await apiClient.processes.getAll();

        this.units = this.sortUnits(data['units']);
        this.updateMainScroll(this.units);

        this.checkHealingProcessExists(processes);
    }

    addContent(w, h) {
        this.add(this.cellContainer = new HealingCellContainer(this.scene, 0, 0, [], w, h, this.unitCategory));
        this.addText(w, h);
    }

    addText(w, h) {
        const text = `Медицинская капсула - это одна из технологий самой развитой цивилизации алуун, по другому их называют древние. За такими технологиями всегда идет охота. Вам повезло вы нашли мед капсулы по дороге на планету в космосе на месте последней битвы человечества с расой подургов в разрушенном крейсере. Время выздоровления в медицинской капсуле зависит лишь от тяжести полученных повреждений. Чем тяжелее раны тем дольше время восстановления. Мутанты, облученные и киборги не восстанавливаются в мед капсулах.`;
        
        this.text = this.scene.add.text(0, 0, '').setOrigin(0, 0).setStyle({
            fontFamily: 'Game_Font',
            fontSize: h * 0.02,
            color: '#ffedbb',
            shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 },
            align: 'justify',
            wordWrap: { width: w * 0.66 },
        }).setLineSpacing(h * 0.02);

        const width = w * 0.66;
        const height = h * 0.155; // 0.342

        this.add(this.contentText = this.scene.rexUI.add.textArea({
            x: 0,
            y: h * 0.16 + height / 2, // -0.032
            width: width,
            height: height,
            origin: 0,

            // background: this.scene.rexUI.add.roundRectangle(0, 0, 0, 0, 0, 0x000000, 0.5),
            text: this.text,
            content: text,

            mouseWheelScroller: {
                focus: true,
                speed: 0.5,
            },

            space: {
                text: {
                    top: h * 0.04,
                    bottom: h * 0.02
                }
            }
        }).layout());
    }

    sortUnits(data) {
        const category = this.unitCategory;
        const injured = [];
        const sorted = [];
        let categoriesToSelect = [];

        if (category == 'characters') {
            categoriesToSelect = ['character', 'commander'];
        } else {
            categoriesToSelect = ['soldier'];
        }

        for (let id in data) {
            let unit = data[id];
            if (unit['health'] < unit['max_health']) {
                injured.push(unit);
            }
        }

        injured.forEach(unit => {
            if (categoriesToSelect.includes(unit['category'])) {
                if (!['Chlapidol', 'Shtryzik'].includes(unit['name'])) {
                    sorted.push(unit);
                }
            }
        });

        return sorted;
    }

    async selectUnit(unit, cell) {
        const building = this.scene.game.registry.get('curBuilding');
        
        const data = await apiClient.processes.start({
            category: 'healing',
            name: 'unit_' + unit.unitData['id'],
            building_name: building.texture
        });
        console.log(data);

        this.addUnitToCell(unit, cell, data['data']);
    }

    addUnitToCell(unit, cell, data) {
        const id = this.units.indexOf(unit.unitData);
        this.units.splice(id, 1);

        this.cellContainer.addUnitToCell(unit);

        cell.startHealing(data);
        this.updateMainScroll(this.units);
    }

    updateMainScroll(data) {
        this.mainScroll.updateContent(data);
    }

    checkHealingProcessExists(processes) {
        this.mainScroll.getAll().forEach(unit => {
            const process = processes['unit_' + unit.unitData['id']];

            if (process) {
                if (process['finished'] == true) {
                    console.log('process finished by update');
                } else {
                    const freeCell = this.cellContainer.findFreeCell();
                    if (freeCell) {
                        this.addUnitToCell(unit, freeCell, process);
                    }
                }
            }
        });
    }
}