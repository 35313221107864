import { getRandomArrayElement } from "../../../../../utils/utils";
import BattleUnit from "../BattleUnit";

export default class BattlePichmog extends BattleUnit {

    create(unitData, linkedIcon) {
        const size = getRandomArrayElement([1, 2, 3, 4, 5]);

        unitData['xp_cost'] = size * 5;
        unitData['size'] = 0.08 + (size - 1) * 0.02;
        unitData['health'] = size * 500;
        unitData['max_health'] = size * 500;
        unitData['armor'] = 50 + (size - 1) * 10;
        unitData['damage'] = 100 + (size - 1) * 50;

        super.create(unitData, linkedIcon);
        this.getData('config')['XP_cost'] = unitData['xp_cost'];
    }

    rest() {
        this.setState('idle');
        // this.playAnimation(['wake']);
    }

    checkForThreat() {
        this.findTarget();
    }
}