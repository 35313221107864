import achievementData from "../../../../../../config/player/achievementData";
import { ScrollManager } from "../../../../../../managers/UIController";
import ProductionCell from "../../production/components/ProductionCell";
import AchievementCell from "./AchievementCell";


export default class AchievementScroll extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.tw = tw;
        this.th = th;

        this.addContent(tw, th);
    }

    addContent(tw, th) {
        const scrollW = tw * 0.32;
        const scrollH = th * 0.62;
        const spaceTop = th * 0.02;

        this.scroll = new ScrollManager(this.scene, {
            x: tw * 0.17,
            y: 0,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                top: spaceTop,
                bottom: spaceTop,
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
        this.addItemsToScroll();
    }

    addItemsToScroll() {
        for (let id in achievementData) {
            let d = achievementData[id];

            let cell = new AchievementCell(this.scene, 0, 0, [], this.tw, this.th, d['name'], d['category'], id - 1);
            this.scroll.addItem(cell);
        }
    }

    updateContent(data) {
        for (let item of this.scroll.items) {
            item.updateContent(data['achievements'][item.id]);
        }
    }

    getAchievementCount() {
        let count = 0;

        for (let item of this.scroll.items) {
            count += item.isAvailable();
        }

        return count;
    }
}