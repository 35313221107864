import InfoTables from "../../../scenes/ui/InfoTables";
import apiClient from "../../../services/apiClient";
import ActionButton from "../../main/buttons/common/ActionButton";
import FixMachineTable from "../link_tables/FixMachineTable";
import MapObject from "./MapObject";

export default class MapMachine extends MapObject {
    constructor(scene, x, y, children, texture, width, height, data) {
        super(scene, x, y, children, texture, width, height, data);

        this.machineData = data;
        this.name = data['name'];

        this.working = false;

        if (this.machineData['status'] != 'broken') {
            this.addInfo(this.obj.displayWidth, this.obj.displayHeight);
            this.work();
        }
    }

    async checkAvailable() {
        let name = `${this.machineData['scene']}_${this.name}_${this.machineData['cellNumber']}`;

        if (this.name == 'builder') {
            name = 'builder';
        }

        const data = await apiClient.buildings.checkAvailable({
            scene: this.scene.scene.key,
            name: name
        });

        if (data['status'] != 'success') {
            return;
        }
        return data['available'];
    }

    async click() {
        const isAvailable = await this.checkAvailable();

        if (!isAvailable) {
            InfoTables.showWarningText('Уничтожьте врагов поблизости');
            return;
        }

        this.scene.game.registry.set('curMachine', this);

        if (this.machineData['status'] == 'broken') {
            this.openFixTable();

            this.scene.input.on('pointerdown', () => {
                if (this.tableFix) this.destroyFixTable();
            });
        } else {
            InfoTables.openInfoTable('production');
        }

        super.click();
    }

    create() {
        super.create();
        this.obj.setDisplaySize(window.screen.height * 2 * this.width, window.screen.height * 2 * this.height);
    }

    addInfo(w, h) {
        const size = window.screen.height * 0.03;
        const x = this.obj.x + w / 2;
        const y = this.obj.y + w * 0.2;
        const sh = window.screen.height;

        this.add(this.machineLink = this.scene.add.image(x, y, 'UI', 'process_link.png')
            .setDisplaySize(sh * 0.09, sh * 0.09).setOrigin(0, 1).setTintFill(0xFFDB79));

        const buttonSize = this.machineLink.displayHeight * 0.55;
        const buttonX = this.machineLink.x + this.machineLink.displayHeight * 0.91;
        const buttonY = this.machineLink.y - this.machineLink.displayHeight * 1.09;

        this.add(this.hex = this.scene.add.image(buttonX, buttonY, 'UI', 'hex.png').setDisplaySize(buttonSize, buttonSize));
        this.add(this.button = new ActionButton(this.scene, buttonX, buttonY, [], 'table_button', buttonSize, buttonSize, 'green', this.collectOre.bind(this)));

        this.add(this.oreIcon = this.scene.add.image(buttonX, buttonY, 'items_icons', this.machineData['ore'] + '.png').setDisplaySize(size, size));
    }

    work() {
        this.oreIcon.setFrame(this.machineData['ore'] + '.png');
        this.obj.anims.play(`${this.texture.slice(0, this.texture.length - 6)}_${this.machineData['anim']}`);

        if (this.machineData['status'] != 'active') {
            this.stop();
        }
    }

    stop() {
        this.obj.anims.stop();
        this.oreIcon.setFrame('production.png');
        this.button.blink(0xffedbb);
    }

    async collectOre() {
        const isAvailable = await this.checkAvailable();

        if (!isAvailable) {
            InfoTables.showWarningText('Уничтожьте врагов поблизости');
            return;
        }

        if (this.machineData['status'] != 'active') {
            const data = await apiClient.machines.start({
                id: this.machineData['id'],
            });

            if (data['status'] != 'success') {
                return;
            }

            this.machineData['status'] = data['machine']['status'];
            this.work();
            return;
        }

        const data = await apiClient.machines.collectOre({
            id: this.machineData['id'],
            ore: this.machineData['ore']
        });

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(data['message']);
        this.scene.game.registry.set('items', data['items']);
    }

    openFixTable() {
        const w = this.obj.displayHeight;
        const x = this.obj.x + w / 2;
        const y = this.obj.y + w * 0.2;

        this.add(this.tableFix = new FixMachineTable(this.scene, x, y, [], this));
    }

    destroyFixTable() {
        this.tableFix && this.tableFix.destroy();
        this.tableFix = null;
        this.scene.map.handleMoves();
    }

    fix() {
        if (this.name == 'builder' || this.machineData['status'] == 'inactive') {
            this.destroy();
        } else {
            this.addInfo(this.obj.displayWidth, this.obj.displayHeight);
            this.work();
        }
    }
}