import SceneManager from '../../../managers/SceneManager.js';
import { TEXT_STYLE_BLUE } from '../../../utils/textStyles.js';
import InteractiveObject from '../../main/InteractiveObject.js';

class JournalButton extends InteractiveObject {
    constructor(scene, x, y, children, texture, width, height, color) {
        super(scene, x, y, children, texture, width, height);

        this.color = (color == 'blue') ? 0xa9e8ff : color;
    }

    create() {
        super.create();
        this.obj.setTexture('journal_UI').setFrame(`${this.texture}.png`);
        this.obj.setDisplaySize(this.width, this.height);

        this.add(this.bg = this.scene.add.sprite(0, 0, 'journal_UI', `${this.texture}_disabled.png`)
            .setOrigin(0, 0).setDisplaySize(this.width, this.height).setAlpha(0));

        this.addTriangle();
        this.glowPlugin = this.scene.plugins.get('rexGlowFilterPipeline');

        this.disable();
        this.makeInteractive();
    }

    addTriangle() {
        this.add(this.triangle = this.scene.add.sprite(0, 0, 'UI', 'triangle.png').setTintFill(0x18ff00).setAlpha(0));
    }

    resize(w, h) {
        this.obj.setDisplaySize(w, h);
        this.bg.setDisplaySize(w, h);
        this.triangle.setPosition(h * 0.27, h * 0.81).setDisplaySize(h * 0.2, h * 0.2);
    }

    pointerOver() {
        this.obj.setTintFill(this.color);
        if (this.selected) return;
        this.triangle.setAlpha(1).setTintFill(0xffedbb);
    }

    pointerOut() {
        this.obj.clearTint();
        if (this.selected) return;
        this.triangle.setAlpha(0);
    }

    click() {
        super.click();
        this.deselect();
    }

    select() {
        this.triangle.setAlpha(1).setTintFill(0x18ff00); // 0xff2d00 - red
        this.selected = true;
    }

    deselect() {
        this.triangle.setAlpha(0);
        this.bg.setAlpha(0);
        this.selected = false;
    }

    makeInteractive() {
        super.makeInteractive();
        this.setAlpha(1);
    }

    disable() {
        super.disable();
        this.blinkOff();
        this.setAlpha(0.5);
    }

    blinkOff() {
        this.removeEffects();
        this.makeInteractive();
    }

    removeEffects() {
        this.blinkFX?.remove();
        this.bgBlinkFX?.remove();
        this.bg.setAlpha(0);
    }

    blink() {
        this.removeEffects();
        this.triangle.setAlpha(0);
        this.bg.setAlpha(0);

        this.blinkFX = this.scene.tweens.add({
            targets: this.triangle,
            alpha: 1,
            duration: 700,
            ease: 'Power1',
            repeat: -1,
            yoyo: true,
        });

        this.bgBlinkFX = this.scene.tweens.add({
            targets: this.bg,
            alpha: 0.3,
            duration: 700,
            ease: 'Power1',
            repeat: -1,
            yoyo: true,
        });
    }
}


export class ButtonCloseJournal extends JournalButton {

    create() {
        super.create();
        this.obj.setOrigin(1, 0);
        this.bg.setOrigin(1, 0);
    }

    pointerOver() {
        super.pointerOver();
        this.triangle.setAlpha(0);
    }

    click() {
        super.click();
        SceneManager.loadScene(this.scene, SceneManager.curScene);
    }

    resize(w, h) {
        super.resize(w, h);
        this.triangle.setPosition(h * -0.27, h * 0.82);
    }
}


export class ButtonToggleJournal extends JournalButton {
    click() {
        super.click();
        this.scene.toggleTabs();
    }
}


export class ButtonClaimJournal extends JournalButton {

    create() {
        super.create();
        this.disable();
    }

    setClickCallback(callback) {
        this.off('click').on('click', callback);
    }

    disable() {
        this.blinkOff();
        this.glowPlugin.remove(this.obj);
        this.obj.disableInteractive();
        this.obj.setTint(0x777777);
    }

    enable() {
        this.obj.setInteractive();
        this.obj.clearTint();
    }
}


export class ButtonFullScreen extends InteractiveObject {

    create() {
        super.create();
        this.obj.setTexture('UI').setFrame(`${this.texture}.png`);
        this.obj.setDisplaySize(this.width, this.height);
    }

    pointerOver() {
        super.pointerOver();
        this.obj.setTint(0xbdbdbd);
    }

    pointerOut() {
        super.pointerOut()
        this.obj.clearTint();
    }

    click() {
        super.click();

        if (this.scene.scale.isFullscreen) {
            this.scene.scale.stopFullscreen();
        } else {
            this.scene.scale.startFullscreen();
        }
    }

    resize(w, h) {
        this.obj.setDisplaySize(w, h);
    }
}


export class JournalTab extends JournalButton {
    constructor(scene, x, y, children, texture, width, height, color, title, available = true) {
        super(scene, x, y, children, texture, width, height, color);
        scene.add.existing(this);

        this.title = title;
        this.available = available;
        if (!available) {
            this.disable();
        }

        this.addTitle();
        this.obj.setTint(this.color);
    }

    resize(w, h) {
        super.resize(w, h);
        this.text.setFontSize(h * 0.5).setPosition(w / 2, h * 0.48);
    }

    pointerOut() {
        super.pointerOut();
        this.obj.setTint(this.color);
    }

    click() {
        super.click();
        this.scene.displayCategoryInfo(this.title, this);
    }

    addTitle() {
        this.add(this.text = this.scene.add.text(0, 0, this.title).setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_BLUE));
    }

}