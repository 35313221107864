import { ScrollManager } from "../../../../../../managers/UIController";
import MailCell from "./MailCell";


export default class MailScroll extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.tw = tw;
        this.th = th;

        this.addContent(tw, th);
    }

    addContent(tw, th) {
        const scrollW = tw * 0.32;
        const scrollH = th * 0.62;
        const spaceTop = th * 0.02;

        this.scroll = new ScrollManager(this.scene, {
            x: tw * -0.17,
            y: 0,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                top: spaceTop,
                bottom: spaceTop,
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
    }

    addItemsToScroll(data) {
        for (let id in data) {
            let cell = new MailCell(this.scene, 0, 0, [], this.tw, this.th, data[id]);
            this.scroll.addItem(cell);
        }
    }

    update(data) {
        this.scroll.removeItems();
        this.addItemsToScroll(data);
    }
}