import { TEXT_STYLE_BLUE } from '../../../../../utils/textStyles.js';
import InfoTableContainer from './InfoTableContainer.js';


export default class BuildingTableContainer extends InfoTableContainer {
    create() {
        super.create();

        this.add(this.levelText = this.scene.add.text(this.w * -0.32, this.h * -0.423, '-').setOrigin(0.5, 0.5));
        this.levelText.setStyle(TEXT_STYLE_BLUE).setFontSize(this.h * 0.03);
    }

    async open() {
        await super.open();
        this.updateInfo();
    }

    updateInfo(buildingLevel) {
        this.buildingLevel = buildingLevel ?? this.scene.game.registry.get('curBuilding').level;
        this.levelText.setText(this.buildingLevel);
    }

    showButtons() {
        super.showButtons();
        this.bringToTop(this.levelText);
    }
}