import loadAssets from "../../../../managers/AssetManager";
import InfoTables from "../../../../scenes/ui/InfoTables";
import apiClient from "../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE } from "../../../../utils/textStyles";

export default class JournalStoryInfo extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children) {
        super(scene, x, y, children);
        this.scene.add.existing(this);

        this.w = window.screen.height * 0.25 * 4.4;
        this.h = window.screen.height * 0.25;
        this.create(this.w, this.h);
        this.category = 'story';
    }

    create(w, h) {
        this.add(this.photo = this.scene.add.image(w * 0.0086, h * 0.038, 'story_pic_1').setOrigin(0, 0));
        this.add(this.cell = this.scene.add.image(0, 0, 'journal_story_info').setOrigin(0, 0).setDisplaySize(w, h));

        // Размер шрифта
        const nameFS = window.screen.height * 0.02;
        const statFS = window.screen.height * 0.017;

        // Описание
        this.add(this.name = this.scene.add.text(w * 0.75, h * 0.17, '').setOrigin(0.5, 1).setStyle(TEXT_STYLE_GREEN).setFontSize(nameFS));
        this.add(this.description = this.scene.add.text(w * 0.55, h * 0.27, '').setOrigin(0, 0).setStyle({
            fontFamily: 'Game_Font',
            fontSize: statFS,
            color: '#ffedbb',
            shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 },
            align: 'justify',
            lineSpacing: h * 0.1,
            wordWrap: { width: w * 0.43 },
        }).setLineSpacing(h * 0.03));

        // Сюжет
        this.addStoryText(w, h);

        // Rewards
        let x = window.innerWidth - window.innerHeight * 0.45;
        let y = window.innerHeight * 0.95;

        this.rewards = this.scene.add.container(x, y, []);

        this.scene.scale.on('resize', () => {
            x = window.innerWidth - window.innerHeight * 0.45;
            y = window.innerHeight * 0.95;

            this.rewards.setPosition(x, y);
        });

        // Loading
        const size = window.screen.height * 0.05;
        this.add(this.loading = this.scene.add.sprite(this.h * 2.25 / 2, this.h * 0.92 / 2, 'loading').setDisplaySize(size, size));

        this.showLoading();
    }

    addStoryText(w, h) {
        const statFS = window.screen.height * 0.017;

        this.story = this.scene.add.text(0, 0, '').setOrigin(0, 0).setStyle({
            fontFamily: 'Game_Font',
            fontSize: statFS,
            color: '#bbfcff',
            shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 },
            align: 'justify',
            lineSpacing: h * 0.1,
            wordWrap: { width: w * 0.98 },
        }).setLineSpacing(h * 0.04);
        
        const width = w * 0.98;
        const height = window.screen.height * 0.4;

        this.add(this.storyText = this.scene.rexUI.add.textArea({
            x: w * 0.02 + width / 2,
            y: h * 1.1 + height / 2,
            width: width,
            height: height,
            origin: 0,

            // background: this.scene.rexUI.add.roundRectangle(0, 0, 0, 0, 0, 0x000000, 0.5),
            text: this.story,
            content: '',

            mouseWheelScroller: {
                focus: true,
                speed: 0.5,
            },

            space: {
                bottom: height * 0.02,
            }
        }).layout());
    }

    displayAwards(rewards) {
        this.hideAwards();
        this.rewards.list[0].destroy();
        const h = window.screen.height;
        let dist = 0;

        rewards.forEach(item => {
            const isContainer = item['name'].includes('container') && !item['name'].includes('key_container');
            const texture = isContainer ? 'container_icons' : 'items_icons';
            const size = isContainer ? h * 0.06 : h * 0.03;

            this.rewards.add(this.scene.add.sprite(dist, 0, texture, `${item['name']}.png`).setDisplaySize(size, size).setOrigin(1, 0.5));
            this.rewards.add(this.scene.add.text(h * 0.007 + dist, 0, item['amount'])
                .setOrigin(0, 0.4).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.02));

            dist -= h * 0.09;
        });
    }

    hideAwards() {
        const h = window.screen.height;

        if (this.rewards.list.length > 0) {
            this.rewards.each(reward => reward.destroy());
        }
        this.rewards.add(this.scene.add.text(h * 0.05, 0, 'Награды собраны')
            .setOrigin(1, 0).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.02));
    }

    showLoading() {
        this.photo.setVisible(false);
        this.loading.anims.play('loading');
        this.loading.setVisible(true);
    }

    hideLoading() {
        this.photo.setVisible(true);
        this.loading.anims.stop();
        this.loading.setVisible(false);
    }

    async displayCurrentInfo(name, missionID, isComplete, cell) {
        this.showLoading();

        this.curCell = cell;
        this.curMissionId = missionID;
        const button = this.scene.btnClaim;
        const data = this.scene.journalData['data']['story'][missionID];

        // Update text
        this.name.setText(data['title']);
        this.description.setText(data['description']);
        this.story.setText(data['story']);

        this.storyText.setText(data['story']);
        this.storyText.scrollToTop();

        // Image update
        await this.loadData(missionID);
        this.photo.setTexture(`story_image_${missionID}`).setDisplaySize(this.h * 2.25, this.h * 0.92);
        this.hideLoading();

        // Awards visibility
        if (this.scene.journalData['story'][missionID - 1]['status'] != 'claimed') {
            this.displayAwards(data['rewards']);
        } else {
            this.hideAwards();
        }

        // Is complete
        button.disable();
        if (isComplete) {
            button.enable();
            button.blink();
            button.setClickCallback(this.claimRewards.bind(this));
        }
    }

    async claimRewards() {
        const data = await apiClient.missions.claimRewards({ mission_id: this.curMissionId });

        if (data['status'] != 'success') {
            return;
        }

        this.scene.journalData = data['data'];

        InfoTables.showWarningText('Награды собраны');
        this.scene.game.registry.set('items', data['items']);
        this.curCell.claim();
        this.scene.btnClaim.disable();
        this.hideAwards();
    }

    async loadData(missionID) {
        const texture = `story_image_${missionID}`;
        await loadAssets(this.scene, 'image', texture, `UI/journal/tabs/${this.category}/images/${missionID}.png`);
    }
}