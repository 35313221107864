import AudioManager from "../../../../../managers/AudioManager";
import BattleUnit from "../BattleUnit";

export default class BattleZazulic extends BattleUnit {

    create(unitData, linkedIcon) {
        super.create(unitData, linkedIcon);
        this.getData('config')['XP_cost'] = unitData['xp_cost'];
    }

    rest() {
        this.setState('idle');
        this.playAnimation(['huff', 'dig']);
    }

    shoot(x, y, dir, target) {
        AudioManager.playSound('attack', 'zazulic');
    }

    die() {
        super.die();
        AudioManager.playSound('death', 'zazulic');
    }
}