import UIController from "../../../../../managers/UIController";
import InfoTables from "../../../../../scenes/ui/InfoTables";
import { updateUI } from "../../../../../scenes/ui/UI";
import apiClient from "../../../../../services/apiClient";
import { TEXT_STYLE_ORANGE } from "../../../../../utils/textStyles";
import ActionButton from "../../../../main/buttons/common/ActionButton";
import SingleInfoTable from "../common/SingleInfoTable";
import MailScroll from "./components/MailScroll";

export default class MailTable extends SingleInfoTable {

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        this.add(this.mailScroll = new MailScroll(this.scene, 0, 0, [], w, h));

        this.add(this.title = this.scene.add.text(w * 0.17, h * -0.29, 'Выберите письмо').setOrigin(0.5, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.022));

        this.addMailText(w, h);

        this.add(this.rewardText = this.scene.add.text(w * 0.11, h * 0.23, 'Награды собраны').setOrigin(0.5, 1).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.022).setVisible(false));
        this.add(this.rewardContainer = this.scene.add.container(0, 0, []));

        this.add(this.claimButton = new ActionButton(this.scene, w * 0.27, h * 0.238, [], 'shop_button', h * 0.205, h * 0.205 * 0.216, 'green', this.claimRewards.bind(this)));
        this.claimButton.disable();
    }

    addMailText(w, h) {
        const text = 'Выберите письмо, которое хотите прочитать';

        this.mail = this.scene.add.text(0, 0, '').setOrigin(0, 0).setStyle({
            fontFamily: 'Game_Font',
            fontSize: h * 0.02,
            color: '#bbfcff',
            shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 },
            align: 'justify',
            wordWrap: { width: w * 0.318 },
        }).setLineSpacing(h * 0.02);

        const width = w * 0.318;
        const height = h * 0.47;

        this.add(this.contentText = this.scene.rexUI.add.textArea({
            x: w * 0.01 + width / 2,
            y: h * -0.28 + height / 2,
            width: width,
            height: height,
            origin: 0,

            // background: this.scene.rexUI.add.roundRectangle(0, 0, 0, 0, 0, 0x000000, 0.5),
            text: this.mail,
            content: text,

            mouseWheelScroller: {
                focus: true,
                speed: 0.5,
            },

            space: {
                bottom: height * 0.05,
                text: {
                    top: height * 0.07
                }
            }
        }).layout());
    }

    async update(category) {
        await super.update(category);
        await this.getMails();
    }

    async getMails() {
        const data = await apiClient.mails.getData();

        if (data['status'] != 'success') {
            return;
        }

        this.mailScroll.update(data['data']['mails']);
    }

    updateInfo(mail) {
        this.curMail = mail;
        const data = mail.mailData;
        const w = this.width;
        const h = this.height;

        this.title.setText(data['subject']);
        this.contentText.setText(data['content']);
        this.contentText.scrollToTop();

        if (data['is_collected']) {
            this.claimButton.disable();
            this.rewardContainer.removeAll(true);
            this.rewardText.setVisible(true);
            return;
        }

        this.claimButton.makeInteractive();
        this.rewardContainer.removeAll(true);

        UIController.arrangeItems({
            container: this.rewardContainer,
            itemsData: data['rewards'],
            x: w * 0.04,
            y: h * 0.21,
            iconSize: h * 0.035,
            fontSize: h * 0.022,
            space: w * 0.05,
        });
    }

    async claimRewards() {
        const data = await apiClient.mails.claimRewards({
            id: this.curMail.mailData['id']
        });

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText('Награды собраны');
        this.scene.game.registry.set('items', data['items']);
        updateUI(this.scene);

        this.curMail.mailData['is_collected'] = true;
        
        this.curMail.update();
        this.updateInfo(this.curMail);
    }
}