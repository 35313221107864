import AudioManager from "../../../../managers/AudioManager";
import InteractiveObject from "../../InteractiveObject";

export default class Button extends InteractiveObject {
    constructor(scene, x, y, children, texture, width, height, color) {
        super(scene, x, y, children, texture, width, height);

        this.color = color;
        if (!this.disabled) this.setColor(this.color);
    }

    create() {
        super.create();
        this.obj.setTexture('UI').setFrame(`${this.texture}.png`);
        this.obj.setDisplaySize(this.width, this.height);
        this.obj.setOrigin(0.5, 0.5);
    }

    makeInteractive() {
        !this.selected && this.obj.setAlpha(0.001);
        this.setColor(this.color);

        this.obj.setTexture('UI').setFrame(`${this.texture}.png`);
        this.obj.setDisplaySize(this.width, this.height);

        super.makeInteractive();
    }

    pointerOver() {
        this.obj.setAlpha(1);
        super.pointerOver();
    }

    pointerOut() {
        if (!this.selected) this.obj.setAlpha(0.001);
        super.pointerOut();
    }

    click(playSound = true) {
        super.click();
        this.blinkOff();
        if (!playSound) return;
        AudioManager.playSound('click', 'button');
    }

    blinkOff() {
        this.blinkFX && this.blinkFX.remove();
        this.makeInteractive();
    }

    blink(color = 0x18ff00) {
        this.blinkFX && this.blinkFX.remove();
        this.obj.setTint(color).setAlpha(0.001);

        this.blinkFX = this.scene.tweens.add({
            targets: this.obj,
            alpha: 1,
            duration: 700,
            ease: 'Power1',
            repeat: -1,
            yoyo: true,
        });
    }

    disable() {
        this.blinkOff();
        super.disable();

        this.obj.setTexture('UI').setFrame(`${this.texture}_disabled.png`).clearTint().setAlpha(0.5);
        this.obj.setDisplaySize(this.width, this.height);
    }

    setColor(color) {
        switch (color) {
            case 'green':
                this.obj.setTint(0x79ff84);
                break;
            case 'orange':
                this.obj.setTint(0xFFDB79);
                break;
            case 'red':
                this.obj.setTint(0xFF7878);
                break;
            case 'white':
                this.obj.clearTint();
                break;
            default:
                this.obj.setTint(0x00e2ff);
                break;
        }
    }

    select() {
        this.selected = true;
        this.obj.setAlpha(1);
    }

    deselect() {
        this.selected = false;
        this.obj.setAlpha(0.001);
    }
}