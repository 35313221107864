import UIController from "../../../../../managers/UIController";
import TimeManager from "../../../../../managers/TimeManager";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../utils/textStyles";
import { multiplyObjectValues } from "../../../../../utils/utils";
import UpgradeBuildingButton from "../../../../main/buttons/UpgradeBuildingButton";


export default class BuildingInfo extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this)

        this.scene = scene;
        this.tw = tw;
        this.th = th;

        this.addInfo(tw, th);
    }

    addInfo(w, h) {
        this.add(this.levelText = this.scene.add.text(w * -0.3175, h * -0.146, '-').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(w * 0.015));
        this.add(this.button = new UpgradeBuildingButton(this.scene, w * -0.066, h * 0.127, [], 'table_button', h * 0.069, h * 0.069, 'green'));

        let items = {
            'glass': 0,
            'iron_tile': 0,
            'iron_pipe': 0,
            'iron_bar': 0,
            'door': 0,
        };

        UIController.arrangeItems({
            container: this, 
            itemsData: items, 
            x: w * -0.295, 
            y: h * 0.031, 
            iconSize: h * 0.04,
            fontSize: h * 0.02,
            space: w * 0.055,
        });

        this.add(this.timeText = this.scene.add.text(w * -0.29, h * 0.06, '0').setOrigin(0, 0).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.027));
        this.addLocks(w, h);
    }

    addLocks(w, h) {
        const size = h * 0.04;
        const y = h * -0.24;

        this.add(this.scene.add.sprite(w * -0.372 + w * 0.192, y, 'UI', 'lock.png').setDisplaySize(size, size));
        this.add(this.scene.add.sprite(w * -0.372 + w * 0.262, y, 'UI', 'lock.png').setDisplaySize(size, size));
    }

    // Обновляем текст в зависимости от уровня
    updateContent(level) {
        this.levelText.setText(level);
        const building = this.scene.game.registry.get('curBuilding');

        if (!building) {
            return;
        }

        const materials = multiplyObjectValues(building.objectData['building_materials'], 1);
        materials['glass'] += (level - 1) * 2;
        materials['iron_tile'] += (level - 1) * 5;
        materials['iron_pipe'] += (level - 1) * 2;
        materials['iron_bar'] += (level - 1) * 2;
        materials['door'] += (level - 1) * 2;

        let items = this.scene.game.registry.get('items');
        UIController.updateItemsText(items, materials, this.itemsText);
        
        const buildingTime = building.objectData['building_time'] + level * 5 * 60;
        this.timeText.setText(TimeManager.formatFullTime(buildingTime));
    }

    updateTimer(remainingTime) {
        this.timeText.setText(TimeManager.formatFullTime(remainingTime));
    }
}