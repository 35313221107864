const containerData = {
    "container_batteries_1": {
        name: 'Маленький контейнер батарей',
        rewards: [
            { "name": "battery_units", "amount": '2-5' },
            { "name": "battery_robots", "amount": 1 },
            { "name": "battery_tech", "amount": 1 },
        ]
    },
    "container_batteries_2": {
        name: 'Средний контейнер батарей',
        rewards: [
            { "name": "battery_units", "amount": '3-7' },
            { "name": "battery_robots", "amount": 1 },
            { "name": "battery_tech", "amount": 1 },
            { "name": "battery_air", "amount": '1-3' },
        ],
    },
    "container_batteries_3": {
        name: 'Большой контейнер батарей',
        rewards: [
            { "name": "battery_units", "amount": '5-10' },
            { "name": "battery_robots", "amount": 1 },
            { "name": "battery_tech", "amount": '1-2' },
            { "name": "battery_air", "amount": '1-5' },
            { "name": "battery_military", "amount": 1 },
        ],
    },

    "container_crystalls_1": {
        name: 'Маленький контейнер кристаллов',
        rewards: [
            { "name": "crystall", "amount": '5-10' },
            { "name": "blue_crystall", "amount": '3-5' },
            { "name": "red_crystall", "amount": '1-3' },
        ],
    },
    "container_crystalls_2": {
        name: 'Средний контейнер кристаллов',
        rewards: [
            { "name": "crystall", "amount": '10-20' },
            { "name": "blue_crystall", "amount": '5-10' },
            { "name": "red_crystall", "amount": '3-5' },
            { "name": "yellow_crystall", "amount": '1-3' },
        ],
    },
    "container_crystalls_3": {
        name: 'Большой контейнер кристаллов',
        rewards: [
            { "name": "crystall", "amount": '15-30' },
            { "name": "blue_crystall", "amount": '7-15' },
            { "name": "red_crystall", "amount": '3-7' },
            { "name": "yellow_crystall", "amount": '2-5' },
            { "name": "purple_crystall", "amount": 1 },
        ],
    },

    "container_devices_1": {
        name: 'Маленький контейнер запчастей',
        rewards: [
            { "name": "machine_wheel", "min": 2, "max": 5 },
            { "name": "machine_arm", "min": 1, "max": 2 },
            { "name": "machine_bucket", "min": 1, "max": 1 },
        ],
    },
    "container_devices_2": {
        name: 'Средний контейнер запчастей',
        rewards: [
            { "name": "machine_wheel", "min": 4, "max": 7 },
            { "name": "machine_arm", "min": 2, "max": 3 },
            { "name": "machine_bucket", "min": 1, "max": 2 },
            { "name": "machine_claw", "min": 1, "max": 3 },
        ],
    },
    "container_devices_3": {
        name: 'Большой контейнер запчастей',
        rewards: [
            { "name": "machine_wheel", "min": 6, "max": 10 },
            { "name": "machine_arm", "min": 3, "max": 5 },
            { "name": "machine_bucket", "min": 2, "max": 3 },
            { "name": "machine_claw", "min": 3, "max": 5 },
            { "name": "machine_jack", "min": 1, "max": 1 },
            { "name": "machine_engine", "min": 1, "max": 1 },
        ],
    },

    "container_genetic_1": {
        name: 'Маленький контейнер генетики',
        rewards: [
            { "name": "injector_health", "min": 1, "max": 2 },
            { "name": "mutagen_health", "min": 1, "max": 2 },
            { "name": "blood", "min": 3, "max": 5 },
            { "name": "claw", "min": 1, "max": 3 },
        ],
    },
    "container_genetic_2": {
        name: 'Средний контейнер генетики',
        rewards: [
            { "name": "injector_health", "min": 1, "max": 3 },
            { "name": "injector_damage", "min": 1, "max": 2 },
            { "name": "mutagen_health", "min": 1, "max": 3 },
            { "name": "mutagen_damage", "min": 1, "max": 2 },
            { "name": "blood", "min": 5, "max": 7 },
            { "name": "claw", "min": 3, "max": 5 },
            { "name": "bone_brain", "min": 1, "max": 3 },
        ],
    },
    "container_genetic_3": {
        name: 'Большой контейнер генетики',
        rewards: [
            { "name": "injector_health", "min": 2, "max": 4 },
            { "name": "injector_damage", "min": 1, "max": 3 },
            { "name": "injector_accuracy", "min": 1, "max": 2 },
            { "name": "mutagen_health", "min": 2, "max": 4 },
            { "name": "mutagen_damage", "min": 1, "max": 3 },
            { "name": "mutagen_accuracy", "min": 1, "max": 2 },
            { "name": "blood", "min": 10, "max": 15 },
            { "name": "claw", "min": 5, "max": 10 },
            { "name": "bone_brain", "min": 3, "max": 5 },
            { "name": "eye", "min": 1, "max": 2 },
            { "name": "brain", "min": 1, "max": 1 },
        ],
    },

    "container_iron_1": {
        name: 'Маленький контейнер стали',
        rewards: [
            { "name": "steel", "amount": '5-10' },
            { "name": "iron_tile", "amount": '3-5' },
            { "name": "iron_pipe", "amount": '1-3' },
        ],
    },
    "container_iron_2": {
        name: 'Средний контейнер стали',
        rewards: [
            { "name": "steel", "amount": '10-20' },
            { "name": "iron_tile", "amount": '5-10' },
            { "name": "iron_pipe", "amount": '3-5' },
            { "name": "iron_bar", "amount": '1-3' },
        ],
    },
    "container_iron_3": {
        name: 'Большой контейнер стали',
        rewards: [
            { "name": "steel", "amount": '15-30' },
            { "name": "iron_tile", "amount": '7-15' },
            { "name": "iron_pipe", "amount": '3-7' },
            { "name": "iron_bar", "amount": '2-5' },
            { "name": "door", "amount": 1 },
            { "name": "iron_armor", "amount": 1 },
        ],
    },

    "container_keys_1": {
        name: 'Маленький контейнер ключей',
        rewards: [
            { "name": "key_container_batteries", "min": 1, "max": 2 },
            { "name": "key_container_crystalls", "min": 1, "max": 2 },
            { "name": "key_container_iron", "min": 1, "max": 2 },
            { "name": "key_container_silicon", "min": 1, "max": 2 },
            { "name": "key_container_titan", "min": 1, "max": 2 },
        ],
    },
    "container_keys_2": {
        name: 'Средний контейнер ключей',
        rewards: [
            { "name": "key_container_batteries", "min": 2, "max": 3 },
            { "name": "key_container_crystalls", "min": 2, "max": 3 },
            { "name": "key_container_iron", "min": 1, "max": 3 },
            { "name": "key_container_silicon", "min": 1, "max": 3 },
            { "name": "key_container_titan", "min": 1, "max": 3 },
            { "name": "key_container_modules", "min": 1, "max": 2 },
            { "name": "key_container_genetic", "min": 1, "max": 2 },
            { "name": "key_container_research", "min": 1, "max": 2 },
        ],
    },
    "container_keys_3": {
        name: 'Большой контейнер ключей',
        rewards: [
            { "name": "key_container_batteries", "min": 3, "max": 4 },
            { "name": "key_container_crystalls", "min": 2, "max": 4 },
            { "name": "key_container_iron", "min": 2, "max": 4 },
            { "name": "key_container_silicon", "min": 2, "max": 4 },
            { "name": "key_container_titan", "min": 2, "max": 4 },
            { "name": "key_container_modules", "min": 2, "max": 3 },
            { "name": "key_container_genetic", "min": 2, "max": 3 },
            { "name": "key_container_research", "min": 2, "max": 3 },
            { "name": "key_container_devices", "min": 1, "max": 2 },
            { "name": "key_container_robots", "min": 1, "max": 2 },
            { "name": "key_container_keys", "min": 1, "max": 2 },
        ],
    },

    "container_modules_1": {
        name: 'Маленький контейнер модулей',
        rewards: [
            { "name": "soldier_module_health_1", "min": 1, "max": 1 },
            { "name": "soldier_module_armor_1", "min": 1, "max": 1 },
            { "name": "soldier_module_damage_1", "min": 1, "max": 1 },
        ],
    },
    "container_modules_2": {
        name: 'Средний контейнер модулей',
        rewards: [
            { "name": "soldier_module_health_2", "min": 1, "max": 1 },
            { "name": "soldier_module_armor_2", "min": 1, "max": 1 },
            { "name": "soldier_module_damage_1", "min": 1, "max": 1 },
        ],
    },
    "container_modules_3": {
        name: 'Большой контейнер модулей',
        rewards: [
            { "name": "soldier_module_health_3", "min": 1, "max": 1 },
            { "name": "soldier_module_armor_3", "min": 1, "max": 1 },
            { "name": "soldier_module_damage_2", "min": 1, "max": 1 },
            { "name": "soldier_module_accuracy_1", "min": 1, "max": 1 },
        ],
    },

    "container_morion_1": {
        name: 'Контейнер мориона',
        rewards: [
            { "name": "morion", "amount": 30 },
        ],
    },
    "container_morion_2": {
        name: 'Контейнер мориона',
        rewards: [
            { "name": "morion", "amount": 100 },
        ],
    },
    "container_morion_3": {
        name: 'Контейнер мориона',
        rewards: [
            { "name": "morion", "amount": 250 },
        ],
    },
    "container_morion_4": {
        name: 'Контейнер мориона',
        rewards: [
            { "name": "morion", "amount": 500 },
        ],
    },
    "container_morion_5": {
        name: 'Контейнер мориона',
        rewards: [
            { "name": "morion", "amount": 750 },
        ],
    },
    "container_morion_6": {
        name: 'Контейнер мориона',
        rewards: [
            { "name": "morion", "amount": 1000 },
        ],
    },

    "container_research_1": {
        name: 'Маленький контейнер исследований',
        rewards: [
            { "name": "crystall_health", "min": 3, "max": 5 },
            { "name": "crystall_damage", "min": 2, "max": 4 },
            { "name": "energy_cell_blue", "min": 1, "max": 2 },
            { "name": "energy_cell_red", "min": 1, "max": 2 },
        ],
    },
    "container_research_2": {
        name: 'Средний контейнер исследований',
        rewards: [
            { "name": "crystall_health", "min": 4, "max": 6 },
            { "name": "crystall_damage", "min": 3, "max": 5 },
            { "name": "crystall_accuracy", "min": 1, "max": 3 },
            { "name": "energy_cell_blue", "min": 2, "max": 3 },
            { "name": "energy_cell_red", "min": 1, "max": 2 },
            { "name": "energy_cell_yellow", "min": 1, "max": 1 },
        ],
    },
    "container_research_3": {
        name: 'Большой контейнер исследований',
        rewards: [
            { "name": "crystall_health", "min": 5, "max": 7 },
            { "name": "crystall_damage", "min": 4, "max": 6 },
            { "name": "crystall_accuracy", "min": 2, "max": 4 },
            { "name": "energy_cell_blue", "min": 3, "max": 5 },
            { "name": "energy_cell_red", "min": 2, "max": 3 },
            { "name": "energy_cell_yellow", "min": 1, "max": 2 },
            { "name": "energy_cell_purple", "min": 1, "max": 1 },
            { "name": "energy_cell_brown", "min": 1, "max": 1 },
        ],
    },

    "container_robots_1": {
        name: 'Маленький контейнер роботов',
        rewards: [
            { "name": "chip", "amount": '5-10' },
            { "name": "processor", "amount": '3-5' },
            { "name": "ram", "amount": '1-3' },
        ],
    },
    "container_robots_2": {
        name: 'Средний контейнер роботов',
        rewards: [
            { "name": "chip", "amount": '10-20' },
            { "name": "processor", "amount": '5-10' },
            { "name": "ram", "amount": '3-5' },
            { "name": "robo_eye", "amount": '1-3' },
        ],
    },
    "container_robots_3": {
        name: 'Большой контейнер роботов',
        rewards: [
            { "name": "chip", "amount": '15-30' },
            { "name": "processor", "amount": '7-15' },
            { "name": "ram", "amount": '3-7' },
            { "name": "robo_eye", "amount": '2-5' },
            { "name": "robo_brain", "amount": 1 },
        ],
    },

    "container_silicon_1": {
        name: 'Маленький контейнер кремния',
        rewards: [
            { "name": "silicon", "amount": '5-10' },
            { "name": "silicon_plate", "amount": '3-5' },
            { "name": "glass", "amount": '1-3' },
        ],
    },
    "container_silicon_2": {
        name: 'Средний контейнер кремния',
        rewards: [
            { "name": "silicon", "amount": '10-20' },
            { "name": "silicon_plate", "amount": '5-10' },
            { "name": "glass", "amount": '3-5' },
            { "name": "silicon_glass", "amount": '1-3' },
        ],
    },
    "container_silicon_3": {
        name: 'Большой контейнер кремния',
        rewards: [
            { "name": "silicon", "amount": '15-30' },
            { "name": "silicon_plate", "amount": '7-15' },
            { "name": "glass", "amount": '3-7' },
            { "name": "silicon_glass", "amount": '2-5' },
            { "name": "lense", "amount": 1 },
            { "name": "prism", "amount": 1 },
        ],
    },

    "container_titan_1": {
        name: 'Маленький контейнер титана',
        rewards: [
            { "name": "titan", "amount": '5-10' },
            { "name": "titan_tile", "amount": '3-5' },
            { "name": "titan_armor", "amount": '1-3' },
        ],
    },
    "container_titan_2": {
        name: 'Средний контейнер титана',
        rewards: [
            { "name": "titan", "amount": '10-20' },
            { "name": "titan_tile", "amount": '5-10' },
            { "name": "titan_armor", "amount": '3-5' },
            { "name": "cutter", "amount": '1-3' },
        ],
    },
    "container_titan_3": {
        name: 'Большой контейнер титана',
        rewards: [
            { "name": "titan", "amount": '15-30' },
            { "name": "titan_tile", "amount": '7-15' },
            { "name": "cutter", "amount": '2-5' },
            { "name": "shaft", "amount": 1 },
            { "name": "turbine", "amount": 1 },
        ],
    },
};

export default containerData;