import loadAssets from "../../../../managers/AssetManager";
import InfoTables from "../../../../scenes/ui/InfoTables";
import apiClient from "../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE, TEXT_STYLE_RED } from "../../../../utils/textStyles";

export default class JournalAnimalInfo extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children) {
        super(scene, x, y, children);
        this.scene.add.existing(this);

        this.w = window.screen.height * 0.35 * 3.04;
        this.h = window.screen.height * 0.35;
        this.create(this.w, this.h);
        this.category = 'animals';
    }

    create(w, h) {
        this.add(this.photo = this.scene.add.image(w * 0.0136, h * 0.021, 'photo_zazulic').setOrigin(0, 0));
        this.add(this.frame = this.scene.add.image(0, 0, 'journal_animal_info').setOrigin(0, 0).setDisplaySize(w, h));

        // Размер шрифта
        const nameFS = window.screen.height * 0.02;
        const statFS = window.screen.height * 0.017;

        // Полное имя
        this.add(this.name = this.scene.add.text(w * 0.73, h * 0.1, '-').setOrigin(0.5, 1).setStyle(TEXT_STYLE_GREEN).setFontSize(nameFS));
        // Количество убитых юнитов
        this.add(this.killedText = this.scene.add.text(w * 0.11, h * 0.098, '-').setOrigin(0, 1).setStyle(TEXT_STYLE_RED).setFontSize(statFS));
        // Бонус к урону
        this.add(this.damageBonusText = this.scene.add.text(w * 0.028, h * 0.93, '1').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        // Параметры
        this.add(this.health = this.scene.add.text(w * 0.54, h * 0.21, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        this.add(this.armor = this.scene.add.text(w * 0.54, h * 0.335, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        this.add(this.damage = this.scene.add.text(w * 0.54, h * 0.46, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        this.add(this.accuracy = this.scene.add.text(w * 0.54, h * 0.585, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        this.add(this.speed = this.scene.add.text(w * 0.54, h * 0.71, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        this.add(this.range = this.scene.add.text(w * 0.54, h * 0.835, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        // Характеристика
        this.add(this.phylum = this.scene.add.text(w * 0.85, h * 0.20, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.class = this.scene.add.text(w * 0.85, h * 0.2925, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.order = this.scene.add.text(w * 0.85, h * 0.3850, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.genus = this.scene.add.text(w * 0.85, h * 0.4775, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.species = this.scene.add.text(w * 0.85, h * 0.5700, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.weight = this.scene.add.text(w * 0.85, h * 0.6625, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.areal = this.scene.add.text(w * 0.85, h * 0.7550, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        // Описание
        this.add(this.description = this.scene.add.text(w * 0.02, h * 1.1, '-').setOrigin(0, 0).setStyle({
            fontFamily: 'Game_Font',
            fontSize: statFS,
            color: '#bbfcff',
            shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 },
            align: 'justify',
            lineSpacing: h * 0.1,
            wordWrap: { width: w * 0.98 },
        }).setLineSpacing(h * 0.04));

        // Rewards
        let x = window.innerWidth - window.innerHeight * 0.45;
        let y = window.innerHeight * 0.95;

        this.rewards = this.scene.add.container(x, y, []);

        this.scene.scale.on('resize', () => {
            x = window.innerWidth - window.innerHeight * 0.45;
            y = window.innerHeight * 0.95;

            this.rewards.setPosition(x, y);
        });

        // Loading
        const size = window.screen.height * 0.05;
        this.add(this.loading = this.scene.add.sprite(this.h * 1.39 / 2, this.h * 0.907 / 2, 'loading').setDisplaySize(size, size));

        this.showLoading();
    }

    showLoading() {
        this.photo.setVisible(false);
        this.loading.anims.play('loading');
        this.loading.setVisible(true);
    }

    hideLoading() {
        this.photo.setVisible(true);
        this.loading.anims.stop();
        this.loading.setVisible(false);
    }

    displayAwards(rewards) {
        this.hideAwards();
        this.rewards.list[0].destroy();
        const h = window.screen.height;
        let dist = 0;

        rewards.forEach(item => {
            const isContainer = item['name'].includes('container') && !item['name'].includes('key_container');
            const texture = isContainer ? 'container_icons' : 'items_icons';
            const size = isContainer ? h * 0.06 : h * 0.03;

            this.rewards.add(this.scene.add.sprite(dist, 0, texture, `${item['name']}.png`).setDisplaySize(size, size).setOrigin(1, 0.5));
            this.rewards.add(this.scene.add.text(h * 0.007 + dist, 0, item['amount'])
                .setOrigin(0, 0.4).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.02));

            dist -= h * 0.09;
        });
    }

    hideAwards() {
        const h = window.screen.height;

        if (this.rewards.list.length > 0) {
            this.rewards.each(reward => reward.destroy());
        }
        this.rewards.add(this.scene.add.text(h * 0.05, 0, 'Награды собраны')
            .setOrigin(1, 0).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.02));
    }

    async displayCurrentInfo(name, id, isComplete, cell) {
        if (this.scene.curTab != 'монстры') {
            return;
        }

        this.showLoading();
        this.animalName = name;
        const data = this.scene.journalData['data']['animals'][name];
        const killed = this.scene.journalData['animals'][name]['killed'];
        const button = this.scene.btnClaim;

        this.killedText.setText(killed);
        this.name.setText(data['name']);
        // Параметры
        this.health.setText(data['health']);
        this.armor.setText(data['armor']);
        this.damage.setText(data['damage']);
        this.accuracy.setText(data['accuracy']);
        this.speed.setText(data['speed']);
        this.range.setText(data['range']);
        // Характеристика
        this.phylum.setText(data['phylum']);
        this.class.setText(data['class']);
        this.order.setText(data['order']);
        this.genus.setText(data['genus']);
        this.species.setText(data['species']);
        this.weight.setText(data['weight']);
        this.areal.setText(data['areal']);
        // Описание
        this.description.setText(data['description']);

        // Awards visibility
        if (this.scene.journalData['animals'][name]['status'] != 'claimed') {
            this.displayAwards(data['rewards']);
        } else {
            this.hideAwards();
        }

        await this.loadData(name);

        this.photo.setTexture(`photo_${name}`).setDisplaySize(this.h * 1.39, this.h * 0.907);
        this.hideLoading();

        // Is complete
        button.disable();
        if (isComplete) {
            button.enable();
            button.blink();
            button.setClickCallback(this.claimRewards.bind(this));
        }
    }

    async claimRewards() {
        const data = await apiClient.animals.claimRewards({ animal: this.animalName });

        if (data['status'] != 'success') {
            return;
        }

        this.scene.journalData = data['data'];

        InfoTables.showWarningText('Награды собраны');
        this.scene.btnClaim.disable();
        this.hideAwards();
    }

    async loadData(name) {
        const texture = `photo_${name}`;
        await loadAssets(this.scene, 'image', texture, `UI/journal/tabs/${this.category}/images/${name}.png`);
    }
}