export default class ArmyUnitsContainer extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, options = { flip: false, available: 2 }) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = width;
        this.th = height;
        this.options = options;
        this.locks = [];

        this.availableCells = this.options['available'];
        
        this.create();
    }

    create() {
        this.units = [];
        this.created = true;
    }

    /**
     * Make the specified amount of cells available for units.
     * @param {number} cells The amount of cells to set available.
     */
    setAvailableCells(cells) {
        this.availableCells = cells;
    }

    enoughSpace() {
        return this.units.length < this.availableCells;
    }

    add(child, unit = false) {
        super.add(child);
        if (!this.created) return;
        unit && this.units.push(child);
        this.update();
    }

    update() {
        let dist = 0;
        this.units.forEach(icon => {
            icon.setPosition(dist, 0);

            if (this.options['flip']) {
                if (!icon.flipped) icon.flip();
                dist -= this.tw * 0.0581;
            } else {
                dist += this.tw * 0.0581;
            }
        });
    }

    removeUnit(unit) {
        const id = this.units.indexOf(unit);
        this.units.splice(id, 1);
        this.remove(unit);
        unit.destroy();
        this.parentContainer.updateInfo();
    }

    clear() {
        this.units.forEach(icon => {
            this.remove(icon);
            icon.destroy();
        });
        this.units = [];
    }

    clearLocks() {
        this.locks.forEach(l => l.destroy());
    }
}