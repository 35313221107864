import InfoTables from '../../../../../scenes/ui/InfoTables.js';
import { InfoTableButton } from '../../../../main/buttons/InfoTableButton.js';


export default class InfoTableContainer extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, category, position) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.category = category;
        this.position = position;
        this.w = this.scene.tableW;
        this.h = this.scene.tableH;

        this.create();
    }

    create() {
        this.addDarkScreen();
        this.addInfoTables();
        this.addButtons(this.w, this.h);
        this.setVisible(false);
    }

    update() {
        this.tableLeft && this.tableLeft.update(this.category);
        this.tableCenter && this.tableCenter.update(this.category);
        this.tableRight && this.tableRight.update(this.category);
    }

    async open(position) {
        this.setVisible(true);
        this.scene.game.registry.set('opened_table', this);

        if (this.tableLeft) {
            this.tableLeft.update(this.category);
            this.hideTable(this.tableLeft);
        }
        if (this.tableCenter) {
            this.tableCenter.update(this.category);
            this.hideTable(this.tableCenter);
        }
        if (this.tableRight) {
            this.tableRight.update(this.category);
            this.hideTable(this.tableRight);
        }

        this.switchTables(position);
    }

    close() {
        this.setVisible(false);
        this.scene.game.registry.set('curBuilding', null);
    }

    rightButtonClick() {
        switch (this.curTable) {
            case this.tableCenter:
            case this.tableLeft:
                if (this.tableRight) {
                    this.hideTable(this.curTable);
                    this.curTable = this.tableRight;
                } else {
                    InfoTables.showWarningText('Будет доступно в одном из обновлений');
                }
                break;
            case this.tableRight:
                this.hideTable(this.curTable);
                this.curTable = this.tableCenter;
                break;
        }
        
        this.showTable(this.curTable);
        this.showButtons();
    }

    leftButtonClick() {

        switch (this.curTable) {
            case this.tableCenter:
            case this.tableRight:
                if (this.tableLeft) {
                    this.hideTable(this.curTable);
                    this.curTable = this.tableLeft;
                } else {
                    InfoTables.showWarningText('Будет доступно в одном из обновлений');
                }
                break;
            case this.tableLeft:
                this.hideTable(this.curTable);
                this.curTable = this.tableCenter;
                break;
        }

        this.showTable(this.curTable);
        this.showButtons()
    }

    addDarkScreen() {
        let w = window.screen.width;
        let h = window.screen.height;

        this.add(this.darkScreen = this.scene.add.rectangle(0, 0, w * 2, h * 2, 0x000000, 0.5));
        this.darkScreen.setInteractive();
    }

    addInfoTables(left, center, right) {
        if (left) { this.add(left); }
        if (center) { this.add(center); }
        if (right) { this.add(right); }
    }

    switchTables(position) {

        switch (position) {
            case 'left':
                this.curTable = this.tableLeft;
                break;
            case 'center':
                this.curTable = this.tableCenter;
                break;
            case 'right':
                this.curTable = this.tableRight;
                break;
            default:
                this.curTable = this.tableCenter;
                break;
        }

        this.showTable(this.curTable);
        this.showButtons();
    }

    addButtons(w, h) {
        this.add(this.exitButton = new InfoTableButton(this.scene, w * 0.322, h * -0.423, [], 'table_button', w * 0.055, w * 0.055, '', this, 'close'));
        this.add(this.rightButton = new InfoTableButton(this.scene, w * 0.425, h * -0.047, [], 'table_button', w * 0.077, w * 0.077, '', this, 'right'));
        this.add(this.leftButton = new InfoTableButton(this.scene, w * -0.427, h * -0.047, [], 'table_button', w * 0.077, w * 0.077, '', this, 'left'));
    }

    showButtons() {
        this.bringToTop(this.exitButton);
        this.bringToTop(this.rightButton);
        this.bringToTop(this.leftButton);
    }

    hideTable(table) {
        table.setVisible(false);
        table.emit('setvisible-false');
    }

    showTable(table) {
        table.setVisible(true);
        table.emit('setvisible-true');
        this.bringToTop(table);
    }
}
