import InfoTables from "../../../../../scenes/ui/InfoTables";
import UI, { updateUI } from "../../../../../scenes/ui/UI";
import apiClient from "../../../../../services/apiClient";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_ORANGE } from "../../../../../utils/textStyles";
import Button from "../../../../main/buttons/common/Button";
import SingleInfoTable from "../common/SingleInfoTable";
import AchievementScroll from "./components/AchievementScroll";

export default class ProfileTable extends SingleInfoTable {

    create() {
        super.create();
        const w = this.width;
        const h = this.height;

        this.add(this.level = this.scene.add.text(w * -0.308, h * -0.295, '-').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.03));
        this.add(this.username = this.scene.add.text(w * -0.28, h * -0.317, '-').setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.022));

        this.add(this.bar = this.scene.add.image(w * -0.2776, h * -0.31, 'UI', 'profile_bar.png')
            .setOrigin(0, 0).setDisplaySize(w * 0.2766, h * 0.0139).setCrop(0, 0, 0, 0));
        this.add(this.title = this.scene.add.text(w * 0.015, h * -0.317, 'Достижения:').setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.022));
        this.add(this.finishedText = this.scene.add.text(w * 0.12, h * -0.317, '100').setOrigin(0, 1).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.022));

        this.add(this.xpText = this.scene.add.text(w * -0.215, h * -0.255, '0').setOrigin(1, 1).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.022));
        this.add(this.maxXpText = this.scene.add.text(w * -0.2, h * -0.255, '0').setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.022));

        // Кнопка "Забрать награду"
        this.add(this.btnText = this.scene.add.text(w * -0.27, h * -0.081, 'Забрать').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.022));
        this.add(this.claimBtn = new Button(this.scene, w * -0.27, h * -0.081, [], 'shop_button', h * 0.205, h * 0.205 * 0.216, 'green'));
        this.claimBtn.disable();

        // Кнопка "Забрать награду" (платная)
        this.add(this.btnTextExtra = this.scene.add.text(w * -0.27, h * 0.238, '1000').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.022));
        this.add(this.morionIcon = this.scene.add.image(w * -0.29, h * 0.238, 'items_icons', 'morion.png').setOrigin(1, 0.5).setDisplaySize(h * 0.03, h * 0.03));

        this.add(this.claimExtraBtn = new Button(this.scene, w * -0.27, h * 0.238, [], 'shop_button', h * 0.205, h * 0.205 * 0.216, 'green'));
        this.claimExtraBtn.disable();

        this.mainRewardsAmount = [];
        this.extraRewardsAmount = [];
        // Основные награды
        this.mainRewards = ['morion', 'battery_units', 'medkit'];
        let dist = 0;
        this.mainRewards.forEach(item => {
            this.add(this.scene.add.image(w * -0.32 + dist, h * -0.115, 'items_icons', `${item}.png`).setOrigin(0, 1).setDisplaySize(h * 0.035, h * 0.035));
            let txt = this.scene.add.text(w * -0.29 + dist, h * -0.117, '99').setOrigin(0, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.022);

            this.mainRewardsAmount.push(txt);
            this.add(txt);

            dist += w * 0.07;
        });

        // Платные награды
        this.extraRewards = ['container_batteries_1', 'container_iron_1', 'container_silicon_1', 'container_titan_1'];
        dist = 0;
        this.extraRewards.forEach(item => {
            this.add(this.scene.add.image(w * -0.33 + dist, h * 0.17, 'container_icons', `${item}.png`).setOrigin(0, 0.5).setDisplaySize(h * 0.08, h * 0.08));
            let txt = this.scene.add.text(w * -0.28 + dist, h * 0.17, '99').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.022);

            this.extraRewardsAmount.push(txt);
            this.add(txt);

            dist += w * 0.08;
        });

        // Achievement scroll
        this.add(this.achievementScroll = new AchievementScroll(this.scene, 0, 0, [], w, h));
        this.handleButtonClick();

        this.addText(w, h);
        this.scene.events.on('profile-level-up', this.getProfileData.bind(this));
    }

    addText(w, h) {
        const fontSize = h * 0.022;

        this.add(this.scene.add.text(w * -0.317, h * -0.2, 'Награды за следующий уровень')
            .setOrigin(0, 0).setStyle(TEXT_STYLE_BLUE).setFontSize(fontSize));

        this.add(this.description = this.scene.add.text(w * -0.317, h * -0.03, 'Открой дополнительные награды и получай их за каждый новый уровень').setOrigin(0, 0).setStyle({
            fontFamily: 'Game_Font',
            fontSize: fontSize,
            color: '#bbfcff',
            shadow: { fill: true, offsetX: 0, offsetY: 2, blur: 0 },
            align: 'justify',
            wordWrap: { width: w * 0.3 },
        }).setLineSpacing(h * 0.004));
    }

    async update(category) {
        await super.update(category);
        await this.getProfileData();
    }

    async getProfileData() {
        const data = await apiClient.users.getUserData();
        const profile = data['profile'];
        const level = profile['level'];

        this.updateBar(profile['xp'], profile['max_xp']);

        this.username.setText(`${profile['first_name']} ${profile['last_name']}`);
        this.level.setText(level);
        this.xpText.setText(profile['xp']);
        this.maxXpText.setText(profile['max_xp']);
        this.achievementScroll.updateContent(data);

        updateUI(this.scene);
        
        if (profile['rewards_claimed'] < level) {
            this.claimBtn.makeInteractive();
            this.claimBtn.blink();
            this.updateRewardsText(level - 1);
        } else {
            this.updateRewardsText(level);
        }
    }

    updateBar(xp, maxXp) {
        const cropW = this.bar.width * (xp / maxXp);
        this.barCrop = this.barCrop || 0;
        const cropRect = new Phaser.Geom.Rectangle(0, 0, this.barCrop, this.bar.height);

        const tween = this.scene.tweens.add({
            targets: cropRect,
            width: cropW,
            duration: 500,
            ease: Phaser.Math.Easing.Sine.Out,
            onUpdate: () => {
                this.bar.setCrop(cropRect);
            },
            onComplete: () => {
                this.barCrop = cropW;
                tween.destroy();
            }
        });
    }

    updateRewardsText(level) {
        this.mainRewardsAmount[0].setText(2 * level);
        this.mainRewardsAmount[1].setText(2 * level);
        this.mainRewardsAmount[2].setText(2 * level);

        this.extraRewardsAmount[0].setText(level);
        this.extraRewardsAmount[1].setText(level);
        this.extraRewardsAmount[2].setText(level);
        this.extraRewardsAmount[3].setText(level);
    }

    handleButtonClick() {
        this.claimBtn.off('click').on('click', async () => {
            const data = await apiClient.profile.claimRewards();
            if (data['message']) {
                InfoTables.showWarningText(data['message']);
            } else {
                this.scene.game.registry.set('items', data['items']);
                updateUI(this.scene);
                this.getProfileData();
            }
            this.claimBtn.disable();
        });

        this.claimExtraBtn.off('click').on('click', async () => {
            const data = await apiClient.profile.claimExtraRewards();
            if (data['message']) {
                InfoTables.showWarningText(data['message']);
            } else {
                this.scene.game.registry.set('items', data['items']);
                updateUI(this.scene);
                this.getProfileData();
            }
            this.claimExtraBtn.disable();
        });
    }

    unlockExtraAwards() {
        this.btnTextExtra.setText('Забрать');
        this.morionIcon.destroy();
    }
}