import { setImageSizeByWidth } from "../../../../../utils/utils";

export default class BattleUnitHPBar extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, category, unitType) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.category = category;
        this.unitType = unitType;

        this.create();
    }

    create() {
        const screenH = window.screen.height;

        this.add(this.frame = this.scene.add.sprite(0, 0, 'UI', 'health_bar.png').setOrigin(0.25, 0.5)
            .setTint(0x000000).setDisplaySize(screenH * 0.04, screenH * 0.003));

        this.add(this.bar = this.scene.add.sprite(this.frame.displayWidth * -0.25, 0, 'UI', 'health_bar.png')
            .setOrigin(0, 0.5).setDisplaySize(screenH * 0.04, screenH * 0.003));

        this.setColor(this.unitType);
        this.addCategoryIcon(this.category);
    }

    setColor(unitType) {
        if (unitType == 'player') this.bar.setTint(0x00ff00); // Зеленый - юнит игрока
        else {
            console.log(this.category);
            switch (this.category) {
                case 'obstacle':
                    this.bar.setTint(0xffa400); // Оранжевый - препятствие (гнездо и тд.)
                    break;
                case 'crystall':
                    this.bar.setTint(0x00e8ff); // Оранжевый - препятствие (гнездо и тд.)
                    break;
                default:
                    this.bar.setTint(0xff3100); // Красный - юнит врага
                    break;
            }
        }
    }

    /**
     * Adds a small icon to the left side of the health bar
     * @param category Category of the unit/object: `character, soldier, obstacle` etc.
     */
    addCategoryIcon(category) {
        const size = window.screen.height * 0.025;
        const x = this.frame.displayWidth * -0.3;

        if (category == 'commander') {
            category = 'character';
        }

        this.add(this.categoryIcon = this.scene.add.sprite(x, 0, 'unit_category_icons', category + '.png')
            .setOrigin(1, 0.5).setDisplaySize(size, size));

        if (category == 'crystall') {
            this.categoryIcon.setTexture('items_icons', 'crystall.png').setDisplaySize(size, size);
        }
    }

    /**
     * Updates the fill width of the health bar
     * @param health Current health of the unit/object
     * @param maxHealth Max (total) health of the unit/object
     * @returns 
     */
    update(health, maxHealth) {
        let width = health / maxHealth * this.frame.displayWidth;

        if (!this.visible) return;

        const tween = this.scene.tweens.add({
            targets: this.bar,
            displayWidth: width,
            duration: 500,
            ease: Phaser.Math.Easing.Sine.Out,
            onUpdate: () => {
                if (~~this.bar.displayWidth <= 0 && health <= 0) {
                    this.setVisible(false);
                }
            },
            onComplete: () => {
                if (health <= 0) {
                    this.destroy();
                    tween.destroy();
                }
            }
        });
    }
}