export default class InteractiveObject extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, texture, width, height) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.startX = x;
        this.startY = y;
        this.x = x;
        this.y = y;
        this.texture = texture;
        this.width = width;
        this.height = height;

        this.create();
    }

    create(data) {
        this.add(this.obj = this.scene.add.sprite(0, 0, this.texture).setOrigin(0, 0));
        this.makeInteractive();
    }

    makeInteractive() {
        this.obj.setInteractive();
        this.obj.input.cursor = 'url(assets/UI/ui/cursors/pointer.png), pointer';

        this.obj.on('pointerover', this.pointerOver, this);
        this.obj.on('pointerout', this.pointerOut, this);

        this.obj.on('pointerdown', () => {
            this.obj.on('pointermove', this.pointerMove, this);
            this.obj.off('pointerup').on('pointerup', this.click, this);
        });

        this.disabled = false;
    }

    pointerOver() { this.emit('hover', this); }
    pointerOut() { this.emit('out', this); }

    click() {
        this.emit('click', this);
    }

    pointerMove() {
        this.obj.off('pointerup');
    }

    disable() {
        this.obj.disableInteractive();
        this.disabled = true;
    }

    resize(w, h) {
        this.obj.setDisplaySize(w, h);
        this.width = w;
        this.height = h;
    }
}