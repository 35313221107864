import OpenContainerTable from "../tables/shop/OpenContainerTable.js";
import ShopTable from "../tables/shop/ShopTable.js";
import InfoTableContainer from "./common/InfoTableContainer";


export default class ContainersTableContainer extends InfoTableContainer {

    addInfoTables() {
        this.tableLeft = new ShopTable(this.scene, 0, 0, [], this.w, this.h, 'shop_containers', 'containers');
        this.tableCenter = new OpenContainerTable(this.scene, 0, 0, [], this.w, this.h, 'containers');
        this.tableRight = new ShopTable(this.scene, 0, 0, [], this.w, this.h, 'shop_keys', 'keys');

        super.addInfoTables(this.tableLeft, this.tableCenter, this.tableRight);
    }
}