import { TEXT_STYLE_BLUE } from "../../../utils/textStyles";
import ObjectLink from "./ObjectLink";

export default class BuildingLink extends ObjectLink {
    constructor(scene, x, y, children, name, building) {
        super(scene, x, y, children, name);

        this.building = building;
    }

    create(w) {
        this.type = 'building';
        super.create(w);

        const lw = this.link.displayWidth;
        const lh = this.link.displayHeight;

        this.add(this.level = this.scene.add.text(lw * 0.203, lh * -0.68, '').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(w * 0.011));
        this.add(this.icon = this.scene.add.sprite(lw * 0.203, lh * -0.687, 'items_icons', 'production.png').setDisplaySize(lh * 0.2, lh * 0.2).setVisible(false));
    }

    show(level = 0) {
        super.show()
        this.level.setText(this.building.level.toString());
        this.update(level);
    }

    flip() {  
        super.flip();
        this.level.setPosition(this.link.displayWidth * -0.203, this.link.displayHeight * -0.68);
        this.icon.setPosition(this.link.displayWidth * -0.203, this.link.displayHeight * -0.687);
    }

    update(level) {
        if (level == 0) {
            this.level.setText('');
            this.icon.setVisible(true);
        } else {
            this.level.setText(level);
            this.icon.setVisible(false);
        }
    }
};