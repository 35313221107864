import InfoTables from "../../../../../../scenes/ui/InfoTables";
import eventBus from "../../../../../../services/eventBus";
import HealingCell from "./HealingCell";

export default class HealingCellContainer extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th, unitCategories) {
        super(scene, x, y, children);

        this.unitCategories = unitCategories;
        this.create(tw, th);
    }

    create(w, h) {
        this.cells = [
            new HealingCell(this.scene, w * -0.323, h * -0.147, [], w, h, 1),
            new HealingCell(this.scene, w * -0.071, h * -0.147, [], w, h, 2),
            new HealingCell(this.scene, w * 0.181, h * -0.147, [], w, h, 3),
        ];
        if (this.unitCategories == 'army') {
            this.cells.push(new HealingCell(this.scene, w * -0.192, h * 0.039, [], w, h, 4));
            this.cells.push(new HealingCell(this.scene, w * 0.06, h * 0.039, [], w, h, 5));
        }
        this.add(this.cells);
    }

    addUnitToCell(icon) {
        const freeCell = this.findFreeCell();
        if (!freeCell) {
            InfoTables.showWarningText('Нет свободных капсул');
            return;
        }

        freeCell.addUnit(icon);
    }

    findFreeCell() {
        return this.cells.find(cell => cell.isFree);
    }

    clearAll(buildingLevel) {
        this.cells.forEach(cell => cell.clear(buildingLevel));
    }
}