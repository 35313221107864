import loadAssets from "../../../../managers/AssetManager";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN, TEXT_STYLE_RED } from "../../../../utils/textStyles";

export default class JournalAnimalInfo extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children) {
        super(scene, x, y, children);
        this.scene.add.existing(this);

        this.w = window.screen.height * 0.35 * 3.04;
        this.h = window.screen.height * 0.35;
        this.create(this.w, this.h);
        this.category = 'animals';
    }

    create(w, h) {
        this.add(this.photo = this.scene.add.image(w * 0.0136, h * 0.021, 'photo_zazulic').setOrigin(0, 0));
        this.add(this.frame = this.scene.add.image(0, 0, 'journal_animal_info').setOrigin(0, 0).setDisplaySize(w, h));

        // Размер шрифта
        const nameFS = window.screen.height * 0.02;
        const statFS = window.screen.height * 0.017;

        // Полное имя
        this.add(this.name = this.scene.add.text(w * 0.73, h * 0.1, '-').setOrigin(0.5, 1).setStyle(TEXT_STYLE_GREEN).setFontSize(nameFS));
        // Количество убитых юнитов
        this.add(this.killedText = this.scene.add.text(w * 0.11, h * 0.098, '-').setOrigin(0, 1).setStyle(TEXT_STYLE_RED).setFontSize(statFS));
        // Бонус к урону
        this.add(this.damageBonusText = this.scene.add.text(w * 0.028, h * 0.93, '1').setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        // Параметры
        this.add(this.health = this.scene.add.text(w * 0.54, h * 0.21, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        this.add(this.armor = this.scene.add.text(w * 0.54, h * 0.335, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        this.add(this.damage = this.scene.add.text(w * 0.54, h * 0.46, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        this.add(this.accuracy = this.scene.add.text(w * 0.54, h * 0.585, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        this.add(this.speed = this.scene.add.text(w * 0.54, h * 0.71, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        this.add(this.range = this.scene.add.text(w * 0.54, h * 0.835, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_GREEN).setFontSize(statFS));
        // Характеристика
        this.add(this.phylum = this.scene.add.text(w * 0.85, h * 0.20, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.class = this.scene.add.text(w * 0.85, h * 0.2925, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.order = this.scene.add.text(w * 0.85, h * 0.3850, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.genus = this.scene.add.text(w * 0.85, h * 0.4775, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.species = this.scene.add.text(w * 0.85, h * 0.5700, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.weight = this.scene.add.text(w * 0.85, h * 0.6625, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        this.add(this.areal = this.scene.add.text(w * 0.85, h * 0.7550, '-').setOrigin(0, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(statFS));
        // Описание
        this.add(this.description = this.scene.add.text(w * 0.02, h * 1.1, '-').setOrigin(0, 0).setStyle({
            fontFamily: 'Game_Font',
            fontSize: statFS,
            color: '#bbfcff',
            shadow: {fill: true, offsetX: 0, offsetY: 2, blur: 0},
            align: 'justify',
            lineSpacing: h * 0.1,
            wordWrap: {width: w * 0.98},
        }).setLineSpacing(h * 0.04));

        // Loading
        const size = window.screen.height * 0.05;
        this.add(this.loading = this.scene.add.sprite(this.photo.displayWidth / 2, this.photo.displayHeight / 2, 'loading').setDisplaySize(size, size));

        this.showLoading();
    }

    showLoading() {
        this.photo.setVisible(false);
        this.loading.anims.play('loading');
        this.loading.setVisible(true);
    }

    hideLoading() {
        this.photo.setVisible(true);
        this.loading.anims.stop();
        this.loading.setVisible(false);
    }

    async displayCurrentInfo(name) {
        this.showLoading();
        const data = this.scene.journalData['data']['animals'][name];
        const killed = this.scene.journalData['animals'][name];

        this.killedText.setText(killed);
        this.name.setText(data['name']);
        // Параметры
        this.health.setText(data['health']);
        this.armor.setText(data['armor']);
        this.damage.setText(data['damage']);
        this.accuracy.setText(data['accuracy']);
        this.speed.setText(data['speed']);
        this.range.setText(data['range']);
        // Характеристика
        this.phylum.setText(data['phylum']);
        this.class.setText(data['class']);
        this.order.setText(data['order']);
        this.genus.setText(data['genus']);
        this.species.setText(data['species']);
        this.weight.setText(data['weight']);
        this.areal.setText(data['areal']);
        // Описание
        this.description.setText(data['description']);

        await this.loadData(name);

        this.photo.setTexture(`photo_${name}`).setDisplaySize(this.h * 1.39, this.h * 0.907);
        this.hideLoading();
    }

    async loadData(name) {
        const texture = `photo_${name}`;
        await loadAssets(this.scene, 'image', texture, `UI/journal/tabs/${this.category}/images/${name}.png`);
    }
}