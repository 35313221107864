import apiClient from "../../../../../services/apiClient.js";
import BuildingTable from "../buildings/BuildingTable.js";
import FactoryStore from "./components/FactoryStore.js";
import ProductionCell from "./components/ProductionCell.js";


export default class FactoryTable extends BuildingTable {
    constructor(scene, x, y, children, width, height, texture) {
        super(scene, x, y, children, width, height, texture);
    }

    create() {
        super.create();

        this.add(this.store = new FactoryStore(this.scene, 0, this.height * -0.32, [], this.width, this.height, this.ore, this.storeItems, this.targetScene));
    }

    async update(category) {
        await super.update(category);
        const data = await apiClient.production.getData({ buildingName: category });
        console.log(data);
        
        this.itemsData = data['data'];
        this.processes = data['processes'];

        if (!this.sectionAdded) {
            this.addProductionCell(this.width, this.height);
        } 

        this.updateContent();
    }

    addProductionCell(w, h) {
        this.add(this.productionCell = new ProductionCell(this.scene, w * -0.18, h * 0.22, [], w * 0.24, w * 0.06063, this.item, this.itemsData[this.item]));
        this.sectionAdded = true;

        this.productionCell.on('start_production', () => this.store.update());
    }

    updateContent() {
        this.productionCell.update();
        this.productionCell.checkProcessExists(this.processes);

        this.store.update();
    }
}