export default class AnimationManager {

    static load(scene, type, category, size, options = {}) {
        const config = { frameWidth: size, frameHeight: size };

        const name = options['name'] ?? '';

        let array = ['idle', 'walk', 'attack', 'death'];
        // array ??= array.concat(config.extraAnims);

        switch (category) {
            case 'character':
                switch (name) {
                    case 'main_hero':
                        array.push('close_fight_1', 'close_fight_2', 'close_fight_3');
                        break;
                    case 'TechNastya':
                        array.push('search', 'lift', 'extract', 'open');
                        break;
                    case 'Chlapidol':
                        array.push('butcher');
                        break;
                    case 'Shtryzik':
                        array.push('mine');
                        break;
                }

                array.forEach(key => {
                    scene.load.spritesheet(`${name}_${key}`, `anims/${type}/character/${name}/${key}.png`, config);
                });
                break;

            case 'animal':
                switch (name) {
                    case 'zazulic':
                    case 'zazulic_mini':
                    case 'zazulic_soldier':
                    case 'zazulic_sand':
                    case 'zazulic_sand_mini':
                    case 'zazulic_sand_soldier':
                        array.shift();
                        array.push('huff', 'dig');
                        break;
                    case 'pichmog':
                        array.shift();
                        array.push('wake');
                        break;
                }

                array.forEach(key => {
                    scene.load.spritesheet(`${name}_${key}`, `anims/${type}/animal/${name}/${key}.png`, config);
                });
                break;

            case 'soldier':
                const rank = options['rank'];

                array.forEach(key => {
                    scene.load.spritesheet(`${category}_${rank}_${key}`, `anims/${type}/soldier/${key}/${rank}.png`, config);
                });
                break;

            case 'mutant':
                array.push('close_fight', 'eat');

                array.forEach(key => {
                    scene.load.spritesheet(`${category}_${key}`, `anims/${type}/mutant/${key}.png`, config);
                });
                break;

            case 'irradiated':
                array.forEach(key => {
                    scene.load.spritesheet(`${category}_${key}`, `anims/${type}/irradiated/${key}.png`, config);
                });
                break;
        }

        if (['effects', 'ui'].includes(type)) {
            scene.load.spritesheet(`${category}`, `anims/${type}/${category}.png`, config);
        }

        if (type == 'objects') {
            scene.load.spritesheet(`${category}`, `anims/${type}/${category}/death.png`, config);
        }
    }

    static create(scene, name, action, directions, frames, rate, repeat) {
        let dirArray = [];

        if (directions == 1) {
            dirArray = [''];
        } else if (directions == 4) {
            dirArray = ['down-r', 'down-l', 'up-r', 'up-l'];
        } else if (directions == 8) {
            dirArray = ['right', 'left', 'down-r', 'down-l', 'up-r', 'up-l', 'up', 'down'];
        }

        let start = 0;
        let end = frames - 1;

        dirArray.forEach(dir => {
            let key = `${name}_${action}_${dir}`;
            let texture = `${name}_${action}`;
            if (dir == '') {
                key = `${name}_${action}`;
                texture = `${name}`;
            }

            if (!scene.anims.exists(key)) {
                scene.anims.create({
                    key: key,
                    frameRate: rate,
                    frames: scene.anims.generateFrameNumbers(texture, { start: start, end: end }),
                    repeat: repeat
                });
            }

            start += frames;
            end += frames;

            if (`${name}_${action}` == 'Phaser_idle') {
                start++;
                end++;
            }
        });
    }

    static createSingleAnimation(scene, texture, key, frames = { start: 0, end: 0 }, rate, repeat) {
        if (!scene.anims.exists(key)) {
            scene.anims.create({
                key: key,
                frameRate: rate,
                frames: scene.anims.generateFrameNumbers(texture, { start: frames.start, end: frames.end }),
                repeat: repeat
            });
        }
    }
}