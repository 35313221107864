import InfoTables from "../scenes/ui/InfoTables";
import apiClient from "../services/apiClient";

export default class SceneManager {
    static curScene = 'MainBase';

    static async loadScene(scene, sceneKey) {
        try {
            if (['Journal', 'BattleUI'].includes(scene.scene.key)) {
                scene.scene.stop();
                scene.scene.start(sceneKey);
            }
            else if (sceneKey != this.curScene) {
                let isExplored = true;
                let explorable = false;

                if (!['Journal', 'BattleUI'].includes(sceneKey)) {
                    const locationData = await apiClient.locations.world.getData();
                    if (locationData['areas'][sceneKey]) {
                        isExplored = locationData['areas'][sceneKey]['is_explored'];
                        explorable = locationData['areas'][sceneKey]['explorable'];
                    }
                }

                // Проверка разведанности зон (раскомментировать, когда надо будет)
                if (!isExplored && explorable) {
                    InfoTables.showWarningText('Локация не разведана');
                } else {
                    scene.scene.sleep(this.curScene);
                    scene.scene.run(sceneKey);

                    if (!['Journal', 'BattleUI'].includes(sceneKey)) {
                        this.curScene = sceneKey;
                    }
                }
            } else {
                InfoTables.showWarningText('Вы уже находитесь на этой локации');
            }
        } catch (error) {
            console.error('Ошибка загрузки данных локации:', error);
        }
    }
}