import shopData from '../../../../../config/shop/shopData.js';
import { ScrollManager } from '../../../../../managers/UIController.js';
import apiClient from '../../../../../services/apiClient.js';
import SingleInfoTable from '../common/SingleInfoTable.js';
import ShopCell from './components/ShopCell.js';
import ShopReward from './components/ShopReward.js';
import ShopSection from './components/ShopSection.js';


export default class ShopTable extends SingleInfoTable {
    constructor(scene, x, y, children, width, height, texture, category) {
        super(scene, x, y, children, width, height, texture);

        this.shopCategory = category;
        this.rewards = [];
        this.addContent();
    }

    async update(category) {
        await super.update(category);

        const data = await apiClient.shop.getData();

        if (data['status'] != 'success') {
            return;
        }

        this.updateInfo(data['data']);
    }

    addContent() {
        const w = this.width;
        const h = this.height;

        const scrollW = w * 0.65;
        const scrollH = h * 0.69;
        const spaceTop = h * 0.03;

        this.scroll = new ScrollManager(this.scene, {
            x: 0,
            y: h * -0.033,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                panel: {
                    top: h * 0.05,
                    bottom: spaceTop,
                }
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
        const data = shopData[this.shopCategory]['content'];

        if (['containers', 'keys'].includes(this.shopCategory)) {
            data.forEach(item => {
                let cell = new ShopSection(this.scene, 0, 0, [], w, h, item);
                cell.linkTable(this);
                this.scroll.addItem(cell);
            });

        } else {
            data.forEach(item => {
                let cell = new ShopCell(this.scene, 0, 0, [], w, h, item);
                cell.linkTable(this);
                this.scroll.addItem(cell);
            });
        }

        // Progress bar
        this.add(this.bar = this.scene.add.image(w * -0.31615, h * 0.4, 'progress_bar').setDisplaySize(w * 0.63177, h * 0.027778).setOrigin(0, 0));
        this.bar.setTintFill(0x18ff00);
        this.bar.setCrop(0, 0, 0, this.bar.height);
    }

    updateRewards(data) {
        const w = this.width;
        const h = this.height;

        this.rewards.forEach(reward => reward.destroy());
        this.rewards = [];

        let dist = w * -0.315;
        let cnt = 0;

        for (let id in data) {
            const item = data[id]['reward'];
            let isKey = item.includes('key_container');
            let size = isKey ? h * 0.06 : h * 0.09;

            let icon = new ShopReward(this.scene, dist, h * 0.36, [], item, size, size);
            icon.disable();

            if (data[id]['status'] == 1) {
                icon.blink();
            }
            this.rewards.push(icon);

            dist += (cnt == 0) ? w * 0.055 : w * 0.0637;
            cnt++;
        }

        this.add(this.rewards);
    }

    updateInfo(data) {
        super.updateInfo(data);
        this.updateProgressBar(data['event_counts'][`shop_${this.shopCategory}`]['count']);
        this.updateRewards(data['rewards'][`shop_${this.shopCategory}`]);
    }

    updateProgressBar(progress) {
        this.bar.setCrop(0, 0, this.bar.width * progress / 100, this.bar.height);
    }
}
