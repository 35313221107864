import { ScrollManager } from "../../../../managers/UIController";

export default class JournalSideScroll extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children) {
        super(scene, x, y, children);

        this.addContent();
    }

    addContent() {
        const w = window.screen.width;
        const h = window.screen.height;
        const scrollW = w * 0.24;
        const scrollH = h * 0.72;
        const spaceTop = h * 0.02;

        this.scroll = new ScrollManager(this.scene, {
            x: w * -0.35,
            y: 0,
            width: scrollW,
            height: scrollH,

            scrollMode: 0,

            space: {
                top: spaceTop,
                bottom: spaceTop,
            }
        }, {
            space: {
                line: spaceTop,
            },
        });

        this.add(this.scroll.scrollPanel);
        this.scene.events.once('getdata', () => this.addItems(this.scene.journalData), this);
    }

    clear() {
        this.scroll.removeItems();
    }

    selectFirstItem() {
        this.scroll.items[0]?.button.click(false);
    }
}