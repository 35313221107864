import InfoTables from "../../../../../../../scenes/ui/InfoTables";
import apiClient from "../../../../../../../services/apiClient";
import ActionButton from "../../../../../../main/buttons/common/ActionButton";
import ItemIcon from "../../../../../../main/icons/ItemIcon";

export default class UnitInjectCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, tw, th) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.tw = tw;
        this.th = th;

        this.create(tw, th);
        this.isFree = true;
    }

    create(w, h) {
        this.add(this.frame = this.scene.add.sprite(0, 0, 'UI', 'table_button.png').setOrigin(0.5, 0.5));
        this.frame.setDisplaySize(h * 0.085, h * 0.085).setTint(0xffcb6a).setAlpha(0.001);

        this.add(this.button = new ActionButton(this.scene, w * 0.034, h * 0.019, [], 'button_up', h * 0.041, h * 0.048, 'green', this.inject.bind(this)));
        this.button.setRotation(Math.PI / 2);
        this.button.disable();
    }

    addItem(key, item, unitData) {
        this.add(this.icon = new ItemIcon(this.scene, 0, 0, [], 'items_icons', this.th * 0.053, this.th * 0.053));
        this.icon.setIconData({ name: key });
        this.icon.setClickCallback(this.removeItem.bind(this));
        this.icon.obj.setOrigin(0.5, 0.5);

        this.icon.amountText.destroy();
        this.frame.setAlpha(1);
        this.item = item;
        this.unitData = unitData;

        this.isFree = false;
        this.button.makeInteractive();
    }

    removeItem() {
        let amount = this.item.getData('amount');
        this.item.updateAmount(++amount);
        this.deleteItem();
    }

    deleteItem() {
        this.icon.destroy();
        this.frame.setAlpha(0.001);
        this.isFree = true;

        this.icon = null;
        this.button.disable();
    }

    async inject() {
        const data = await apiClient.units.inject({
            id: this.unitData['id'],
            item: this.item.getData('name')
        });

        if (data['status'] != 'success') {
            return;
        }

        InfoTables.showWarningText(data['message']);
        InfoTables.curTable.update();

        this.deleteItem();
    }
}