import GameScene from './gameScene.js';
import apiClient from '../../services/apiClient.js';
import GameMap from '../../modules/scene/common/GameMap.js';
import EasyStar from 'easystarjs';
import terrainData from '../../config/scenes/terrainData.js';
import AnimationManager from '../../managers/AnimationManager.js';
import { getRandomArrayElement, getRandomNumber, isPointInMatrix } from '../../utils/utils.js';
import { TILE_HEIGHT, TILE_WIDTH } from '../../utils/const.js';
import animalData from '../../config/units/animalData.js';
import BattleZazulic from '../../modules/scene/battle/units/animals/BattleZazulic.js';
import BattleUI from '../ui/BattleUI.js';
import BattlePichmog from '../../modules/scene/battle/units/animals/BattlePichmog.js';
import BattleVugda from '../../modules/scene/battle/units/animals/BattleVugda.js';
import BattleBeles from '../../modules/scene/battle/units/animals/BattleBeles.js';
import BattleDevice from '../../modules/scene/battle/objects/BattleDevice.js';
import battleObstacleData from '../../config/scenes/battleObstacleData.js';
import BattleIrradiated from '../../modules/scene/battle/units/BattleIrradiated.js';
import BattleMutant from '../../modules/scene/battle/units/BattleMutant.js';
import BattleChlapidol from '../../modules/scene/battle/units/characters/BattleChlapidol.js';

export default class BattleScene extends GameScene {

    async preload() {
        this.loadingText = 'Загружаем локации и объекты: ';
        
        super.preload();
        this.scene.bringToTop();

        this.sceneEvent = this.sceneEvent || new Phaser.Events.EventEmitter();

        const data = await apiClient.locations.getData({ scene: this.scene.key });

        this.sceneInfo = data;
        this.loadAssets(data);
    }

    loadAssets(data) {
        const sceneName = this.scene.key;

        this.load.image(`${sceneName}_tiles`, `./scenes/battle/${sceneName}/map.jpg`);
        this.load.tilemapTiledJSON(`${sceneName}_map`, `./scenes/battle/${sceneName}/map.json`);

        this.load.atlas('battle_obstacles', 'anims/objects/zazulic_nest/zazulic_nest.png', 'anims/objects/zazulic_nest/zazulic_nest.json');
        this.load.image('battle_crystall_1', 'anims/objects/blue_crystall/1.png');

        for (let key in battleObstacleData[sceneName]) {
            this.load.image(`${sceneName}_${key}`, `./scenes/battle/${sceneName}/obstacles/${key}.png`);
        }

        this.data.set('data', this.sceneInfo);

        // Загрузка необходимых анимаций
        this.loadAnimations();
        //-----------------------------/

        this.load.start();
        this.load.once('complete', () => {
            this.sceneEvent.emit('loaded');
            this.sceneLoaded = true;
        });
    }

    loadAnimations() {
        const enemies = this.getEnemyData();

        for (let id in enemies) {
            const unitData = enemies[id];
            const name = unitData['name'];
            const category = unitData['category'];

            let size = 128;
            if (['pichmog', 'vugda', 'beles', 'mutant', 'irradiated'].includes(name)) {
                size = 256;
            }
            AnimationManager.load(this, 'units', category, size, { name: name });

            if (name == 'zazulic') {
                AnimationManager.load(this, 'units', category, size, { name: 'zazulic_mini' });

            } else if (name == 'beles') {
                AnimationManager.load(this, 'units', category, size, { name: 'beles_female' });
                AnimationManager.load(this, 'units', category, size, { name: 'beles_mini' });

            } else if (name == 'vugda') {
                AnimationManager.load(this, 'units', category, size, { name: 'vugda_female' });
                AnimationManager.load(this, 'units', category, size, { name: 'vugda_mini' });

            } else if (name == 'mutant') {
                AnimationManager.load(this, 'units', category, size, { name: 'Chlapidol' });

            } else if (name == 'irradiated') {
                AnimationManager.load(this, 'effects', 'lightning_strike', 256);
            }
        }

        // Анимации препятствий и объектов
        AnimationManager.load(this, 'objects', 'zazulic_nest', 256);
    }

    create() {
        let createScene = () => {
            console.log('scene created');
            super.create();
            this.createScene();
        }

        if (this.sceneLoaded) {
            createScene();
        } else {
            this.sceneEvent.once('loaded', () => createScene());
        }
    }

    createScene() {
        this.scene.bringToTop('BattleUI');
        this.scene.bringToTop('InfoTables');

        this.data.set('data', this.sceneInfo);

        // Создание необходимых анимаций
        this.createAnimations();
        //-----------------------------/

        console.log(this.sceneInfo);
        this.addMap(this.sceneInfo['data']['scene'][this.scene.key]);
        this.drawGrid();

        this.data.set('enemies', this.generateEnemies());
        this.data.set('army', []);
        this.data.set('objects', this.addObjects());
        this.data.set('deadUnits', []);
        this.addObstacles();

        this.data.set('enemies_total', this.data.get('enemies').length);
        this.data.set('enemies_killed', {});
        this.data.set('army_count', this.game.registry.get('battle_data')['count']);
        this.data.set('army_total', this.game.registry.get('battle_data')['count']);

        this.data.set('extra_rewards', {});

        BattleUI.playerUI.updateContent();
        BattleUI.enemyUI.updateContent();
    }

    addMap(data) {
        let w = window.screen.width * data['w'];
        let h = window.screen.width * data['h'];
        let x = w * data['x'];
        let y = h * data['y'];

        if (data['x'] == 100) x = window.innerWidth - w;
        if (data['y'] == 100) y = window.innerHeight - h;

        this.map = new GameMap(this, x, y, [], this.scene.key, w, h);
    }

    drawGrid() {
        this.tileW = window.screen.width * 0.051;
        this.tileH = this.tileW * 0.6;
        this.grid = this.setGridMatrix();

        // Test visualisation of grid
        // const tileW = this.tileW;
        // const tileH = this.tileH;
        // const grid = this.grid;
        // let x = 0, y = 0;

        // for (var i = 0; i < grid.length; i++) {
        //     for (var j = 0; j < grid[i].length; j++) {

        //         let color = 0xff6d6d; // По умолчанию зеленый
        //         switch (grid[i][j]) {
        //             case 0: // Непроходимые клетки
        //                 color = 0x76ffa0;
        //                 break;
        //             case 5: // Гнезда и другие препятствия
        //                 color = 0xFFAE00;
        //                 break;
        //         }

        //         let tile = this.add.rectangle(x, y, tileW, tileH, color, 0.1).setOrigin(0, 0).setStrokeStyle(1, 0x000000);

        //         this.map.add(tile); // Добавляем тайл в контейнер объектов карты
        //         x += tileW; // Х Позиция следующего тайла
        //     }

        //     x = 0;
        //     y += tileH; // Y Позиция следующего тайла
        // }

        this.easystar = new EasyStar.js();

        this.easystar.setGrid(this.grid);
        this.easystar.setAcceptableTiles([0, 5]);
        this.easystar.enableDiagonals();
        this.easystar.enableCornerCutting();
    }

    setGridMatrix() {
        this.deployX = 1;
        this.deployY = 1;
        return [];
    }

    createAnimations() {
        const enemies = this.getEnemyData();

        for (let id in enemies) {
            const unitData = enemies[id];
            const name = unitData['name'];

            switch (name) {
                case 'zazulic':
                case 'zazulic_soldier':
                    AnimationManager.create(this, name, 'huff', 4, 18, 6, -1);
                    AnimationManager.create(this, name, 'dig', 8, 10, 6, -1);
                    AnimationManager.create(this, name, 'walk', 8, 10, 18, -1);
                    AnimationManager.create(this, name, 'attack', 8, 10, 8, -1);
                    AnimationManager.create(this, name, 'death', 8, 5, 8, false);
                    // zazulic_mini
                    AnimationManager.create(this, 'zazulic_mini', 'huff', 4, 18, 6, -1);
                    AnimationManager.create(this, 'zazulic_mini', 'dig', 8, 10, 6, -1);
                    AnimationManager.create(this, 'zazulic_mini', 'walk', 8, 10, 18, -1);
                    AnimationManager.create(this, 'zazulic_mini', 'attack', 8, 10, 8, -1);
                    AnimationManager.create(this, 'zazulic_mini', 'death', 8, 5, 8, false);
                    break;
                case 'pichmog':
                    AnimationManager.create(this, name, 'wake', 4, 5, 6, -1);
                    AnimationManager.create(this, name, 'walk', 8, 10, 10, -1);
                    AnimationManager.create(this, name, 'attack', 8, 10, 8, -1);
                    AnimationManager.create(this, name, 'death', 8, 10, 8, false);
                    break;
                case 'vugda':
                    AnimationManager.create(this, name, 'idle', 4, 20, 6, -1);
                    AnimationManager.create(this, name, 'walk', 8, 10, 18, -1);
                    AnimationManager.create(this, name, 'attack', 8, 10, 5, -1);
                    AnimationManager.create(this, name, 'death', 8, 10, 7, false);
                    AnimationManager.create(this, 'vugda_female', 'idle', 4, 20, 6, -1);
                    AnimationManager.create(this, 'vugda_female', 'walk', 8, 10, 18, -1);
                    AnimationManager.create(this, 'vugda_female', 'attack', 8, 10, 5, -1);
                    AnimationManager.create(this, 'vugda_female', 'death', 8, 10, 7, false);
                    AnimationManager.create(this, 'vugda_mini', 'idle', 4, 20, 6, -1);
                    AnimationManager.create(this, 'vugda_mini', 'walk', 8, 10, 18, -1);
                    AnimationManager.create(this, 'vugda_mini', 'attack', 8, 10, 5, -1);
                    AnimationManager.create(this, 'vugda_mini', 'death', 8, 10, 7, false);
                    break;
                case 'beles':
                    AnimationManager.create(this, name, 'idle', 4, 20, 5, -1);
                    AnimationManager.create(this, name, 'walk', 8, 10, 15, -1);
                    AnimationManager.create(this, name, 'attack', 8, 10, 8, -1);
                    AnimationManager.create(this, name, 'death', 8, 10, 7, false);
                    AnimationManager.create(this, 'beles_female', 'idle', 4, 20, 5, -1);
                    AnimationManager.create(this, 'beles_female', 'walk', 8, 10, 15, -1);
                    AnimationManager.create(this, 'beles_female', 'attack', 8, 10, 8, -1);
                    AnimationManager.create(this, 'beles_female', 'death', 8, 10, 7, false);
                    AnimationManager.create(this, 'beles_mini', 'idle', 4, 20, 5, -1);
                    AnimationManager.create(this, 'beles_mini', 'walk', 8, 10, 15, -1);
                    AnimationManager.create(this, 'beles_mini', 'attack', 8, 10, 8, -1);
                    AnimationManager.create(this, 'beles_mini', 'death', 8, 10, 7, false);
                    break;
                case 'mutant':
                    AnimationManager.create(this, 'mutant', 'idle', 8, 10, 5, -1);
                    AnimationManager.create(this, 'mutant', 'walk', 8, 10, 9, -1);
                    AnimationManager.create(this, 'mutant', 'attack', 8, 10, 4, -1);
                    AnimationManager.create(this, 'mutant', 'death', 8, 10, 7, false);
                    AnimationManager.create(this, 'mutant', 'close_fight', 8, 10, 8, -1);
                    AnimationManager.create(this, 'mutant', 'eat', 8, 10, 8, -1);
                    // Chlapidol
                    AnimationManager.create(this, 'Chlapidol', 'idle', 8, 10, 2, -1);
                    AnimationManager.create(this, 'Chlapidol', 'walk', 8, 10, 10, -1);
                    AnimationManager.create(this, 'Chlapidol', 'attack', 8, 10, 7, -1);
                    AnimationManager.create(this, 'Chlapidol', 'death', 8, 10, 7, false);
                    AnimationManager.create(this, 'Chlapidol', 'butcher', 8, 10, 7, -1);
                    break;
                case 'irradiated':
                    AnimationManager.create(this, 'irradiated', 'idle', 8, 10, 5, -1);
                    AnimationManager.create(this, 'irradiated', 'walk', 8, 10, 10, -1);
                    AnimationManager.create(this, 'irradiated', 'attack', 8, 10, 5, -1);
                    AnimationManager.create(this, 'irradiated', 'death', 8, 10, 5, false);
                    AnimationManager.createSingleAnimation(this, 'lightning_strike', 'lightning_strike', { start: 0, end: 9 }, 10, -1);
                    break;
            }
        }

        // Анимации препятствий и объектов
        AnimationManager.create(this, 'zazulic_nest', 'death', 1, 5, 8, false);
    }

    getEnemyData() {
        const data = this.data.get('data');
        const terrain = data['data']['scene'][this.scene.key]['terrain'];

        const battleData = this.game.registry.get('battle_data');
        const amount = battleData['data']['data']['amount'];

        let enemies = [];

        if (amount < 10) {
            enemies = terrainData[terrain]['units']['1'];

        } else if (amount >= 10 && amount < 20) {
            enemies = terrainData[terrain]['units']['2'];

        } else if (amount >= 20 && amount < 30) {
            enemies = terrainData[terrain]['units']['3'];

        } else if (amount >= 30) {
            enemies = terrainData[terrain]['units']['4'];
        }

        return enemies;
    }

    generateEnemies() {
        const battleData = this.game.registry.get('battle_data');
        let amount = battleData['data']['data']['amount'];
        const enemyData = this.getEnemyData();
        const names = [];
        const enemies = [];

        for (let id in enemyData) {
            for (let i = 0; i < enemyData[id]['chance']; i++) {
                names.push(enemyData[id]['name']);
            }
        }

        const data = this.data.get('data');
        const terrain = data['data']['scene'][this.scene.key]['terrain'];

        // Create Chlapidol in genetic 
        if (terrain == 'genetic' && amount == 5) {
            amount = 4;
            const unit = new BattleChlapidol(this, 0, 0, [], 'enemy', 0.15);
            unit.create({ ...animalData['Chlapidol'] });
            enemies.push(unit);
        }

        // Создаем юнитов
        for (let i = 0; i < amount; i++) {
            let unit, name = getRandomArrayElement(names);

            switch (name) {
                case 'zazulic':
                case 'zazulic_mini':
                case 'zazulic_soldier':
                    const size = (name == 'zazulic_soldier') ? 0.09 : 0.07;
                    unit = new BattleZazulic(this, 0, 0, [], 'enemy', size);
                    break;
                case 'pichmog':
                    unit = new BattlePichmog(this, 0, 0, [], 'enemy', 0.08);
                    break;
                case 'vugda':
                    name = getRandomArrayElement(['vugda', 'vugda_female']);
                    unit = new BattleVugda(this, 0, 0, [], 'enemy', (name == 'vugda_female') ? 0.11 : 0.12);
                    break;
                case 'beles':
                    name = getRandomArrayElement(['beles', 'beles_female']);
                    unit = new BattleBeles(this, 0, 0, [], 'enemy', (name == 'beles_female') ? 0.12 : 0.14);
                    break;
                case 'mutant':
                    unit = new BattleMutant(this, 0, 0, [], 'enemy', 0.2);
                    break;
                case 'irradiated':
                    unit = new BattleIrradiated(this, 0, 0, [], 'enemy', 0.14);
                    break;
            }

            unit.create({ ...animalData[name] }); // Здесь именно копируем объект с данными
            enemies.push(unit);
        }

        return this.addEnemiesToMap(enemies);
    }

    addEnemiesToMap(enemies) {
        const tw = TILE_WIDTH;
        const th = TILE_HEIGHT;
        const grid = this.grid;

        // Добавляем на карту созданных юнитов
        for (let i = 0; i < enemies.length; i++) {
            let y = Math.floor(Math.random() * grid.length);
            let x = Math.floor(Math.random() * grid[y].length);

            let curUnit = enemies[i];

            if (curUnit.getData('unit')['category'] != 'obstacle') {
                let spotUsed = enemies.find(unit => (unit.x == tw * (x + 0.5) && unit.y == th * (y + 0.5)));

                if (!isPointInMatrix(grid, x, y) || grid[y][x] == 1 || spotUsed) {
                    i--;
                    continue;
                }

                curUnit.setPosition(tw * (x + 0.5), th * (y + 0.5));
            }
        }

        this.addExtraUnits(enemies, 'zazulic_nest', 'zazulic_mini', 2);
        this.addExtraUnits(enemies, 'beles_female', 'beles_mini', 1);
        this.addExtraUnits(enemies, 'vugda_female', 'vugda_mini', 1);

        this.map.add(enemies);
        this.map.sort('y');

        this.enemies = enemies;
        return enemies;
    }

    addExtraUnits(enemies, targetName, unitName, amount) {
        const tw = TILE_WIDTH;
        const th = TILE_HEIGHT;
        const grid = this.grid;

        for (let i = 0; i < enemies.length; i++) {
            if (enemies[i].getData('unit')['name'] == targetName) {

                let targetX = Math.floor(enemies[i].x / tw);
                let targetY = Math.floor(enemies[i].y / th);

                for (let j = 0; j < amount; j++) {
                    let x = targetX + getRandomNumber(-1, 3);
                    let y = targetY + getRandomNumber(-1, 3);

                    let spotUsed = enemies.find(unit => (unit.x == tw * (x + 0.5) && unit.y == th * (y + 0.5)));

                    if (!isPointInMatrix(grid, x, y) || grid[y][x] == 1 || spotUsed || (x == targetX && y == targetY)) {
                        j--;
                        continue;
                    }

                    let unit;

                    switch (unitName) {
                        case 'zazulic_mini':
                            unit = new BattleZazulic(this, x, y, [], 'enemy', 0.06);
                            break;
                        case 'vugda_mini':
                            unit = new BattleVugda(this, x, y, [], 'enemy', 0.09);
                            break;
                        case 'beles_mini':
                            unit = new BattleBeles(this, x, y, [], 'enemy', 0.09);
                            break;
                    }

                    unit.create({ ...animalData[unitName] });
                    enemies.push(unit);
                }
            }
        }
    }

    addObjects() {
        this.objects = this.spreadDevicesOnMap();
        this.map.add(this.objects);
        return this.objects;
    }

    spreadDevicesOnMap() {
        const tw = TILE_WIDTH;
        const th = TILE_HEIGHT;
        const grid = this.grid;
        const devices = [];

        // Предметы, которые ТехНастя может собирать
        const itemsToCollect = [
            'soldier_module_health_1',
            'soldier_module_health_2',
            'soldier_module_damage_1',
            'soldier_module_damage_2',
            'soldier_module_armor_1',
            'soldier_module_armor_2',
            'soldier_module_accuracy_1',
            'soldier_module_accuracy_2',
            'hack_tool_1',
            'hack_tool_2',
            'battery_units',
            'chip',
            'processor',
            'medkit',
            'key_container_batteries',
            'key_container_crystalls',
            'key_container_iron',
            'key_container_silicon',
            'key_container_titan',
            'key_container_modules',
            'key_container_genetic',
            'key_container_research',
            'key_container_devices',
            'key_container_robots',
            'key_container_keys',
        ];

        // Создаем случайное количество предметов на карте
        let deviceCnt = getRandomNumber(3, 6);

        for (let i = 0; i < deviceCnt; i++) {
            let name = getRandomArrayElement(itemsToCollect);
            let item = new BattleDevice(this, 0, 0, [], 'device', 0.02);

            item.create({
                health: 1,
                max_health: 1,
                name: name,
                category: 'device',
                difficulty: 0,
            });

            devices.push(item);
        }

        // Распределяем предметы на карте
        for (let i = 0; i < devices.length; i++) {
            let y = Math.floor(Math.random() * grid.length);
            let x = Math.floor(Math.random() * grid[y].length);

            let spotUsed = devices.find(unit => (unit.x == tw * (x + 0.5) && unit.y == th * (y + 0.5)));

            if (!isPointInMatrix(grid, x, y) || grid[y][x] == 1 || spotUsed) {
                i--;
                continue;
            }
            devices[i].setPosition(tw * (x + 0.5), th * (y + 0.5));
        }

        return devices;
    }

    addObstacles() {
        const sceneName = this.scene.key;
        const data = battleObstacleData[sceneName];

        for (let key in data) {
            let d = data[key];
            this.map.add(this.add.image(this.mapW * d['x'], this.mapW * d['y'], `${sceneName}_${key}`)
                .setDisplaySize(this.mapW * d['w'], this.mapW * d['h']).setOrigin(0, 1));
        }
    }
}

