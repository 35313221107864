import UIController from "../../../../../../managers/UIController";
import { TEXT_STYLE_BLUE, TEXT_STYLE_GREEN } from "../../../../../../utils/textStyles";

export default class ConstructionItemCell extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.w = width;
        this.h = height;

        this.create(width, height);
    }

    create(w, h) {
        this.add(this.icon = this.scene.add.image(0, 0, 'items_icons', 'production.png').setOrigin(0.5, 0.5).setDisplaySize(h * 0.06, h * 0.06));
        this.add(this.curAmount = this.scene.add.text(w * 0.035, 0, '0').setOrigin(0, 1.2).setStyle(TEXT_STYLE_GREEN).setFontSize(h * 0.022));
        this.add(this.reqAmount = this.scene.add.text(w * 0.035, 0, '0').setOrigin(0, -0.2).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.022));
    }

    updateText(reqItem) {
        this.icon.setFrame(reqItem['name'] + '.png');

        const items = this.scene.game.registry.get('items');

        UIController.updateItemsText(items, { [reqItem['name']]: reqItem['amount']}, [this.curAmount], true);
        this.reqAmount.setText(reqItem['amount']);
    }
}