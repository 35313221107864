import GameScene from '../core/gameScene.js';
import { ButtonCloseJournal, ButtonClaimJournal, ButtonFullScreen, JournalTab, ButtonToggleJournal } from '../../modules/ui/journal/journalModule.js';
import apiClient from '../../services/apiClient.js';
import { TEXT_STYLE_ORANGE } from '../../utils/textStyles.js';
import JournalAnimalPage from '../../modules/ui/journal/pages/JournalAnimalPage.js';
import JournalCharacterPage from '../../modules/ui/journal/pages/JournalCharacterPage.js';
import InfoTables from './InfoTables.js';
import JournalStoryPage from '../../modules/ui/journal/pages/JournalStoryPage.js';

export default class Journal extends GameScene {
    constructor() {
        super('Journal');
    }

    preload() {
        this.loadingText = 'Загружаем журнал: ';
        super.preload();
        this.scene.bringToTop();
        this.load.setPath('UI/journal/');

        this.load.atlas('journal_UI', 'UI.png', 'UI.json');
        this.load.image('journal_bg', 'bg.png');
        this.load.image('journal_frame_l', 'frame_l.png');
        this.load.image('journal_frame_r', 'frame_r.png');
        this.load.image('journal_story_info', 'tabs/story/info_frame.png');
        this.load.image('journal_character_info', 'tabs/characters/info_frame.png');
        this.load.image('journal_animal_info', 'tabs/animals/info_frame.png');

        this.load.setPath('UI/icons/ui/journal/');

        this.load.atlas('journal_character_icons', 'units.png', 'units.json');
        this.load.atlas('journal_animal_icons', 'animals.png', 'animals.json');
        
        this.load.setPath('');
    }

    async create() {
        super.create();
        this.scene.bringToTop('InfoTables');

        this.addUI();
        this.updateUI();

        this.scale.on('resize', this.updateUI, this);

        this.journalData = await apiClient.UI.journal.getData();
        this.events.emit('getdata', this);
        console.log(this.journalData);

        this.updateContent();
    }

    addPages() {
        this.pages = [
            new JournalStoryPage(this, 0, 0, [], 'сюжет'),
            new JournalCharacterPage(this, 0, 0, [], 'персонажи'),
            new JournalAnimalPage(this, 0, 0, [], 'монстры'),
        ];

        this.displayCategoryInfo('сюжет', this.tabsUnits[0]);
    }

    addUI() {
        const w = window.screen.width;
        const h = window.screen.height;

        this.bg = this.add.image(0, 0, 'journal_bg').setDisplaySize(w, h).setOrigin(0, 0).setInteractive();

        this.frameL = this.add.image(0, 0, 'journal_frame_l').setOrigin(0, 0);
        this.frameR = this.add.image(0, 0, 'journal_frame_r').setOrigin(1, 0);

        // Кнопки
        this.tabsUnits = [
            new JournalTab(this, 0, 0, [], 'tab', 0, 0, 'blue', 'сюжет'),
            new JournalTab(this, 0, 0, [], 'tab', 0, 0, 'blue', 'персонажи'),
            new JournalTab(this, 0, 0, [], 'tab', 0, 0, 'blue', 'герои', false),
            new JournalTab(this, 0, 0, [], 'tab', 0, 0, 'blue', 'наемники', false),
            new JournalTab(this, 0, 0, [], 'tab', 0, 0, 'blue', 'древние', false),
        ];
        // Вкладки врагов
        this.tabsEnemies = [
            new JournalTab(this, 0, 0, [], 'tab', 0, 0, 'blue', 'монстры'),
            new JournalTab(this, 0, 0, [], 'tab', 0, 0, 'blue', 'люди', false),
            new JournalTab(this, 0, 0, [], 'tab', 0, 0, 'blue', 'киборги', false),
            new JournalTab(this, 0, 0, [], 'tab', 0, 0, 'blue', 'мутанты', false),
            new JournalTab(this, 0, 0, [], 'tab', 0, 0, 'blue', 'пришельцы', false),
        ];
        this.tabsEnemies.forEach(tab => tab.setVisible(false));

        this.btnToggle = new ButtonToggleJournal(this, 0, 0, [], 'toggle_btn', 0, 0, 'blue');
        this.btnClose = new ButtonCloseJournal(this, 0, 0, [], 'close_btn', 0, 0, 0xff4848);
        this.btnClaim = new ButtonClaimJournal(this, 0, 0, [], 'claim_btn', 0, 0, 0xfffa6f);
        this.btnFullScreen = new ButtonFullScreen(this, 0, 0, [], 'full_screen', 0, 0);

        // Дата в углу экрана
        this.dateText = this.add.text(0, 0, '00.00.00').setOrigin(1, 0.5).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.017);
        this.addPages();
    }

    updateUI() {
        const w = window.innerWidth;
        const h = window.innerHeight;

        let frameW = h / 9 * 16;
        let frameH = h;

        this.frameL.setDisplaySize(frameW, frameH);
        this.frameR.setDisplaySize(frameW, frameH).setPosition(w, 0);

        // Кнопки
        this.btnToggle.resize(frameH * 0.077, frameH * 0.038);
        this.btnToggle.setPosition(frameH * 1.027, frameH * 0.013);
        // Выход
        this.btnClose.resize(frameH * 0.077, frameH * 0.038);
        this.btnClose.setPosition(w - frameH * 0.013, frameH * 0.013);
        // Награды
        this.btnClaim.resize(frameH * 0.331, frameH * 0.038);
        this.btnClaim.setPosition(w - frameH * 0.348, frameH * 0.943);
        // Экран
        this.btnFullScreen.resize(frameH * 0.03, frameH * 0.03);
        this.btnFullScreen.setPosition(frameH * 0.05, frameH * 0.015);

        // Вкладки союзников
        let x = frameH * 0.092;
        this.tabsUnits.forEach(tab => {
            tab.resize(frameH * 0.208, frameH * 0.038);
            tab.setPosition(x, frameH * 0.013);

            x += frameH * 0.187;
        });
        // Вкладки врагов
        x = frameH * 0.092;
        this.tabsEnemies.forEach(tab => {
            tab.resize(frameH * 0.208, frameH * 0.038);
            tab.setPosition(x, frameH * 0.013);

            x += frameH * 0.187;
        });

        // Дата в углу экрана
        this.dateText.setPosition(w - frameH * 0.1, frameH * 0.035);
        this.pages.forEach(table => table.setPosition(w / 2, h / 2));
    }

    toggleTabs() {
        let units = this.tabsUnits;
        let enemies = this.tabsEnemies;

        if (units.every(tab => tab.visible)) {
            enemies.forEach(tab => tab.setVisible(true));
            units.forEach(tab => tab.setVisible(false));
        } else {
            enemies.forEach(tab => tab.setVisible(false));
            units.forEach(tab => tab.setVisible(true));
        }
    }

    updateContent() {
        this.dateText.setText(this.journalData['date']);
    }

    displayCategoryInfo(category, curTab) {
        this.curTab = category;
        const page = this.pages.find(page => page.category == category);

        if (!page) {
            InfoTables.showWarningText('Пока нет данных об этом');
            return;
        }

        this.pages.forEach(page => page.setVisible(false));
        page.setVisible(true);
        page.scroll.selectFirstItem();

        this.tabsUnits.forEach(tab => tab.deselect());
        this.tabsEnemies.forEach(tab => tab.deselect());
        curTab.select();
    }
}