import loadAssets from "../../../../../managers/AssetManager";

export default class SingleInfoTable extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children, width, height, texture) {
        super(scene, x, y, children);
        scene.add.existing(this);

        this.scene = scene;
        this.width = width;
        this.height = height;
        this.texture = texture;

        this.add(this.loading = this.scene.add.sprite(0, 0, 'loading').setDisplaySize(this.height * 0.1, this.height * 0.1));
    }

    create() {
        this.add(this.bg = this.scene.add.image(0, 0, '').setOrigin(0.5, 0.5));
    }

    async update(category) {
        this.emit('updatetable', this);
        const texture = 'table_' + this.texture;

        this.loading.anims.play('loading');

        await loadAssets(this.scene, 'image', texture, `UI/info_tables/tables/${category}/${texture}.png`);

        if (!this.created) {
            this.create();
            this.created = true;
            this.loading.setVisible(false);
        }
        this.updateTexture(texture);
        this.sendToBack(this.bg);
    }

    updateTexture(texture) {
        this.bg.setTexture(texture);
        this.bg.setDisplaySize(this.width, this.height);
        this.bg.setInteractive();
    }

    updateInfo() { }
}