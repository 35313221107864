import AudioManager from "../../../../managers/AudioManager";
import SceneManager from "../../../../managers/SceneManager";
import Button from "../common/Button";

export default class LeaveBattleButton extends Button {

    click() {
        super.click();

        const scene = this.scene;
        const battleUIscene = scene.scene.get('BattleUI');
        scene.scene.stop(battleUIscene.battleScene);
        scene.scene.sendToBack('InfoTables');
        SceneManager.loadScene(battleUIscene, 'UI');
        AudioManager.setMusic('environment');
    }
}