import machineData from '../../../../../config/machines/machineData.js';
import loadAssets from '../../../../../managers/AssetManager.js';
import apiClient from '../../../../../services/apiClient.js';
import { TEXT_STYLE_BLUE, TEXT_STYLE_ORANGE } from '../../../../../utils/textStyles.js';
import { setImageSizeByHeight } from '../../../../../utils/utils.js';
import MachineBodyIcon from '../../../../main/icons/MachineBodyIcon.js';
import InfoTableMainScroll from '../../../common/scroll/InfoTableMainScroll.js';
import SingleInfoTable from '../common/SingleInfoTable.js';
import MachineConstructionInfo from './components/MachineConstructionInfo.js';


export default class MachineConstructionTable extends SingleInfoTable {

    async create() {
        super.create();
        this.unitCategory = this.texture;

        const w = this.width;
        const h = this.height;
        this.addContent(this.width, this.height);

        this.add(this.mainScroll = new InfoTableMainScroll(this.scene, 0, 0, [], w, h, (data) => {

            for (let key in data) {
                if (!key.includes('_body')) continue;

                for (let i = 0; i < data[key]; i++) {
                    let icon = new MachineBodyIcon(this.scene, 0, 0, [], 'machine_body_icons', h * 0.09, h * 0.09, '');
                    icon.setIconData({ name: key, title: machineData[key.slice(0, -5)]['name'] }, i);

                    // Колбэк при клике на иконку
                    icon.setClickCallback(() => {
                        this.mainScroll.deselectAll();
                        icon.select();

                        this.updateInfo(icon);
                    });

                    this.mainScroll.addItem(icon);
                }
            }

            if (this.mainScroll.getAll().length == 0) {
                this.machineInfo.disable();
                this.fullBodyImage.setVisible(false);
                this.infoText.setVisible(true);
            } else {
                this.fullBodyImage.setVisible(true);
                this.infoText.setVisible(false);
            }
        }));
    }

    async update(category) {
        await this.loadData(this.unitCategory);
        await super.update(category);

        this.updateContent();
    }

    async loadData(category) {
        const machines = ['builder', 'miner', 'crystall_collector', 'truck'];

        for (let i = 0; i < machines.length; i++) {
            let key = machines[i];
            await loadAssets(this.scene, 'image', 'full_body_' + key, `UI/info_tables/tables/machine_factory/machines/${key}.png`);
        }
    }

    addContent(w, h) {
        this.machineAmount = [
            this.scene.add.text(w * -0.2975, h * -0.32, '0'),
            this.scene.add.text(w * -0.2545, h * -0.32, '0'),
            this.scene.add.text(w * -0.2115, h * -0.32, '0'),
            this.scene.add.text(w * -0.1685, h * -0.32, '0'),
        ];
        this.add(this.machineAmount);

        this.machineAmount.forEach(text => {
            text.setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.014);
        });

        this.add(this.machineName = this.scene.add.text(w * 0.182, h * -0.29, 'Шахтер')
            .setOrigin(0.5, 1).setStyle(TEXT_STYLE_BLUE).setFontSize(h * 0.022));

        // Full Machine Image
        this.add(this.fullBodyImage = this.scene.add.sprite(w * -0.14, h * 0.37, 'full_body_builder').setOrigin(0.5, 0.95));
        setImageSizeByHeight(this.fullBodyImage, h * 0.68);

        // Machine Info Block (components, button, etc.)
        this.add(this.machineInfo = new MachineConstructionInfo(this.scene, 0, 0, [], w, h));

        const text = 'У вас нет необходимых запчастей для сбора техники';

        this.add(this.infoText = this.scene.add.text(0, h * 0.29, text)
            .setOrigin(0.5, 0.5).setStyle(TEXT_STYLE_ORANGE).setFontSize(h * 0.022).setVisible(false));
    }

    updateInfo(icon) {
        if (!icon) return;

        this.machineName.setText(icon.iconData['title']);
        this.fullBodyImage.setTexture(`full_body_${icon.iconData['name']}`.slice(0, -5));
        setImageSizeByHeight(this.fullBodyImage, this.height * 0.68);

        this.machineInfo.updateInfo(icon);
    }

    async updateContent() {
        const data = await apiClient.machines.getAll();
        console.log(data);

        const items = this.scene.game.registry.get('items');
        this.updateScroll(items);

        const machines = data['machines'];
        const machineStats = {
            'miner': 0,
            'crystall_collector': 0,
            'truck': 0,
            'builder': items['builder'],
        };

        for (let index in machines) {
            machineStats[machines[index]['name']]++;
        }

        this.machineAmount[0].setText(machineStats['miner']);
        this.machineAmount[1].setText(machineStats['crystall_collector']);
        this.machineAmount[2].setText(machineStats['truck']);
        this.machineAmount[3].setText(machineStats['builder']);
    }

    updateScroll(data) {
        this.mainScroll.updateContent(data);
        const icon = this.mainScroll.getItem(this.selectedUnitIndex || 0);
        icon && icon.click();
    }
}



